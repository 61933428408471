<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p v-if="androidPackage != null" class="modal-card-title">
        Создать приложение {{ androidPackage }} в Firebase
      </p>
      <p v-if="iosBundle != null" class="modal-card-title">
        Создать приложение {{ iosBundle }} в Firebase
      </p>
    </header>
    <section class="modal-card-body">
      <b-field label="В каком проекте создать">
        <b-select v-model="selectedProject">
          <option v-for="p in firebaseProjects" :value="p.id" :key="p.id">
            {{ p.name }}
          </option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Отменить
      </button>
      <button class="button is-primary" @click="create()">Создать</button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    androidPackage: String,
    iosBundle: String,
    firebaseProjects: Array
  },
  data: function () {
    return {
      selectedProject: null
    }
  },
  methods: {
    create: function () {
      this.$emit('create', this.selectedProject)
    }
  }
}
</script>

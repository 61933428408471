import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import companies from './companies'
import firebase from './firebase'
import marketAccounts from './marketAccounts'
import onboarding from './onboarding'
import releases from './releases'
import servers from './servers'
import tags from './tags'
import hms from './hms'
import users from './users'
import versions from './versions'
import locales from './locales'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    user: null
  },
  getters: {
  },
  mutations: {
    startLoading (state) {
      state.isLoading = true
    },
    stopLoading (state) {
      state.isLoading = false
    },
    saveUser (state, user) {
      state.user = user
    }
  },
  actions: {
    loadAllReleaseData({ dispatch }) {
      const queue = Promise.all(
        [
          dispatch('servers/getAll'),
          dispatch('tags/getAll'),
          dispatch('releases/getAll', { page: 1 }),
          dispatch('firebase/getProjects'),
          dispatch('marketAccounts/getAccounts'),
          dispatch('versions/getAll'),
          dispatch('locales/getAll')
        ]
      )
      return dispatch('startLoading')
        .then(() => queue)
        .then(() => dispatch('stopLoading'))
    },

    loadAll ({ dispatch }) {
      var loadEverything = Promise.all(
        [
          dispatch('servers/getAll'),
          dispatch('tags/getAll'),
          dispatch('releases/getAll', { page: 1 })
        ]
      )
      return dispatch('startLoading')
        .then(() => loadEverything)
        .then(() => dispatch('stopLoading'))
    },
    startLoading ({ commit }) {
      commit('startLoading')
    },
    stopLoading ({ commit }) {
      commit('stopLoading')
    },
    saveUser ({ commit }, { user }) {
      commit('saveUser', user)
    }
  },
  modules: {
    auth,
    companies,
    firebase,
    marketAccounts,
    onboarding,
    releases,
    servers,
    tags,
    hms,
    users,
    versions,
    locales
  }
})

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.release)?_c('div',{staticClass:"container header"},[_c('release-details-header',{attrs:{"release":_vm.release}})],1):_vm._e(),(_vm.release)?_c('div',{staticClass:"container"},[_c('p',{staticClass:"is-size-4"},[_vm._v("Список компаний:")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},_vm._l((_vm.release.companies),function(release_company){return _c('div',{key:release_company.id,staticClass:"release-company",class:{ 'is-selected': _vm.selectedCompany.id == release_company.id },on:{"click":function($event){return _vm.selectCompany(release_company.id)}}},[_c('p',[_vm._v(" "+_vm._s(release_company.company.company_id)+". "+_vm._s(release_company.company.name)+" "),_c('span',[(release_company.platform.includes('ios'))?_c('b-icon',{attrs:{"icon":"apple"}}):_vm._e(),(release_company.platform.includes('android'))?_c('b-icon',{attrs:{"icon":"android","type":"is-success"}}):_vm._e()],1),_c('router-link',{staticClass:"app-name",attrs:{"to":{
                name: 'edit-company',
                params: { id: release_company.company.id },
              }}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1)],1),_c('b-taglist',[(release_company.status == 0)?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Не начато ")]):(
                release_company.status == 1 &&
                _vm.release.report.is_stopped == false
              )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Подготавливаем данные ")]):(
                release_company.status == 2 &&
                _vm.release.report.is_stopped == false
              )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Собираем приложение ")]):(
                release_company.status == 3 &&
                _vm.release.report.is_stopped == false
              )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Проводим релиз ")]):(release_company.status == 4)?_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v(" Завершено ")]):(release_company.status == 5)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v(" Ошибка ")]):(release_company.status == 6)?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Релиз остановлен ")]):(
                _vm.release.report.completed == false &&
                _vm.release.report.is_stopped == true
              )?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Остановка релиза ")]):_vm._e(),(release_company.skipped_upload_to_admin_panel)?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Эта сборка не залита в админ-панель ")]):_vm._e()],1)],1)}),0),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"company-summary box"},[_c('h1',{staticClass:"title"},[_vm._v(" Компания: "),_c('i',[_vm._v(_vm._s(_vm.selectedCompany.company.company_id)+".")]),_vm._v(" "+_vm._s(_vm.selectedCompany.company.name)+" "),_c('b-taglist',{staticClass:"is-pulled-right"},[(_vm.selectedCompany.status == 0)?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Не начато ")]):(
                  _vm.selectedCompany.status == 1 &&
                  _vm.release.report.is_stopped == false
                )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Подготавливаем данные ")]):(
                  _vm.selectedCompany.status == 2 &&
                  _vm.release.report.is_stopped == false
                )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Собираем приложение ")]):(
                  _vm.selectedCompany.status == 3 &&
                  _vm.release.report.is_stopped == false
                )?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" Проводим релиз ")]):(_vm.selectedCompany.status == 4)?_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v(" Завершено ")]):(_vm.selectedCompany.status == 5)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v(" Ошибка ")]):(_vm.selectedCompany.status == 6)?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Релиз остановлен ")]):(
                  _vm.release.report.completed == false &&
                  _vm.release.report.is_stopped == true
                )?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Остановка релиза ")]):_vm._e(),(_vm.selectedCompany.skipped_upload_to_admin_panel)?_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" Эта сборка не залита в админ-панель ")]):_vm._e()],1)],1),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.selectedCompany.company.app_name)+" "),_c('span',[(_vm.selectedCompany.platform.includes('ios'))?_c('b-icon',{attrs:{"icon":"apple"}}):_vm._e(),(_vm.selectedCompany.platform.includes('android'))?_c('b-icon',{attrs:{"icon":"android","type":"is-success"}}):_vm._e()],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[(_vm.selectedCompany.build_url)?_c('p',{staticClass:"subtitle"},[_c('a',{attrs:{"href":_vm.selectedCompany.build_url,"target":"_blank"}},[_vm._v(" Ссылка на сборку ")])]):_vm._e(),(_vm.selectedCompany.result_url)?_c('p',{staticClass:"subtitle"},[_c('a',{attrs:{"href":_vm.selectedCompany.result_url,"target":"_blank"}},[_vm._v(" Ссылка на собранное приложение ")])]):_vm._e()]),_c('div',{staticClass:"column is-half"},[_vm._v(" Дополнительные артефакты: "),_c('table',{staticClass:"artifacts"},_vm._l((JSON.parse(
                    _vm.selectedCompany.build_artifacts
                  )),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[(value && value.startsWith('http'))?[_c('a',{attrs:{"href":value,"target":"_blank"}},[_vm._v(_vm._s(value))])]:[_vm._v(_vm._s(value))]],2)])}),0)])])]),_c('div',{staticClass:"company-details content"},[_c('log-viewer',{attrs:{"logs":_vm.selectedCompany.build_logs}})],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import LocalesApi from '@/api/locales'

const state = {
  locales: []
}

const getters = {
}

const mutations = {
  storeLocales (state, locales) {
    state.locales = locales
  }
}
const actions = {
  getAll ({ commit }) {
    return LocalesApi.getAll().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeLocales', res.data.locales)
      return Promise.resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
  <div>
    <div class="container">
      <div class="editor-window">
        <b-input
          v-if="messageLimit > 0"
          v-model="localizations[selectedLocale]"
          expanded
          type="textarea"
          :maxlength="messageLimit"
          ref="a"
        ></b-input>
        <b-input
          v-else
          v-model="localizations[selectedLocale]"
          expanded
          type="textarea"
          ref="b"
        ></b-input>
      </div>
      <div class="preview-window">
        <markdown-it-vue
          class="markdown-preview"
          :content="localizations[selectedLocale]"
        ></markdown-it-vue>
      </div>
    </div>
    <div class="locale-select">
      <b-field label="Выберите локаль">
        <b-select v-model="selectedLocale">
          <option
            v-for="locale in locales"
            :value="locale.locale"
            :key="locale.locale"
          >
            {{ locale.name }}
          </option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

export default {
  name: 'VersionMessageEditor',
  components: {
    MarkdownItVue
  },
  props: {
    locales: Array,
    onCompanyClick: Function,
    messageLimit: {
      type: Number,
      default: 0
    },
    localizations: Object
  },
  data: function () {
    return {
      selectedLocale: 'ru'
    }
  },
  methods: {}
}
</script>

<style scoped>
.container {
  display: flex;
}

.editor-window {
  flex: 1;
}

.preview-window {
  flex: 1;
  margin-left: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1px;
  background-color: #f6f6f6;
  font-family: "Monaco", courier, monospace;
  font-size: 14px;
}

.locale-select {
  margin-top: 1rem;
  text-align: right;
}
</style>

function getTextFromErrorCode (code, payload) {
  switch (code) {
    case 1:
      return 'Ошибка в запросе: Wrong payload'
    case 2:
      return 'Не авторизован'
    case 3:
      return 'Такое имя пользователя уже занято'
    case 4:
      return 'Не найдено'
    case 10:
      return 'Сервер не доступен'
    case 11:
      return 'Не удалось получить доступ к Firebase'
    case 12:
      return 'Этот проект Firebase уже добавлен'
    case 13:
      return `Это приложение уже добавлено в проект Firebase: ${payload.project}`
    case 14:
      return 'Невозможно добавить новый проект в Firebase'
    case 15:
      return 'Неверно указан тип аккаунта'
    case 16:
      return 'Не удалось получить доступ к Google Play'
    case 17:
      return 'Неверно указана учетная запись для стора'
    case 18:
      return 'Не удалось найти данные в учетной записи Google Play для указанного Android alias'
    case 19:
      return 'Не удалось найти проект Firebase для указанного Android package'
    case 20:
      return 'Не удалось найти проект Firebase для указанного iOS проекта'
    case 21:
      return 'Не удалось загрузить данные из Figma'
    case 22:
      return 'Компания уже создана'
    case 23:
      return 'Ошибка регистрации'
    case 24:
      return `Не удалось залить в админ-панель ${payload.companies.length} компаний`
    case 40:
      return 'Версия не найдена'
    case 41:
      return 'Компания не найдена'
    case 42:
      return 'Сервер не найден'
    case 44:
      return 'Не указан токен s3 proxy'
  }
}

function getTextFromError (error) {
  var message = getTextFromErrorCode(error.code, error.payload)

  var cause = error.cause
  if (cause) {
    cause = cause.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
    message = message + ' Внутренняя ошибка: ' + cause
  }
  return message
}

const ErrorHandlerPlugin = {
  install (Vue, options) {
    Vue.prototype.$handleError = function (error) {
      console.log('Error: ')
      console.log('------------')
      var errorText = ''
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (!error.response.data || !error.response.data.error) {
          errorText = 'Ошибка сервера'

          console.log(error.request)

          console.log(error.config)

          this.$buefy.notification.open({
            message: errorText,
            position: 'is-bottom-right',
            autoClose: false,
            type: 'is-danger'
          })
          return
        }
        errorText = getTextFromError(error.response.data.error)

        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)

        if (error.response.data.error.code === 2) { // Not authorized
          localStorage.setItem('token', null)
          this.$store.dispatch('auth/loadTokensFromStorage')

          if (!this.$route.matched.some(record => record.meta != null && record.meta.availableWithoutAuth)) {
            this.$router.push({ name: 'login' })
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorText = 'Ошибка интернет соединения'

        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        errorText = error.message

        console.log('Error', error.message)
      }
      console.log(error.config)

      this.$buefy.notification.open({
        message: errorText,
        position: 'is-bottom-right',
        autoClose: false,
        type: 'is-danger'
      })
    }
  }
}

export default ErrorHandlerPlugin

<template>
  <b-tabs type="is-boxed">
    <template v-for="(tab, index) in tabs">
      <b-tab-item :key="index">
        <template slot="header">
          <span>
            <b-icon :icon="isAndroid(tab) ? 'android' : 'apple'" />
            {{ tab.title }}
            <b-tooltip
              v-if="hasErrorOnTab(tab.code)"
              :label="getErrorForTab(tab.code)"
              position="is-right"
            >
              <b-icon icon="alert-circle" type="is-danger"></b-icon>
            </b-tooltip>
          </span>
        </template>

        <version-message-editor
          :locales="locales"
          :localizations="getLocalizations(tab)"
          :message-limit="5000"
        />
      </b-tab-item>
    </template>
  </b-tabs>
</template>

<style lang="scss" scoped>
.error-tag {
  padding-right: 10.5px;
  padding-left: 10.5px;
}
</style>

<script>
import { mapState } from "vuex";
import VersionMessageEditor from "@/components/VersionMessageEditor";
import Vue from "vue";

export default {
  name: "VersionEditor",
  components: {
    VersionMessageEditor,
  },
  props: {
    localizations: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      tabs: [
        { title: "Android: Админ-панель", code: "android" },
        { title: "IOS: Админ-панель", code: "ios" },
        { title: "Android: Google Play", code: "android-market" },
        { title: "IOS: App Store", code: "ios-market" },
      ],
    };
  },
  computed: {
    ...mapState({
      locales: (state) => state.locales.locales,
    }),
  },
  created: function () {
    this.getLocales();
  },
  mounted: function () {
    this.fillDefaultLocalizations(this.localizations.android.admin);
    this.fillDefaultLocalizations(this.localizations.android.market);
    this.fillDefaultLocalizations(this.localizations.ios.admin);
    this.fillDefaultLocalizations(this.localizations.ios.market);
  },
  methods: {
    isAndroid(tab) {
      return tab.code.includes("android");
    },
    getLocalizations(tab) {
      switch (tab.code) {
        case "android":
          return this.localizations.android.admin;
        case "android-market":
          return this.localizations.android.market;
        case "ios":
          return this.localizations.ios.admin;
        case "ios-market":
          return this.localizations.ios.market;
      }
    },
    fillDefaultLocalizations: function (localizations) {
      for (var l of this.locales) {
        if (!(l in localizations)) {
          // localizations[l.locale] = ''
          Vue.set(localizations, l.locale, "");
        }
      }
    },
    getLocales: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("locales/getAll")
        .then((_) => {
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    hasErrorOnTab: function (code) {
      if (code === "android") {
        return (
          this.getMissingLocales(this.localizations.android.admin).length > 0
        );
      }
      if (code === "android-market") {
        return (
          this.getMissingLocales(this.localizations.android.market).length > 0
        );
      }
      if (code === "ios") {
        return this.getMissingLocales(this.localizations.ios.admin).length > 0;
      }
      if (code === "ios-market") {
        return this.getMissingLocales(this.localizations.ios.market).length > 0;
      }
      return false;
    },
    getErrorForTab: function (code) {
      var error = "Незаполнены локали: ";
      var locs = [];
      if (code === "android") {
        locs = this.getMissingLocales(this.localizations.android.admin);
      }
      if (code === "android-market") {
        locs = this.getMissingLocales(this.localizations.android.market);
      }
      if (code === "ios") {
        locs = this.getMissingLocales(this.localizations.ios.admin);
      }
      if (code === "ios-market") {
        locs = this.getMissingLocales(this.localizations.ios.market);
      }
      error += locs.join(", ");
      return error;
    },
    getMissingLocales: function (loc) {
      var missing = [];
      if (!("ru" in loc) || !loc.ru) {
        missing.push("ru");
      }
      if (!("en" in loc) || !loc.en) {
        missing.push("en");
      }
      return missing;
    },
  },
};
</script>

import api from "@/api/api";

class ReleasesApi {
  constructor() {
    this.api = api;
  }

  getAll(page, sort, filters) {
    return this.api.post("releases/", { page, sort, filters });
  }

  new(
    companies,
    version,
    platforms,
    mode,
    message,
    isTestRelease,
    localizations
  ) {
    return this.api.put("releases/", {
      companies,
      version,
      platforms,
      mode,
      message,
      is_test_release: isTestRelease,
      localizations,
    });
  }

  stopRelease(id, stop) {
    return this.api.post(`releases/${id}/report/stop-release/`, {
      stop,
    });
  }

  stopCompanyRelease(release, company) {
    return this.api.post(`releases/${release}/report/stop-release-company/`, {
      company,
    });
  }

  confirmation(
    companies,
    version,
    platforms,
    message,
    isTestRelease,
    localizations
  ) {
    return this.api.post("releases/confirmation", {
      companies,
      version,
      platforms,
      message,
      is_test_release: isTestRelease,
      localizations,
    });
  }

  getReport(id) {
    return this.api.get(`releases/${id}/report/`);
  }

  uploadToAdminPanel(id) {
    return this.api.post(`releases/${id}/upload-admin/`);
  }

  prepareRetryData(id, onlyFailed) {
    return this.api.post(`releases/${id}/prepare-retry-data/`, {
      only_failed: onlyFailed,
    });
  }
}

export default new ReleasesApi();

<template>
  <div class="container" v-if="isEnabled(aboutType)">
    <b-message title="Что на этой странице?" type="is-info" @close="disable({type: aboutType})">
      <slot></slot>
    </b-message>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    aboutType: String
  },
  methods: {
    ...mapActions({
      disable: 'onboarding/disableAbout'
    })
  },
  computed: {
    ...mapGetters({
      isEnabled: 'onboarding/isAboutEnabled'
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 16px;
}
</style>

import api from '@/api/api'

class HmsApi {
  constructor() {
    this.api = api
  }

  uploadAgConnect (file) {
    return this.api.postFile('hms/update_agconnect', 'file', file, [], {})
  }
}

export default new HmsApi()

<template>
    <div class="modal-card change-password">
      <div class="has-text-centered">
        <header class="modal-card-head">
          <p class="modal-card-title">Смена пароля</p>
        </header>
      </div>
      <section class="modal-card-body">
            <div class="has-text-centered">
                <span class="field-title">Новый пароль</span>
                <b-input v-model="newPassword" type="password" required></b-input>
            </div>
            <div class="has-text-centered">
                <span class="field-title">Подтвердите новый пароль</span>
                <b-input v-model="confirmPassword" type="password" required ></b-input>
            </div>
        </section>
            <div class="has-text-centered">
                <button class="button is-primary" type="is-success" @click="changepass()">Изменить</button>
            </div>
        </div>
</template>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {},
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    changepass: function() {
      if (this.newPassword !== this.confirmPassword) {
        toastHandler.apply(this, ['Пароли не совпадают!', 'is-danger'])
        return
      }

      if (this.newPassword.length < 8 || this.confirmPassword.length < 8) {
        toastHandler.apply(this, ['Пароль должен содержать как минимум 8 символов!', 'is-danger'])
        return
      }

      this.$store.dispatch('startLoading')
      this.$store.dispatch('users/changepassword', { new_pas: this.newPassword, confirm: this.confirmPassword })
        .then(_ => {
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Пароль изменен!', 'is-success'])
        })
        .catch(error => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Что-то пошло не так', 'is-danger'])
        })
    },
  },
};
</script>


import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginPage from '@/views/Auth.vue'
import LogoutPage from '@/views/Logout.vue'
import NotFound from '@/views/NotFound.vue'
import ServersList from '@/views/servers/ServersList.vue'
import AddServer from '@/views/servers/AddServer.vue'
import EditServer from '@/views/servers/EditServer'
import AddCompany from '@/views/companies/AddCompany.vue'
import EditCompany from '@/views/companies/EditCompany.vue'
import CompanyReleases from '@/views/companies/CompanyReleases.vue'
import VersionsList from '@/views/versions/VersionsList.vue'
import EditVersion from '@/views/versions/EditVersion.vue'
import ReleasesList from '@/views/releases/ReleasesList.vue'
import NewRelease from '@/views/releases/NewRelease.vue'
import ReleaseDetails from '@/views/releases/ReleaseDetails.vue'
import AdminHome from '@/views/admin/AdminHome.vue'
import FirebaseProjectsList from '@/views/admin/FirebaseProjectsList.vue'
import GooglePlayAccountsList from '@/views/admin/GooglePlayAccountsList.vue'
import AppStoreAccountsList from '@/views/admin/AppStoreAccountsList.vue'
import UsersList from '@/views/admin/UsersList.vue'
import TagsList from '@/views/admin/TagsList.vue'
import HuaweiMobileServicesAccountsList from '@/views/admin/HuaweiMobileServicesAccountsList.vue'
import About from '@/views/docs/About.vue'
import HowToHome from '@/views/docs/HowToHome.vue'
import HowToRelease from '@/views/docs/HowToRelease.vue'
import HowToServers from '@/views/docs/HowToServers.vue'
import HowToCompanies from '@/views/docs/HowToCompanies.vue'
import DocsFirebase from '@/views/docs/Firebase.vue'
import DocsGooglePlay from '@/views/docs/GooglePlay.vue'
import NotificationSettings from '@/views/settings/Notifications.vue'
import ChangePassword from '@/views/settings/ChangePassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/servers',
    component: ServersList
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      showHeader: false,
      availableWithoutAuth: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage,
    meta: {
      showHeader: false,
      availableWithoutAuth: true
    }
  },
  {
    path: '/servers/add',
    name: 'add-server',
    component: AddServer
  },
  {
    path: '/servers/:id/edit',
    name: 'edit-server',
    component: EditServer
  },
  {
    path: '/servers/:id/companies/add',
    name: 'add-company',
    component: AddCompany
  },
  {
    path: '/companies/:id',
    name: 'edit-company',
    component: EditCompany
  },
  {
    path: '/companies/:id/releases',
    name: 'company-releases',
    component: CompanyReleases
  },
  {
    path: '/versions',
    name: 'versions',
    component: VersionsList
  },
  {
    path: '/versions/:id',
    name: 'edit-version',
    component: EditVersion
  },
  {
    path: '/releases',
    name: 'releases',
    component: ReleasesList
  },
  {
    path: '/releases/new',
    name: 'new-release',
    component: NewRelease,
    props: true
  },
  {
    path: '/releases/:id',
    name: 'release-details',
    component: ReleaseDetails
  },
  {
    path: '/admin',
    component: AdminHome,
    children: [
      {
        path: '',
        name: 'admin-home',
        component: FirebaseProjectsList
      },
      {
        path: 'google-play-accounts',
        name: 'admin-google-play-accounts',
        component: GooglePlayAccountsList
      },
      {
        path: 'app-store-accounts',
        name: 'admin-app-store-accounts',
        component: AppStoreAccountsList
      },
      {
        path: 'users',
        name: 'admin-users',
        component: UsersList
      },
      {
        path: 'tags',
        name: 'admin-tags',
        component: TagsList
      },
      {
        path: 'hms-config',
        name: 'admin-hms-config',
        component: HuaweiMobileServicesAccountsList
      }
    ]
  },
  {
    path: '/docs/about',
    name: 'docs-about',
    component: About
  },
  {
    path: '/docs/how-to',
    component: HowToHome,
    children: [
      {
        path: '/docs/how-to/release',
        name: 'docs-how-to-release',
        component: HowToRelease
      },
      {
        path: '/docs/how-to/server',
        name: 'docs-how-to-servers',
        component: HowToServers
      },
      {
        path: '/docs/how-to/companies',
        name: 'docs-how-to-companies',
        component: HowToCompanies
      },
      {
        path: '/docs/firebase',
        name: 'docs-firebase',
        component: DocsFirebase
      },
      {
        path: '/docs/google-play',
        name: 'docs-google-play',
        component: DocsGooglePlay
      }
    ]
  },
  {
    path: '/settings/notifications',
    name: 'settings-notifications',
    component: NotificationSettings
  },
  {
    path: '/settings/changepassword',
    name: 'settings-password',
    component: ChangePassword
  },
  {
    path: '*',
    component: NotFound
  }
]

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false
    }

    position.x = 0
    position.y = 0
    return position
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router

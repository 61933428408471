<template>
  <div>
    <section>
      <b-tabs position="is-centered" v-model="selectedTab">
        <b-tab-item label="Вход"></b-tab-item>
        <b-tab-item label="Регистрация"></b-tab-item>
      </b-tabs>
    </section>
    <section>
      <div class="columns is-centered">
        <div class="column is-half">
          <form v-if="isLoginTabSelected">
            <b-field label="Логин">
              <b-input v-model="login"></b-input>
            </b-field>
            <b-field label="Пароль">
              <b-input v-model="password" type="password" @keyup.enter.native="auth" password-reveal></b-input>
            </b-field>
            <b-field>
              <b-button type="is-primary" @click="auth()">Войти</b-button>
            </b-field>
          </form>
        </div>
      </div>
      <div class="columns is-centered">
        <b-message type="is-success" v-if="isRegistrationTabSelected">
              Внимание: Регистрация новой учетной записи происходит через администратора
          </b-message>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  created: function () {
    if (this.$store.getters['auth/isAuthorized']) {
      // already authorized
      this.$router.push('/')
    }
  },
  data: function () {
    return {
      login: '',
      password: '',
      selectedTab: 0
    }
  },
  methods: {
    auth: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('auth/login', { login: this.login, password: this.password })
        .then(_ => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    isLoginTabSelected: function () {
      return this.selectedTab === 0
    },
    isRegistrationTabSelected: function () {
      return this.selectedTab === 1
    }
  },
  components: {
  }
}
</script>

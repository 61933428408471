<template>
  <section>
    <b-button @click="showHMSModal()" icon-left="plus" type="is-primary">Добавить учетную запись Huawei Mobile Services</b-button>

    <template v-if="accounts != null && accounts.length > 0">
      <b-table :data="accounts">
          <b-table-column label="Название" v-slot="props">{{ props.row.name }}</b-table-column>
          <b-table-column v-slot="props">
            <div class="buttons-block">
              <b-button @click="showUpdateHMSModal(props.row)">
                 <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button @click="deleteAccount(props.row)">
                 <b-icon icon="delete"></b-icon>
              </b-button>
          </div>
        </b-table-column>
      </b-table>
    </template>

    <p v-else class="is-size-3 has-text-centered huawei-no-list">Учетные записи не добавлены</p>

    <b-modal
      :active.sync="isHMSModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <huawei-mobile-service-add-account-modal @create="getAccounts" />
    </b-modal>

    <b-modal
      :active.sync="isUpdateHMSModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <huawei-mobile-service-update-account-modal @update="getAccounts"
      :account="updatedAccount"/>
    </b-modal>
  </section>
</template>

<style lang="scss" scoped>
.buttons-block {
  position: relative;
  right: -80%;
}
.buttons-block button {
  margin-right: 20px;
}
.huawei-no-list {
  margin-top: 20px;
}
</style>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'
import HuaweiMobileServiceAddAccountModal from '@/components/market/HuaweiMobileServiceAddAccountModal.vue'
import HuaweiMobileServiceUpdateAccountModal from '@/components/market/HuaweiMobileServiceUpdateAccountModal.vue'

export default {
  created: function () {
    this.getAccounts()
  },
  data() {
    return {
      isHMSModalActive: false,
      isUpdateHMSModalActive: false,
      updatedAccount: {}
    }
  },
  methods: {
    getAccounts: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/getAccounts')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    deleteAccount: function (account) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/deleteHuaweiAccount', { id: account.id })
        .then(_ => {
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Аккаунт удален!', 'is-danger'])
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    showHMSModal () {
      this.isHMSModalActive = true
    },
    showUpdateHMSModal (account) {
      this.updatedAccount = account
      this.isUpdateHMSModalActive = true
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.marketAccounts.huawei
    })
  },
  components: {
    HuaweiMobileServiceAddAccountModal,
    HuaweiMobileServiceUpdateAccountModal
  }
}
</script>

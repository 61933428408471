import MarketAccountsApi from '@/api/marketAccounts'

const state = {
  android: null,
  huawei: null,
  ios: null
}

const getters = {
}

const mutations = {
  storeAccounts (state, accounts) {
    state.android = accounts.android
    state.huawei = accounts.huawei
    state.ios = accounts.ios
  },
  addAndroidAccount (state, account) {
    state.android.push(account)
  },
  addHuaweiAccount (state, account) {
    state.huawei.push(account)
  },
  addIosAccount (state, account) {
    state.ios.push(account)
  }
}
const actions = {
  getAccounts ({ commit }) {
    return MarketAccountsApi.getAll().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeAccounts', res.data.accounts)
      return Promise.resolve()
    })
  },
  addAndroidAccount ({ commit }, { name, creds, testPackage }) {
    return MarketAccountsApi.addAndroidAccount(name, creds, testPackage).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      commit('addAndroidAccount', res.data.account)
      return Promise.resolve()
    })
  },
  addHuaweiAccount ({ commit }, { name, creds }) {
    return MarketAccountsApi.addHuaweiAccount(name, creds).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      commit('addHuaweiAccount', res.data.account)
      return Promise.resolve()
    })
  },
  updateHuaweiAccount ({ commit }, { id, name, creds }) {
    return MarketAccountsApi.updateHuaweiAccount(id, name, creds).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      return Promise.resolve()
    })
  },
  deleteHuaweiAccount ({ dispatch }, { id }) {
    return MarketAccountsApi.deleteHuaweiAccount(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      return dispatch('getAccounts')
    })
  },
  addIosAccount ({ commit }, { name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision }) {
    return MarketAccountsApi.addIosAccount(name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      commit('addIosAccount', res.data.account)
      return Promise.resolve()
    })
  },
  updateIosAccount ({ commit }, { name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision }) {
    return MarketAccountsApi.updateIosAccount(name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      return Promise.resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

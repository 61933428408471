<template>
  <section>
    <b-button @click="showAppStoreModal()" icon-left="plus" type="is-primary">
      Добавить учетную запись Apple App Store
    </b-button>

    <template v-if="accounts != null && accounts.length > 0">
      <b-table :data="accounts">
        <b-table-column label="Название" v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Sign Team ID" v-slot="props">
          {{ props.row.sign_team_id }}
        </b-table-column>
        <b-table-column label="Fastlane team ID" v-slot="props">
          {{ props.row.fastlane_team_id }}
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button @click="showUpdateAppStoreModal(props.row)">
            <b-icon icon="pencil"></b-icon>
          </b-button>
        </b-table-column>
      </b-table>
    </template>

    <p v-else class="is-size-3 has-text-centered apple-no-list">
      Учетные записи не добавлены
    </p>

    <b-modal
      :active.sync="isAppStoreModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <app-store-add-account-modal @create="getAccounts" />
    </b-modal>

    <b-modal
      :active.sync="isAppUpdateStoreModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <app-store-update-account-modal
        @update="getAccounts"
        :account="updatedAccount"
      />
    </b-modal>
  </section>
</template>

<style lang="scss" scoped>
.apple-no-list {
  margin-top: 20px;
}
</style>

<script>
import { mapState } from 'vuex'
import AppStoreAddAccountModal from '@/components/market/AppStoreAddAccountModal.vue'
import AppStoreUpdateAccountModal from '@/components/market/AppStoreUpdateAccountModal.vue'

export default {
  created: function () {
    this.getAccounts()
  },
  data: function () {
    return {
      updatedAccount: {},
      isAppStoreModalActive: false,
      isAppUpdateStoreModalActive: false
    }
  },
  methods: {
    getAccounts: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/getAccounts')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    showAppStoreModal () {
      this.isAppStoreModalActive = true
    },
    showUpdateAppStoreModal (account) {
      this.updatedAccount = account
      this.isAppUpdateStoreModalActive = true
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.marketAccounts.ios
    })
  },
  components: {
    AppStoreAddAccountModal,
    AppStoreUpdateAccountModal
  }
}
</script>

<template>
  <b-navbar shadow type="is-primary" class="header-block">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <b-icon icon="rocket-launch" size="is-small" class="icon-menu"></b-icon>
        <b>Релиз сервис</b>
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'home' }"
        :active="$route.name == 'home'"
      >
        Компании
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name: 'versions' }"
        :active="$route.name == 'versions'"
      >
        Версии
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name: 'releases' }"
        :active="$route.name == 'releases'"
      >
        Отчеты
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name: 'admin-home' }"
        >
          Администрирование
      </b-navbar-item>

      <b-navbar-item tag="div">
        <b-button
          tag="router-link"
          :to="{name: 'new-release'}"
          icon-left="rocket"
          type="is-primary"
          outlined
          inverted
        >
          Запустить релиз
        </b-button>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ name: 'docs-how-to-release' }">
        <b-icon icon="newspaper-variant-outline" size="is-small" style="margin-right: 5px;"></b-icon>
        Инструкция
      </b-navbar-item>

      <b-navbar-dropdown :label="user.login" v-if="user" right>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'settings-notifications' }"
        >
          Настройки уведомлений
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'docs-about' }">
          О сервисе
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'settings-password' }">
          Изменить пароль
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'logout' }">
          Выйти
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<style lang="scss" scoped>
.header-block {
  margin-bottom: 25px;
}
.icon-menu {
  margin-right: 5px;
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  components: {
  }
}
</script>

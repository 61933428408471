import ServersApi from "@/api/servers";

const state = {
  available: null,
};

const getters = {};

const mutations = {
  storeServers(state, servers) {
    state.available = servers;
  },
  addServer(state, server) {
    if (state.available) {
      // TODO hack
      state.available.push(server);
    }
  },
  editServer(state, server) {
    state.available.map((s) => {
      if (s.id === server.id) {
        return server;
      }

      return s;
    });
  },
};
const actions = {
  getAll({ commit }) {
    return ServersApi.getAll().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeServers", res.data.servers);

      return Promise.resolve();
    });
  },
  add(
    { commit },
    {
      name,
      urlWeb,
      urlApi,
      urlAdmin,
      adminLogin,
      adminPassword,
      s3ProxyToken,
      behindFirewall,
    }
  ) {
    return ServersApi.add(
      name,
      urlWeb,
      urlApi,
      urlAdmin,
      adminLogin,
      adminPassword,
      s3ProxyToken,
      behindFirewall
    ).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("addServer", res.data.server);

      return Promise.resolve();
    });
  },
  edit({ commit }, { id, name, server }) {
    return ServersApi.edit(id, name, server).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editServer", res.data.server);

      return Promise.resolve();
    });
  },
  addCertificates({ commit }, { files, serverId }) {
    return ServersApi.sendCertificates(files, serverId).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editServer", res.data.server);

      return Promise.resolve();
    });
  },
  editCertificate({ commit }, { serverId, certificateId, name }) {
    return ServersApi.editCertificate(serverId, certificateId, name).then(
      (res) => {
        if ("error" in res.data) {
          return Promise.reject(res.data.error);
        }

        commit("editServer", res.data.server);

        return Promise.resolve();
      }
    );
  },
  deleteCertificate({ commit }, { serverId, certificateId }) {
    return ServersApi.deleteCertificate(serverId, certificateId).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editServer", res.data.server);

      return Promise.resolve();
    });
  },
  deleteServer({ dispatch }, { id }) {
    return ServersApi.delete(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return dispatch("getAll");
    });
  },
  updateAssetlinks(_, { serverId }) {
    return ServersApi.updateAssetlinks(serverId).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  updateAppleAppSiteAssociations(_, { serverId }) {
    return ServersApi.updateAppleAppSiteAssociations(serverId).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <b-field type="is-primary">
      <b-button @click="showFigmaHistoryModal()"
        type="is-primary"
        size="is-small"
        icon-left="eye">
          Посмотреть историю изменений Figma
      </b-button>

      <b-button
        type="is-primary"
        size="is-small"
        icon-left="delete"
        class="button-figma"
        outlined
        @click="resetFigmaCache()">
          Сбросить кэш для Figma
      </b-button>
    </b-field>

    <label class="label" slot="label">
      Bundle ID
      <b-tooltip
        label="Пакет приложения. Пример: ru.equeo.iprofi.test, при нажатии кнопки подставятся значения в поля design name и scheme "
        multilined
      >
        <b-icon icon="help-circle-outline" size="is-small"></b-icon>
      </b-tooltip>
    </label>
    <b-field grouped type="is-primary">
      <b-input
        v-model="bundleId"
        placeholder="ru.equeo.iprofi.test"
        expanded
      ></b-input>

      <b-checkbox v-model="useDifferentFirebaseId">
        Другой ID для Firebase
      </b-checkbox>
    </b-field>
    <div style="margin-top: 10px;">
        <b-button @click="handleBundleClick" class="is-primary is-small">Подставить значения</b-button>
    </div>
    <b-field type="is-primary">
      <b-input
        v-model="firebaseId"
        v-if="useDifferentFirebaseId"
        placeholder="ru.equeo.iprofi.test"
        expanded
      ></b-input>
    </b-field>
    <firebase-ios-help-message
      :bundleId="useDifferentFirebaseId ? firebaseId : bundleId"
      :firebaseProjects="firebaseProjects"
      ref="firebaseMessage"
      @create="launchFirebaseModal()"
    />

    <b-field type="is-primary">
      <b-input v-model="scheme" placeholder="scheme"></b-input>
      <template slot="label">
        Scheme
        <b-tooltip
          label="подставляется кнопкой из bundleID"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="designName" placeholder="design name"></b-input>
      <template slot="label">
        Design Name
        <b-tooltip label="Путь к файлу параметризации на s3" multilined>
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-select v-model="statusBarStyle">
        <option value="UIStatusBarStyleDarkContent">UIStatusBarStyleDarkContent</option>
        <option value="UIStatusBarStyleLightContent">UIStatusBarStyleLightContent</option>
      </b-select>
      <template slot="label">Стиль статус-бара</template>
    </b-field>

    <b-field>
      <b-checkbox v-model="needPoweredBy">
        Добавить подпись "Powered by eQueo"
        <b-tooltip
          label="Если эта галочка включена, на экране авторизации будет добавлена подпись Powered by eQueo"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>

    <b-field type="is-primary">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <b-input v-model="figmaId"></b-input>
        </div>
        <div class="control">
          <b-button @click="parseFigmaUrl" class="is-primary is-fullwidth">Спарсить ID</b-button>
        </div>
      </div>
      <template slot="label">
        Figma ID
        <b-tooltip
          label="Вставьте URL, figma ID будет найден после нажатия кнопки"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field label="Базовый ipa файл" type="is-primary">
      <b-select v-model="ipaName">
        <option v-for="p in availableIpaNames" :value="p" :key="p">
          {{ p }}
        </option>
      </b-select>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="localiseId"></b-input>
      <template slot="label">
        Localise ID
        <b-tooltip
          label="ID проекта в Localise, для указания переопределенных текстовок"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="team_id"></b-input>
      <template slot="label">
        Team ID
        <b-tooltip
          label="Подставляется в app id с приоритетом"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field>
      <b-checkbox v-model="needZoom">
        Добавить Zoom
        <b-tooltip
          label="Если эта галочка включена - в приложение будет добавлен Zoom"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>

    <b-field
      label="Какую учетную запись App Store использовать для подписи и публикации приложения"
      type="is-primary"
    >
      <b-select v-model="appStoreAccount">
        <option v-for="a in appStoreAccounts" :value="a.id" :key="a.id">
          {{ a.name }}
        </option>
      </b-select>
    </b-field>

    <b-field grouped>
      <b-field>
        <b-checkbox v-model="isMarket">
          Загружать в Apple App Store
          <b-tooltip
            label="Если эта галочка включена - приложение будет загружено в App Store"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="isClientMarket" :disabled="!isMarket">
          Клиент публикует сборку
          <b-tooltip
            label="Если эта галочка включена - сборка будет собрана как для App Store, но не будет залита. Эту сборку нужно передать клиенту, чтобы он залил"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </b-checkbox>
      </b-field>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="marketUrl"></b-input>
      <template slot="label">
        AppStore URL
        <b-tooltip
          label="URL для приложения в аппстор (https://apps.apple.com/us/app/ekvio/id1106896463). Будет использовано для заливки приложения в админ-панель"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-modal
      :active.sync="isFirebaseModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <firebase-create-app-modal
        :iosBundle="useDifferentFirebaseId ? firebaseId : bundleId"
        :firebaseProjects="firebaseProjects"
        @create="createIosAppInFirebase"
      />
    </b-modal>

    <b-modal
      :active.sync="isFigmaHistoryModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <figma-history-modal :history="figmaHistory" :company="company" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FirebaseIosHelpMessage from '@/components/FirebaseIosHelpMessage.vue'
import FirebaseCreateAppModal from '@/components/FirebaseCreateAppModal.vue'
import FigmaHistoryModal from '@/components/FigmaHistoryModal.vue'

export default {
  components: {
    FirebaseIosHelpMessage,
    FirebaseCreateAppModal,
    FigmaHistoryModal
  },

  props: {
    company: Object
  },

  model: {
    prop: 'company',
    event: 'input'
  },

  data: function () {
    return {
      bundleId: this.company.ios.ios_bundle_id,
      firebaseId: this.company.ios.ios_firebase_id,
      useDifferentFirebaseId: !(this.company.ios.ios_firebase_id == null || this.company.ios.ios_firebase_id === this.company.ios.ios_bundle_id),
      scheme: this.company.ios.ios_scheme_name,
      designName: this.company.ios.ios_design_name,
      needPoweredBy: this.company.ios.ios_need_powered_by,
      needZoom: this.company.ios.ios_need_zoom,
      statusBarStyle: this.company.ios.ios_status_bar_style,
      figmaId: this.company.ios.ios_figma_id,
      ipaName: this.company.ios.ios_base_ipa_name,
      localiseId: this.company.ios.ios_localise_project_id,
      team_id: this.company.ios.ios_team_id,
      isMarket: this.company.ios.ios_is_market,
      isClientMarket: this.company.ios.ios_is_market_client_upload,
      marketUrl: this.company.ios.ios_market_url,
      appStoreAccount: this.company.ios.ios_market_account == null ? 0 : this.company.ios.ios_market_account,
      // androidDataUrl: this.value.android_data_url,
      availableIpaNames: [
        'build.ipa',
        'appstore.ipa'
      ],
      approveDesign: this.company.ios.ios_approved_design,
      isFigmaHistoryModalActive: false,
      figmaHistory: null,
      isFirebaseModalActive: false
    }
  },

  computed: {
    ...mapState({
      firebaseProjects: state => state.firebase.projects,
      appStoreAccounts: state => state.marketAccounts.ios
    })
  },

  watch: {
    bundleId: function () {
      this.updateValue()
    },
    firebaseId: function () {
      this.updateValue()
    },
    scheme: function () {
      this.updateValue()
    },
    designName: function () {
      this.updateValue()
    },
    needPoweredBy: function () {
      this.updateValue()
    },
    statusBarStyle: function () {
      this.updateValue()
    },
    figmaId: function () {
      this.updateValue()
    },
    isMarket: function () {
      if (!this.isMarket) {
        this.isClientMarket = false
      }
      this.updateValue()
    },
    isClientMarket: function () {
      this.updateValue()
    },
    marketUrl: function () {
      this.updateValue()
    },
    appStoreAccount: function () {
      this.updateValue()
    },
    ipaName: function () {
      this.updateValue()
    },
    localiseId: function () {
      this.updateValue()
    },
    team_id: function () {
      this.updateValue()
    },
    needZoom: function () {
      this.updateValue()
    },
    useDifferentFirebaseId: function () {
      this.updateValue()
    },
    approveDesign: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/approveDesign',
        {
          id: this.company.id,
          platform: 'ios',
          approve: this.approveDesign
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },

  mounted: function () {
  },

  methods: {
    updateValue: function () {
      var obj = {
        ...this.company,
        ios: {
          ios_bundle_id: this.bundleId,
          ios_firebase_id: this.useDifferentFirebaseId ? this.firebaseId : null,
          ios_scheme_name: this.scheme,
          ios_design_name: this.designName,
          ios_need_powered_by: this.needPoweredBy,
          ios_status_bar_style: this.statusBarStyle,
          ios_figma_id: this.figmaId,
          ios_base_ipa_name: this.ipaName,
          ios_localise_project_id: this.localiseId,
          ios_team_id: this.team_id,
          ios_need_zoom: this.needZoom,
          ios_is_market: this.isMarket,
          ios_is_market_client_upload: this.isClientMarket,
          ios_market_url: this.marketUrl,
          ios_market_account: this.appStoreAccount
        }
      }

      this.$emit('input', obj)
    },
    handleBundleClick() {
      const bundleParts = this.bundleId.split(".");
      if (bundleParts.length >= 1) {
        const lastWord = bundleParts[bundleParts.length - 1];
        this.scheme = lastWord;
        this.designName = lastWord;
      } 
    },
    parseFigmaUrl() {
      const url = this.figmaId
      const pattern = /https:\/\/www\.figma\.com\/(file|proto)\/([a-zA-Z0-9]+)\/.*$/;

      // Extract the file ID using the regular expression
      const match = url.match(pattern);

      if (match && match.length >= 3) {
        // Return the file ID (group 2 in the regular expression match)
        this.figmaId = match[2];
      } else {
        // Invalid Figma URL, return null or handle the error accordingly
        this.figmaId = 'invalid url';
      }
    },
    launchFirebaseModal: function () {
      this.isFirebaseModalActive = true
    },
    resetFigmaCache: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/resetFigmaCache',
        {
          id: this.company.id,
          platform: 'android'
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    createIosAppInFirebase: function (projectId) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('firebase/addIosApp',
        {
          projectId: projectId,
          bundleId: this.bundleId
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          this.isFirebaseModalActive = false
          this.$refs.firebaseMessage.refreshMessage()
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    showFigmaHistoryModal: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/getFigmaHistory',
        {
          id: this.company.id
        })
        .then(res => {
          this.$store.dispatch('stopLoading')

          this.isFigmaHistoryModalActive = true
          this.figmaHistory = res
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button-figma {
  margin-left: 10px;
}
</style>

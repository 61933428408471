<template>
  <div class="logs" v-html="formatedLogs">
    <!-- {{ formatedLogs }} -->
  </div>
</template>

<script>
export default {
  props: {
    logs: String,
  },
  computed: {
    formatedLogs: function () {
      if (!this.logs) {
        return "";
      }

      return this.logs
        .replace(/\n/gi, "<br />")
        .replace(/\t/gi, "&nbsp;&nbsp;");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.logs {
  padding: 16px;
  white-space: pre;
  background-color: $grey-darker;
  border-radius: 8px;
  color: $white;
  overflow: scroll;
  font-family: monospace, monospace;
}
</style>

<template>
  <div id="app">
    <the-header v-if="showHeader" />

    <router-view />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import TheHeader from '@/components/Header'
import { mapState } from 'vuex'

export default {
  created: function () {
    this.refreshUserData()
  },
  methods: {
    refreshUserData () {
      this.$store.dispatch('auth/loadTokensFromStorage')
      this.$store.dispatch('onboarding/loadFromStorage')
    }
  },
  computed: {
    showHeader () {
      if (this.$route.matched.length === 0) {
        return true
      }

      if (this.$route.matched[0].meta !== null) {
        if ('showHeader' in this.$route.matched[0].meta) {
          return this.$route.matched[0].meta.showHeader
        }
      }
      return true
    },
    ...mapState({
      isLoading: state => state.isLoading
    })
  },
  components: {
    TheHeader
  }
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/_all";

$primary: rgb(0, 84, 108);
$link: $primary;

$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "green": (
    $green,
    $green-invert,
  ),
  "grey": (
    $grey-lightest,
    $black,
  ),
  "red": (
    $red,
    $red-invert,
  ),
  "blue": (
    $blue,
    $blue-invert,
  ),
);

@import "~bulma/bulma.sass";
@import "~buefy/src/scss/buefy";
@import "~@mdi/font/css/materialdesignicons.min.css";

#app {
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <div class="modal-card user-new-account">
    <header class="modal-card-head">
      <p class="modal-card-title">Добавить нового пользователя</p>
    </header>
    <section class="modal-card-body">
      <b-field type="is-primary">
        <b-input v-model="login" placeholder="Логин"></b-input>
      </b-field>

      <b-field>
        <b-input v-model="password" type="password" placeholder="Пароль" @keyup.enter.native="register" password-reveal></b-input>
      </b-field>

    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Отменить</button>
      <button class="button is-primary" type="is-success" @click="register()">Добавить</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.user-new-account {
  width: auto;
}
</style>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
  },
  data: function () {
    return {
      login: '',
      password: ''
    }
  },
  methods: {
    register: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('users/register', { login: this.login, password: this.password })
        .then(_ => {
          this.$store.dispatch('stopLoading')
          this.$parent.close()
          toastHandler.apply(this, ['Новый пользователь добавлен!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

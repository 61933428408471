<template>
  <section>
    <div class="container" v-if="self">
      <template>
        <b-tabs type="is-boxed">
            <b-tab-item label="Уведомления" icon="bell-ring">
              <p class="is-size-3">Настройки уведомлений</p>
              <div class="subscription-block">
                <b-field>
                  <b-checkbox v-model="notifyRelease">Присылать уведомление при окончании релиза</b-checkbox>
                </b-field>

                <b-field>
                  <b-checkbox v-model="notifySuccessBuilds">Присылать уведомление при успешном завершении сборки</b-checkbox>
                </b-field>

                <b-field>
                  <b-checkbox v-model="notifyFailedBuilds">Присылать уведомление в случае ошибки сборки</b-checkbox>
                </b-field>
              </div>
              <b-field>
                <b-button type="is-primary" @click="editSettings()">Сохранить</b-button>
              </b-field>
            </b-tab-item>

            <b-tab-item label="Теги" icon="tag-multiple">
              <div>
                <p class="is-size-3">Подписка на теги</p>
                <div class="subscription-block">
                  <b-table :data="tags">
                    <template slot-scope="props">
                      <b-table-column label="Название">
                        <b-tag :type="'is-' + props.row.color">{{props.row.title}}</b-tag>
                      </b-table-column>
                      <b-table-column>
                        <b-button
                          v-if="props.row.is_subscribed"
                          icon-left="bell-off"
                          @click="unsubscribeFromTag(props.row)"
                        >Отписаться</b-button>
                        <b-button v-else icon-left="bell-plus" @click="subscribeToTag(props.row)">Подписаться</b-button>
                      </b-table-column>
                    </template>
                  </b-table>
                  <b-field>
                    <b-button type="is-primary" @click="editSettings()">Сохранить</b-button>
                  </b-field>
                </div>
                <!-- <b-field>
                  <b-button type="is-primary" @click="editSettings()">Сохранить</b-button>
                </b-field> -->
              </div>
            </b-tab-item>

            <b-tab-item label="Чат-бот" icon="chat-alert">
              <p class="is-size-3">Добавить уведомления в Discord</p>
              <div class="social-ping">
                <template v-if="!self.discord_id">
                  <p>
                    Чтобы добавить уведомления в дискорд, откройте чат с ботом
                    <span
                      class="has-text-weight-semibold"
                    >EqueoReleaseService#5833</span> и отправьте ему команду:
                  </p>

                  <p class="token">
                    <span class="is-family-monospace has-background-dark has-text-white">
                      ?auth {{self.discord_token}}
                    </span>
                  </p>
                </template>
                <template v-else>
                  <p>Учетная запись Discord привязана</p>
                </template>
              </div>
              <p class="is-size-3">Добавить уведомления в Telegram</p>
              <div class="social-ping">
                <template v-if="!self.telegram_id">
                  <p>
                    Чтобы добавить уведомления в Telegram, откройте чат с ботом
                    <span class="has-text-weight-semibold">
                      <a href="t.me/EkvioRSBot">@EkvioRSBot</a>
                    </span> и отправьте ему команду:
                  </p>

                  <p class="token">
                    <span class="is-family-monospace has-background-dark has-text-white">
                      /auth {{self.telegram_token}}
                    </span>
                  </p>
                </template>
                <template v-else>
                  <p>Учетная запись Telegram привязана</p>
                </template>
              </div>
            </b-tab-item>
          </b-tabs>
        </template>
      </div>
    </section>
</template>

<style lang="scss" scoped>
.token {
  margin-top:10px;
}
.token span {
  padding: 8px;
}
.subscription-block {
  margin-top:10px;
  margin-bottom:20px;
}
.social-ping {
  margin-top:10px;
  margin-bottom:10px;
}
</style>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  created: function () {
    this.getSelf()
  },
  data: function () {
    return {
      notifyRelease: false,
      notifySuccessBuilds: false,
      notifyFailedBuilds: false
    }
  },
  methods: {
    getSelf: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('users/getSelf')
        .then(res => {
          this.$store.dispatch('stopLoading')

          this.notifyRelease = this.self.notification_notify_release
          this.notifySuccessBuilds = this.self.notification_notify_success_builds
          this.notifyFailedBuilds = this.self.notification_notify_failed_builds
        })
        .then(_ => {
          return this.$store.dispatch('tags/getAll')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    editSettings: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('users/saveSettings', { notifyRelease: this.notifyRelease, notifySuccessBuilds: this.notifySuccessBuilds, notifyFailedBuilds: this.notifyFailedBuilds })
        .then(_ => {
          this.$store.dispatch('stopLoading')

          this.notifyRelease = this.self.notification_notify_release
          this.notifySuccessBuilds = this.self.notification_notify_success_builds
          this.notifyFailedBuilds = this.self.notification_notify_failed_builds
          toastHandler.apply(this, ['Настройки сохранены!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    subscribeToTag: function (tag) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('tags/subscribe', { id: tag.id })
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    unsubscribeFromTag: function (tag) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('tags/unsubscribe', { id: tag.id })
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    ...mapState({
      self: state => state.user,
      tags: state => state.tags.available
    })
  },
  components: {
  }
}
</script>

<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Сменить название сертификата
      </p>
    </header>
    <section class="modal-card-body">
      <b-field label="Сменить название сертификата">
        <b-input v-model="name"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Отменить
      </button>
      <button class="button is-primary" @click="save()">Сохранить</button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    certificate: Object
  },
  data: function () {
    return {
      currentCertificate: this.certificate,
      name: this.certificate.name
    }
  },
  methods: {
    save: function () {
      this.$emit('changeName', {
        id: this.currentCertificate.id,
        name: this.name
      })
    }
  }
}
</script>

<template>
  <section>
    <div class="container" v-if="currentServer">
      <b-tabs>
        <b-tab-item :key="0">
          <template slot="header">
            <span>Настройка</span>
          </template>

          <edit-settings-server v-model="currentServer" />
        </b-tab-item>

        <b-tab-item :key="1">
          <template slot="header">
            <span>Сертификаты</span>
          </template>

          <add-certificate-server
            v-model="currentServer"
            v-on:update-servers="eventListener()"
          />
          <!-- <edit-ios v-model="currentCompany" /> -->
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

import EditSettingsServer from "@/components/servers/EditSettingsServer.vue";
import AddCertificateServer from "@/components/servers/AddCertificateServer.vue";

export default {
  created: function () {
    if (this.servers === null) {
      this.getServers();
    } else {
      this.refreshModel();
    }
  },
  data: function () {
    return {
      currentServer: null,
    };
  },
  methods: {
    getServers: function () {
      this.$store
        .dispatch("loadAll")
        .then((res) => {
          this.refreshModel();

          if (!this.currentServer) {
            this.$router.push("/404");
          }

          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    refreshModel: function () {
      this.currentServer = this.servers.find(
        (s) => s.id === parseInt(this.$route.params.id)
      );
    },
    eventListener: function () {
      this.getServers();
    },
  },
  computed: mapState({
    servers: (state) => state.servers.available,
  }),
  components: {
    EditSettingsServer,
    AddCertificateServer,
  },
};
</script>

<template>
  <section>
    <b-field grouped type="is-primary">
      <b-input v-model="tagName"></b-input>
      <b-button @click="addTag" icon-left="plus" type="is-primary">Добавить тег</b-button>
    </b-field>

    <b-table :data="tags">
        <b-table-column label="Название" v-slot="props">
          <b-tag :type="'is-' + props.row.color">{{props.row.title}}</b-tag>
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button v-if="!props.row.is_system_tag" @click="deleteTag(props.row)">
            <b-icon icon="delete"></b-icon>
          </b-button>
        </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  created: function () {
    this.$store.dispatch('tags/getAll')
  },
  data: function () {
    return {
      tagName: ''
    }
  },
  methods: {
    addTag: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('tags/addTag', { title: this.tagName })
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    deleteTag: function (tag) {
      if (confirm('Are you sure you want to delete this tag?')) {
        this.$store.dispatch('startLoading')

        this.$store.dispatch('tags/deleteTag', { id: tag.id })
          .then(_ => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Тег удален!', 'is-danger'])
          })
          .catch(error => {
            this.$handleError(error)

            this.$store.dispatch('stopLoading')
          })
    }
  }
    // onReleaseClick: function (release) {
    //   this.$router.push({ name: 'release-details', params: { id: release.id } })
    // }
  },
  computed: {
    ...mapState({
      tags: state => state.tags.available
    })
  },
  components: {
  }
}
</script>

import api from "@/api/api";

class ServersApi {
  constructor() {
    this.api = api;
  }

  getAll() {
    return this.api.get("servers/");
  }

  add(
    name,
    urlWeb,
    urlApi,
    urlAdmin,
    adminLogin,
    adminPassword,
    s3ProxyToken,
    behindFirewall
  ) {
    return this.api.post("servers/", {
      name,
      url_web: urlWeb,
      url_api: urlApi,
      url_admin: urlAdmin,
      admin_login: adminLogin,
      admin_password: adminPassword,
      s3_proxy_token: s3ProxyToken,
      behind_firewall: behindFirewall,
    });
  }

  sendCertificates(files, serverId) {
    const fields = [
      {
        name: "server_id",
        value: serverId,
      },
    ];
    return this.api.postFiles("servers/add_certificates", files, fields, {});
  }

  deleteCertificate(serverId, certificateId) {
    return this.api.post("servers/delete_certificate", {
      server_id: serverId,
      certificate_id: certificateId,
    });
  }

  editCertificate(serverId, certificateId, name) {
    return this.api.post("servers/edit_certificate", {
      server_id: serverId,
      certificate_id: certificateId,
      name: name,
    });
  }

  edit(id, name, server) {
    return this.api.post(`servers/${id}/`, {
      ...server,
      name,
    });
  }

  delete(id) {
    return this.api.delete(`servers/${id}/`);
  }

  updateAssetlinks(id) {
    return this.api.get(`servers/${id}/assetlinks/`);
  }

  updateAppleAppSiteAssociations(id) {
    return this.api.get(`servers/${id}/aasa/`);
  }
}

export default new ServersApi();

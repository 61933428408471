<template>
  <section>
    <div class="container">
      <div class="google-play-block">
        <p class="is-size-3">
          Как автоматизировать заливку в Google Play?
          </p>
        <p>
          <span>
            Чтобы добавить учетную запись Google Play для автоматической заливки приложений:
          </span>
        </p>

        <p>
          Создайте Service Account в интерфейсе Google Play. Для этого перейдите в раздел
          <code class="is-family-monospace has-background-dark has-text-white">
            Settings -> Developer Account -> Api Access
          </code>.
          Нажмите кнопку
          <code class="is-family-monospace has-background-dark has-text-white">
            Create Service Account
          </code>
          и далее следуйте инструкции. При выборе ролей для сервисного аккаунта необходимо выбрать роль
          <code class="is-family-monospace has-background-dark has-text-white">
            Editor
          </code>.
          Там же нужно создать json ключ для доступа к API. После создания сервисного аккаунта необходимо предоставить этому аккаунту доступ к приложениям в настройках Google Play
        </p>

        <p>
          Загрузите файл credentials.json в релиз-сервис.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.google-play-block p {
  text-align: justify;
  margin-top: 20px;
}
.google-play-block span{
  font-weight: bold;
}
</style>

<script>
export default {

}
</script>

<template>
  <b-table :data="companies" hoverable v-if="companies.length > 0">
    <b-table-column label="ID" v-slot="props">
      {{ props.row.company_id }}
    </b-table-column>

    <b-table-column v-slot="props">
      <b-icon icon="archive" v-if="props.row.is_archived"></b-icon>
    </b-table-column>

    <b-table-column label="Название компании" v-slot="props">
      <div>
        {{
          props.row.name.length > 30
            ? props.row.name.slice(0, 30) + "..."
            : props.row.name
        }}
      </div>

      <b-taglist>
        <b-tag
          v-for="tag in props.row.tags"
          :key="tag.id"
          :type="'is-' + tag.color"
        >
          {{ tag.title }}
        </b-tag>
      </b-taglist>
    </b-table-column>

    <b-table-column label="Название приложения" v-slot="props">
      <p>{{ props.row.app_name }}</p>
      <p>{{ props.row.app_name_android }}</p>
      <p>{{ props.row.app_name_ios }}</p>
    </b-table-column>

    <b-table-column label="Последняя версия приложения" v-slot="props">
      <span>
        <template v-if="versions[props.row.id]">
          <p v-if="versions[props.row.id].android">
            <a :href="versions[props.row.id].android.url">
              <b-icon icon="android" type="is-success"></b-icon>
              {{ versions[props.row.id].android.version }}
            </a>
          </p>
          <p v-if="versions[props.row.id].ios">
            <a :href="versions[props.row.id].ios.url">
              <b-icon icon="apple" type="is-dark"></b-icon>
              {{ versions[props.row.id].ios.version }}
            </a>
          </p>
        </template>
        <p v-else></p>
      </span>
    </b-table-column>

    <b-table-column label=" " v-slot="props">
      <a>
        <b-button>
          <b-icon
            @click.native="archive(props.row.id)"
            v-if="!props.row.is_archived"
            icon="archive-arrow-down"
          ></b-icon>
          <b-icon
            @click.native="unarchive(props.row.id)"
            v-else
            icon="archive-arrow-up"
          ></b-icon>
        </b-button>
      </a>
    </b-table-column>

    <b-table-column label="  " v-slot="props">
      <router-link :to="{ name: 'edit-company', params: { id: props.row.id } }">
        <b-button>
          <b-icon icon="pencil"></b-icon>
        </b-button>
      </router-link>
    </b-table-column>
  </b-table>

  <div v-else>
    <section>
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered">
            <p class="has-text-grey">Компаний еще нет</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
    companies: Array
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState({
      versions: state => state.companies.versions,
    }),
  },
  methods: {
    archive: function (id) {
      if (confirm('Вы уверены, что хотите архивировать компанию?')) {
        this.$store.dispatch('startLoading')

        this.$store.dispatch('companies/archive', { id: id })
          .then(res => {
            this.$router.push('/')
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Компания заархивирована!', 'is-primary'])
          })
          .catch(error => {
            this.$handleError(error)
            this.$store.dispatch('stopLoading')
          })
      }
    },
    unarchive: function (id) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/unarchive', { id: id })
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Компания разархивирована', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },

  }
}
</script>

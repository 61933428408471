<template>
  <div class="modal-card apple-account-block">
    <header class="modal-card-head">
      <p class="modal-card-title">Изменение учетной записи Apple App store</p>
    </header>
    <section class="modal-card-body">
      <b-field type="is-success">
        <b-input v-model="accountName" placeholder="Название аккаунта" disabled></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamId" placeholder="Sign team ID"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamName" placeholder="Sign team name"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamUsername" placeholder="Sign team username"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneTeamId" placeholder="Fastlane team ID"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneTeamName" placeholder="Fastlane team name"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneResignSigningIdentity" placeholder="Fastlane Signing Identity"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="provisionProfile" placeholder="Provision Profile"></b-input>
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Отменить</button>
      <button class="button is-primary" @click="update()">Сохранить</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.apple-account-block {
  width: auto;
}
</style>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
    account: Object
  },
  data: function () {
    return {
      accountName: this.account.name,
      signTeamId: this.account.sign_team_id,
      signTeamName: this.account.sign_team_name,
      signTeamUsername: this.account.sign_team_username,
      fastlaneTeamId: this.account.fastlane_team_id,
      fastlaneTeamName: this.account.fastlane_team_name,
      fastlaneResignSigningIdentity: this.account.fastlane_resign_signing_identity,
      provisionProfile: this.account.provision_profile
    }
  },
  methods: {
    update: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/updateIosAccount',
        {
          name: this.accountName,
          signTeamID: this.signTeamId,
          signTeamName: this.signTeamName,
          signTeamUsername: this.signTeamUsername,
          flTeamID: this.fastlaneTeamId,
          flTeamName: this.fastlaneTeamName,
          flIdentity: this.fastlaneResignSigningIdentity,
          provision: this.provisionProfile
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          this.$parent.close()
          this.$emit('update')
          toastHandler.apply(this, ['Учетная запись Apple App Store изменена!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }

}
</script>

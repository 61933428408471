<template>
  <div>
    <p>
      <!-- <b v-if="isTestRelease">тестовый</b> -->
      <b>{{ displayReleaseVariant }}</b>
      версию
      <b>{{ version.name }}</b>
      на платформы:
      <b>{{ formatPlatforms(platforms) }}</b>
      . Всего компаний:
      <b>{{ companies.length }}</b>
    </p>
    <p v-if="message">Дополнительное описание: {{ message }}</p>
    <p class="is-size-5">Компании, которые получат обновление:</p>

    <b-table
      :data="companies"
      @click="$emit('click', $event)"
      hoverable
      v-if="companies.length > 0"
    >
      <b-table-column label="ID" v-slot="props">
        {{ props.row.company_id }}
      </b-table-column>

      <b-table-column v-slot="props">
        <b-icon icon="archive" v-if="props.row.is_archived"></b-icon>
      </b-table-column>

      <b-table-column label="Название компании" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Название приложения" v-slot="props">
        <p>{{ props.row.app_name }}</p>
        <p>{{ props.row.app_name_android }}</p>
        <p>{{ props.row.app_name_ios }}</p>
      </b-table-column>

      <b-table-column label="Последняя версия приложения" v-slot="props">
        {{ props.row.last_version }}
      </b-table-column>
    </b-table>

    <div v-else>
      <section>
        <div class="container">
          <div class="columns">
            <div class="column has-text-centered">
              <p class="has-text-grey">Компаний еще нет</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    version: Object,
    platforms: Array,
    companies: Array,
    isTestRelease: Boolean,
    releaseVariant: String,
    message: String
  },
  methods: {
    formatPlatforms: function (platforms) {
      var ret = []
      if (platforms.indexOf('android') >= 0) {
        ret.push('Android')
      }
      if (platforms.indexOf('ios') >= 0) {
        ret.push('iOS')
      }

      return ret.join(', ')
    }
  },
  computed: {
    displayReleaseVariant: function () {
      if (!this.releaseVariant) {
        return 'errrrr'
      } else if (this.releaseVariant === 'build') {
        return 'Собираем'
      } else if (this.releaseVariant === 'release') {
        return 'Релизим'
      }
      return 'errrrr'
    }
  },
  components: {
  }
}
</script>

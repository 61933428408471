<template>
  <div class="columns">
    <div class="column is-half">
      <p>
        Релиз версии
        <b>{{ release.version.name }}</b>
        на платформы:
        <b>{{ formatPlatforms(release.platforms) }}</b>
      </p>

      <p>Запущен: {{ release.created_at | as_date }}</p>

      <p v-if="release.message">{{ release.message }}</p>

      <div>
        <div v-if="release.mode == 0">
          <b-icon icon="cog"></b-icon>
          <span class="description">Только собрать приложения</span>
        </div>
        <div v-if="release.mode == 1">
          <b-icon icon="rocket"></b-icon>
          <span class="description">Собрать и выпустить</span>
        </div>
      </div>
    </div>

    <div class="column is-half additional-info">
      <div>
        <b-tag
          v-if="
            release.report.completed == false &&
            release.report.is_stopped != true
          "
          type="is-success"
        >
          В работе
        </b-tag>
        <b-tag
          v-if="
            release.report.completed &&
            release.report.errors == 0 &&
            release.report.is_stopped != true
          "
          type="is-success"
          class="tag"
        >
          Завершено
        </b-tag>
        <b-tag
          v-if="release.report.completed && release.report.errors > 0"
          type="is-danger"
          class="tag"
        >
          Завершено с ошибками
        </b-tag>
        <b-tag
          v-if="release.report.is_stopped == true"
          type="is-warning"
          class="tag"
        >
          Релиз остановлен
        </b-tag>
      </div>
      <br />
      <b-progress
        v-if="
          release.report.completed == false &&
          release.report.is_stopped == false
        "
        type="is-success"
      ></b-progress>

      <b-message v-if="release.report.is_stopped == true" type="is-warning">
        <strong>Релиз принудительно остановлен</strong>
      </b-message>

      <b-message
        v-if="
          quantityIosMarketUrlNull(release) > 0 &&
          release.platforms.includes('ios')
        "
        type="is-danger"
      >
        <strong>
          AppStore URL не заполнен в
          {{
            `${quantityIosMarketUrlNull(release)} ` +
            getNoun(
              quantityIosMarketUrlNull(release),
              "компании",
              "компаниях",
              "компаний"
            )
          }}
        </strong>
      </b-message>

      <p
        v-if="
          release.report.completed == false &&
          release.report.is_stopped == false
        "
        class="report-message"
      >
        Эта страница обновляется автоматически
      </p>

      <div class="buttons">
        <b-button
          v-if="
            release.report.is_stopped == false &&
            release.report.completed == false
          "
          icon-left="shield-alert"
          type="is-primary"
          class="button-release"
          @click="stopRelease()"
        >
          Остановить весь релиз
        </b-button>

        <b-button
          v-if="release.report.completed == true"
          icon-left="refresh"
          type="is-primary"
          class="button-release"
          @click="retryRelease(false)"
        >
          Повторить
        </b-button>

        <b-button
          v-if="hasFailed"
          icon-left="alert"
          type="is-primary"
          @click="retryRelease(true)"
        >
          Пересобрать упавшие
        </b-button>

        <p
          v-if="release && release.is_not_uploaded_to_admin_builds"
          class="upload-admin"
        >
          <b-button type="is-primary" outlined @click="uploadToAdminPanel()">
            Залить в админ-панель
          </b-button>
        </p>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.description {
  font-weight: bold;
  margin-left: 5px;
}
.tag {
  margin-right: 10px;
}
.button-release {
  margin-right: 15px;
}
.upload-admin {
  margin-top: 8px;
}
.repost-message {
  margin-bottom: 20px;
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  button {
    margin-top: 16px;
  }
}
</style>

<script>
import { toastHandler } from "@/plugins/toastHandler";
var moment = require("moment");

export default {
  props: {
    release: Object,
  },
  methods: {
    stopRelease: function () {
      this.statusStop = true;
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("releases/stopRelease", {
          id: this.$route.params.id,
          stop: this.statusStop,
        })
        .then((_) => {
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, ["Весь релиз остановлен!", "is-primary"]);
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },

    retryRelease: function (onlyFailed) {
      this.$store
        .dispatch("releases/prepareRetryData", {
          id: this.release.id,
          onlyFailed,
        })
        .then((res) => {
          this.$router.push({
            name: "new-release",
            params: {
              initialStep: 3,
              initialCompanies: res.companies.map((rc) => rc.company),
              initialVersion: res.version,
              initialVersionLocalization: res.localizations,
              initialPlatforms: res.platforms.split(","),
              initialReleaseVariant: res.mode ? "release" : "build",
              initialMessage: res.message,
            },
          });
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    uploadToAdminPanel: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("releases/uploadToAdminPanel", { id: this.release.id })
        .then((res) => {
          this.$store.dispatch("stopLoading");

          this.$buefy.notification.open({
            message: "Сборки успешно загружены в админ-панель",
            position: "is-bottom-right",
            autoClose: false,
            type: "is-success",
          });
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
    hasCompanyInList: function (company, list) {
      for (let i = 0; i < list.length; i++) {
        if (company.id === list[i].id) {
          return true;
        }
      }
      return false;
    },
    formatPlatforms: function (platforms) {
      var ret = [];
      if (platforms.indexOf("android") >= 0) {
        ret.push("Android");
      }
      if (platforms.indexOf("ios") >= 0) {
        ret.push("iOS");
      }

      return ret.join(", ");
    },

    quantityIosMarketUrlNull: function (reports) {
      var ret = [];
      for (var c of reports.companies) {
        if (c.company.ios_market_url === null) {
          ret.push(c.company.company_id);
        }
      }
      var urls = ret.length;

      return urls;
    },
    getNoun: function (number, one, two, five) {
      var n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
  },
  computed: {
    hasFailed: function () {
      return this.release.companies.some((c) => c.status === 5);
    },
  },
  filters: {
    as_date(value) {
      if (!value) {
        return "";
      }
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

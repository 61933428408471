import api from '@/api/api'

class AuthApi {
  constructor() {
    this.api = api
  }

  login (login, password) {
    return this.api.post('auth/login/', { login: login, password: password })
  }
}

export default new AuthApi()

<template>
  <section>
    <div class="container">
      <b-message type="is-success" class="message">
        <p class="is-size-3">
          Release Service
        </p>

        <div class="block-about">
          <p>
            Release Service - это внутренний проект для автоматизации сборок мобильных приложений проекта Эквио.
          </p>
        </div>

        <div class="block-about">
          <p class="is-size-4">Сервис написан с использованием Python, Vue.js</p>

          <p>
            <b-icon icon="file-document-outline" size="is-small"></b-icon>
            <a href="https://equeo.eqdoc.xyz/services/release-service/release-service/">
              Техническая документация для разработчиков
            </a>
          </p>

          <p>
            <b-icon icon="git" size="is-small"></b-icon>
            <a href="https://gitlab.e-queo.xyz/equeo/services/release-service/release-service">
              Бекенд часть
            </a>
          </p>

          <p>
            <b-icon icon="git" size="is-small"></b-icon>
            <a href="https://gitlab.e-queo.xyz/equeo/services/release-service/release-service-frontend">
              Фронтенд часть
            </a>
          </p>
        </div>

        <div class="block-about">
          <p class="is-size-4">По всем вопросам:</p>

          <p>
            <b-icon icon="telegram" size="is-small"></b-icon>
            <a href="https://t.me/veloc1">
              @veloc1
            </a>
          </p>

          <p>
            <b-icon icon="discord" size="is-small"></b-icon>
              uselessdinosaur#3324
          </p>
        </div>
      </b-message>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.icon {
  margin-right: 5px;
}
.block-about {
  margin-top: 20px;
}
.message {
  margin-top: 10px;
}
</style>

<script>
export default {

}
</script>

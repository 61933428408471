var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('p',[_vm._v(" Релиз версии "),_c('b',[_vm._v(_vm._s(_vm.release.version.name))]),_vm._v(" на платформы: "),_c('b',[_vm._v(_vm._s(_vm.formatPlatforms(_vm.release.platforms)))])]),_c('p',[_vm._v("Запущен: "+_vm._s(_vm._f("as_date")(_vm.release.created_at)))]),(_vm.release.message)?_c('p',[_vm._v(_vm._s(_vm.release.message))]):_vm._e(),_c('div',[(_vm.release.mode == 0)?_c('div',[_c('b-icon',{attrs:{"icon":"cog"}}),_c('span',{staticClass:"description"},[_vm._v("Только собрать приложения")])],1):_vm._e(),(_vm.release.mode == 1)?_c('div',[_c('b-icon',{attrs:{"icon":"rocket"}}),_c('span',{staticClass:"description"},[_vm._v("Собрать и выпустить")])],1):_vm._e()])]),_c('div',{staticClass:"column is-half additional-info"},[_c('div',[(
          _vm.release.report.completed == false &&
          _vm.release.report.is_stopped != true
        )?_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v(" В работе ")]):_vm._e(),(
          _vm.release.report.completed &&
          _vm.release.report.errors == 0 &&
          _vm.release.report.is_stopped != true
        )?_c('b-tag',{staticClass:"tag",attrs:{"type":"is-success"}},[_vm._v(" Завершено ")]):_vm._e(),(_vm.release.report.completed && _vm.release.report.errors > 0)?_c('b-tag',{staticClass:"tag",attrs:{"type":"is-danger"}},[_vm._v(" Завершено с ошибками ")]):_vm._e(),(_vm.release.report.is_stopped == true)?_c('b-tag',{staticClass:"tag",attrs:{"type":"is-warning"}},[_vm._v(" Релиз остановлен ")]):_vm._e()],1),_c('br'),(
        _vm.release.report.completed == false &&
        _vm.release.report.is_stopped == false
      )?_c('b-progress',{attrs:{"type":"is-success"}}):_vm._e(),(_vm.release.report.is_stopped == true)?_c('b-message',{attrs:{"type":"is-warning"}},[_c('strong',[_vm._v("Релиз принудительно остановлен")])]):_vm._e(),(
        _vm.quantityIosMarketUrlNull(_vm.release) > 0 &&
        _vm.release.platforms.includes('ios')
      )?_c('b-message',{attrs:{"type":"is-danger"}},[_c('strong',[_vm._v(" AppStore URL не заполнен в "+_vm._s(`${_vm.quantityIosMarketUrlNull(_vm.release)} ` + _vm.getNoun( _vm.quantityIosMarketUrlNull(_vm.release), "компании", "компаниях", "компаний" ))+" ")])]):_vm._e(),(
        _vm.release.report.completed == false &&
        _vm.release.report.is_stopped == false
      )?_c('p',{staticClass:"report-message"},[_vm._v(" Эта страница обновляется автоматически ")]):_vm._e(),_c('div',{staticClass:"buttons"},[(
          _vm.release.report.is_stopped == false &&
          _vm.release.report.completed == false
        )?_c('b-button',{staticClass:"button-release",attrs:{"icon-left":"shield-alert","type":"is-primary"},on:{"click":function($event){return _vm.stopRelease()}}},[_vm._v(" Остановить весь релиз ")]):_vm._e(),(_vm.release.report.completed == true)?_c('b-button',{staticClass:"button-release",attrs:{"icon-left":"refresh","type":"is-primary"},on:{"click":function($event){return _vm.retryRelease(false)}}},[_vm._v(" Повторить ")]):_vm._e(),(_vm.hasFailed)?_c('b-button',{attrs:{"icon-left":"alert","type":"is-primary"},on:{"click":function($event){return _vm.retryRelease(true)}}},[_vm._v(" Пересобрать упавшие ")]):_vm._e(),(_vm.release && _vm.release.is_not_uploaded_to_admin_builds)?_c('p',{staticClass:"upload-admin"},[_c('b-button',{attrs:{"type":"is-primary","outlined":""},on:{"click":function($event){return _vm.uploadToAdminPanel()}}},[_vm._v(" Залить в админ-панель ")])],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <div class="container" v-if="currentVersion">
      <p class="is-size-3">Версия {{ currentVersion.name }}</p>
      <div class="columns version-block">
        <div class="column">
          <version-editor :localizations.sync="currentVersion.localizations" />

          <b-field>
            <b-button @click="editVersion()" type="is-primary">
              Обновить текст "Что нового?"
            </b-button>
          </b-field>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.version-block {
  margin-top: 20px;
}
</style>

<script>
import VersionEditor from '@/components/VersionEditor'
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  created: function () {
    if (this.versions === null) {
      this.getVersions()
    } else {
      this.refreshModel()
      if (!this.currentVersion) {
        this.getVersions()
      }
    }
  },
  data: function () {
    return {
      currentVersion: {}
    }
  },
  methods: {
    getVersions: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('versions/getAll')
        .then(res => {
          this.refreshModel()
          if (!this.currentVersion) {
            this.$router.push('/404')
          }
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    refreshModel: function () {
      this.currentVersion = this.versions.find(v => v.id === parseInt(this.$route.params.id))
      if (this.currentVersion) {
        this.id = this.$route.params.id
      }
    },
    editVersion: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('versions/edit',
        {
          id: this.id,
          localizations: this.currentVersion.localizations
        })
        .then(res => {
          this.$router.push({ name: 'versions' })
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Текст "Что нового?" обновлен!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    ...mapState({
      versions: state => state.versions.available
    })
  },
  components: {
    VersionEditor
  }
}
</script>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">История изменений</p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-info">
        Иконкой платформы
        <span>
          (
          <b-icon icon="android" type="is-success"></b-icon>
          <b-icon icon="apple" type="is-dark"></b-icon>
          )
        </span>
        обозначена последняя версия подтвержденного дизайна
      </b-message>

      <div v-for="e in figmaEvents" :key="e.id">
        <b-icon v-if="e.android" icon="android" type="is-success"></b-icon>
        <b-icon v-if="e.ios" icon="apple" type="is-dark"></b-icon>
        <span>{{ e.created_at | as_date }} {{ e.user.handle }}</span>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="is-danger" @click="$parent.close()">
        Закрыть
      </button>
    </footer>
  </div>
</template>

<script>

var moment = require('moment')

export default {
  props: {
    history: Object,
    company: Object
  },
  data: function () {
    return {
    }
  },
  methods: {
  },
  computed: {
    figmaEvents: function () {
      var figmaVersions = this.history.history.versions

      return figmaVersions.map(v => {
        if (v.id === this.company.android_approved_design_version) {
          v.android = true
        }
        if (v.id === this.company.ios_approved_design_version) {
          v.ios = true
        }
        // if (v.id === this.history.android_version.version) {
        //   v.android = true
        // }
        // if (v.id === this.history.ios_version.version) {
        //   v.ios = true
        // }
        return v
      })
    }
  },
  filters: {
    as_date (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

import api from '@/api/api'

class MarketAccountsApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('market/')
  }

  addAndroidAccount (name, creds, testPackage) {
    var fields = [
      {
        name: 'name',
        value: name
      },
      {
        name: 'test_package',
        value: testPackage
      }
    ]
    return this.api.postFile('market/google-play/', 'creds', creds, fields)
  }

  addHuaweiAccount (name, creds) {
    var fields = [
      {
        name: 'name',
        value: name
      }
    ]
    return this.api.postFile('market/huawei-mobile-service/', 'creds', creds, fields)
  }

  updateHuaweiAccount (id, name, creds) {
    var fields = [
      {
        name: 'name',
        value: name
      }
    ]
    return this.api.postFile(`market/huawei-mobile-service/${id}/`, 'creds', creds, fields)
  }

  deleteHuaweiAccount (id) {
    return this.api.delete(`market/huawei-mobile-service/${id}/`)
  }

  addIosAccount (name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision) {
    return this.api.post('market/app-store/', {
      name,
      sign_team_id: signTeamID,
      sign_team_name: signTeamName,
      sign_team_username: signTeamUsername,
      fastlane_team_id: flTeamID,
      fastlane_team_name: flTeamName,
      fastlane_resign_signing_identity: flIdentity,
      provision_profile: provision
    })
  }

  updateIosAccount (name, signTeamID, signTeamName, signTeamUsername, flTeamID, flTeamName, flIdentity, provision) {
    return this.api.post('market/app-store/', {
      name,
      sign_team_id: signTeamID,
      sign_team_name: signTeamName,
      sign_team_username: signTeamUsername,
      fastlane_team_id: flTeamID,
      fastlane_team_name: flTeamName,
      fastlane_resign_signing_identity: flIdentity,
      provision_profile: provision
    })
  }
}

export default new MarketAccountsApi()

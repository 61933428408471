<template>
  <section>
    <b-button @click="showGooglePlayModal()" icon-left="plus" type="is-primary">Добавить учетную запись Google Play</b-button>

    <template v-if="accounts != null && accounts.length > 0">
      <b-table :data="accounts">
          <b-table-column label="Название" v-slot="props">{{ props.row.name }}</b-table-column>
      </b-table>
    </template>

    <p v-else class="is-size-3 has-text-centered google-no-list">Учетные записи не добавлены</p>

    <b-modal
      :active.sync="isGooglePlayModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <google-play-add-account-modal @create="getAccounts" />
    </b-modal>
  </section>
</template>

<style lang="scss" scoped>
.google-no-list {
  margin-top: 20px;
}
</style>

<script>
import { mapState } from 'vuex'
import GooglePlayAddAccountModal from '@/components/market/GooglePlayAddAccountModal.vue'

export default {
  created: function () {
    this.getAccounts()
  },
  data: function () {
    return {
      isGooglePlayModalActive: false
    }
  },
  methods: {
    getAccounts: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/getAccounts')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    showGooglePlayModal () {
      this.isGooglePlayModalActive = true
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.marketAccounts.android
    })
  },
  components: {
    GooglePlayAddAccountModal
  }
}
</script>

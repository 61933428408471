import api from '@/api/api'
import AuthApi from '@/api/auth'

const state = {
  token: null
}

const getters = {
  isAuthorized: state => {
    return state.token != null
  }
}

const mutations = {
  storeToken (state, token) {
    state.token = token
  }
}
const actions = {
  login ({ commit, dispatch }, { login, password }) {
    return AuthApi.login(login, password).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeToken', res.data.token)

      api.setDefaultInstanceParams(res.data.token)

      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user', JSON.stringify({ login: login }))

      dispatch('saveUser', { user: { login: login } }, { root: true })

      return res
    })
  },
  logout ({ commit }) {
    commit('storeToken', null)
    api.setDefaultInstanceParams(null)
    localStorage.setItem('token', null)
    localStorage.setItem('user', null)
  },
  loadTokensFromStorage ({ commit, dispatch }) {
    var t = localStorage.getItem('token')
    if (t !== null && t !== undefined && t !== 'null') {
      commit('storeToken', t)
      api.setDefaultInstanceParams(t)
    } else {
      commit('storeToken', null)
      api.setDefaultInstanceParams(null)
    }

    t = localStorage.getItem('user')
    if (t !== null && t !== undefined && t !== 'null') {
      var u = JSON.parse(t)
      dispatch('saveUser', { user: u }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
  <div class="server-block">
    <span>
      <b-button @click="toggle" type="is-primary">
        <b-icon
          v-if="ServersWithLoadedCompanies[server.id]"
          icon="menu-up"
          size="is-small"
        ></b-icon>
        <b-icon v-else icon="menu-down" size="is-small"></b-icon>
      </b-button>

      <b-button @click="isOpenSettings = !isOpenSettings" type="is-primary">
        <b-icon icon="cog" size="is-small"></b-icon>
      </b-button>

      <b-icon icon="server" size="is-small" style="height: 100%"></b-icon>
      <b-icon
        v-if="server.behind_firewall"
        icon="wall"
        size="is-small"
      ></b-icon>
      <b>Сервер:</b>
      {{ server.name }}

      <b>URL:</b>
      {{ server.url_web }}
    </span>

    <div v-if="isOpenSettings" class="quick-settings">
      <b-button
        v-if="server.behind_firewall"
        tag="router-link"
        type="is-primary"
        :to="{ name: 'add-company', params: { id: server.id } }"
        icon-left="plus"
      >
        Добавить компанию
      </b-button>

      <router-link
        class="edit-server"
        :to="{ name: 'edit-server', params: { id: server.id } }"
      >
        <b-button icon-left="pencil" type="is-dark" outlined>
          Редактировать сервер
        </b-button>
      </router-link>

      <b-button
        v-if="!hasUnitedBuild()"
        type="is-primary"
        @click="addCompany()"
        icon-left="plus"
      >
        Добавить единую сборку
      </b-button>

      <b-button
        type="is-primary"
        @click="updateAssetlinks()"
        icon-left="refresh"
      >
        Обновить assetlinks
      </b-button>

      <b-button
        type="is-primary"
        @click="updateAppleAppSiteAssociations()"
        icon-left="refresh"
      >
        Обновить AppleAppSiteAssociations
      </b-button>
    </div>
    <view-companies
      v-if="ServersWithLoadedCompanies[server.id]"
      :companies="filteredCompanies"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ViewCompanies from "@/components/companies/ViewCompanies.vue";
import { toastHandler } from "@/plugins/toastHandler";

export default {
  name: "ViewServer",

  props: {
    server: {
      type: Object,
      default: () => {},
    },
    ServersWithLoadedCompanies: {
      type: Object,
      default: () => {},
    },
    filteredCompanies: {
      type: Array,
      default: () => [],
    },
  },

  data: function () {
    return {
      UNITED_BUILD_ID: 100000,
      isOpenSettings: false,
    };
  },
  computed: {},

  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    async toggle() {
      this.$emit("toggle", this.server.id);
    },

    hasUnitedBuild: function () {
      return this.filteredCompanies.some(
        (obj) => obj.company_id === this.UNITED_BUILD_ID
      );
    },
    addCompany: function () {
      this.startLoading();

      this.$store
        .dispatch("companies/add", {
          name: `Единая сборка ${this.server.name}`,
          companyId: 100000,
          serverId: this.server.id,
        })
        .then((res) => {
          this.$router.push("/");
          this.stopLoading();
          toastHandler.apply(this, [
            `Единая сборка добавлена на сервер ${this.server.name}!`,
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },
    updateAssetlinks: function () {
      this.startLoading();

      this.$store
        .dispatch("servers/updateAssetlinks", {
          serverId: this.server.id,
        })
        .then((res) => {
          this.stopLoading();
          toastHandler.apply(this, [
            `Assetlinks для сервера ${this.server.name} обновлены!`,
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },
    updateAppleAppSiteAssociations: function () {
      this.startLoading();

      this.$store
        .dispatch("servers/updateAppleAppSiteAssociations", {
          serverId: this.server.id,
        })
        .then((res) => {
          this.stopLoading();
          toastHandler.apply(this, [
            `updateAppleAppSiteAssociations для сервера ${this.server.name} обновлены!`,
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },
  },
  components: {
    ViewCompanies,
  },
};
</script>

<style lang="scss" scoped>
.server-block {
  margin-bottom: 10px;
}

.server-block span button {
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

.quick-settings {
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    margin-left: 12px;
  }
}

.edit-server {
  margin-right: 10px;
}
</style>

<template>
  <section>
    <about-servers-list />
    <div class="container">
      <div class="columns">
        <div class="column is-four-fifths">
          <template
            class="block"
            v-if="filteredServers != null && filteredServers.length > 0"
          >
            <div v-for="server in filteredServers" :key="server.id">
              <viewServer
                :server="server"
                :ServersWithLoadedCompanies="ServersWithLoadedCompanies"
                :filteredCompanies="filterCompanies(server.id)"
                @toggle="toggleSpoiler"
              />
            </div>
          </template>

          <empty v-else>
            <b-button
              tag="router-link"
              :to="{ name: 'add-server' }"
              icon-left="plus"
              type="is-primary"
            >
              Добавить сервер
            </b-button>
          </empty>
        </div>
        <div class="column">
          <div class="column" title="scroll">
            <div class="block">
              <b-button @click="refreshCompanies()" icon-left="refresh" type="is-primary">
                Обновить список компаний
              </b-button>
              <b-modal v-model="showModal" title="Refresh Response" hide-footer>
                <pre>{{ validatedResponse }}</pre>
              </b-modal>
            </div>

            <div class="block">
              <p>
                <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                Список компаний обновляется каждые 24 часа. Вы можете обновить
                список компаний, нажав кнопку ниже. Эта операция синхронизриует
                список компаний на сервере eQueo и релиз-сервисе
              </p>
            </div>
            <div class="block">
              <b-field type="is-primary">
                <b-input
                  placeholder="Поиск..."
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchString"
                ></b-input>
              </b-field>

              <b-field>
                <b-checkbox v-model="searchShowArchive">
                  Показать архивные компании
                </b-checkbox>
              </b-field>

              <div class="block">
                <b-button
                  @click="searchTags = !searchTags"
                  icon-left="magnify-scan"
                  type="is-primary"
                >
                  Поиск по тегам
                </b-button>
              </div>
              <b-message v-if="searchTags == true" type="is-success">
                <b-field grouped group-multiline>
                  <div class="control" v-for="tag in tags" :key="tag.id">
                    <b-taglist attached>
                      <b-tag :type="'is-' + tag.color">{{ tag.title }}</b-tag>

                      <b-tag v-if="isFilteredByTag(tag)" type="is-red">
                        <a @click="removeTagFromFilter(tag)">
                          <b-icon icon="close" size="is-small"></b-icon>
                        </a>
                      </b-tag>
                      <b-tag v-else>
                        <a @click="addTagToFilter(tag)">
                          <b-icon icon="magnify" size="is-small"></b-icon>
                        </a>
                      </b-tag>
                    </b-taglist>
                  </div>
                </b-field>
              </b-message>
            </div>
            <hr />
            <div class="block">
              <b-button
                tag="router-link"
                :to="{ name: 'add-server' }"
                icon-left="plus"
                type="is-primary"
                class="button-router"
              >
                Добавить сервер
              </b-button>
            </div>
            <hr />
            <div class="block">
              <b-button
                @click="exportCsv()"
                icon-left="download"
                type="is-primary"
              >
                Экспортировать
              </b-button>
            </div>
            <div class="block">
              <p>
                <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                Будет выгружен csv файл со списком компаний и актуальными
                версиями приложений.
              </p>
            </div>
          </div>

          <button
            v-if="buttonShow"
            @click="top"
            class="button-scroll"
            type="is-primary"
          >
            <b-icon
              icon="rocket-outline"
              size="is-medium"
              class="icon-menu"
            ></b-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { toastHandler } from "@/plugins/toastHandler";
import Empty from "@/components/Empty.vue";
import AboutServersList from "@/components/help/about/ServersList.vue";
import ViewServer from "@/components/companies/ViewServer.vue";
import Vue from 'vue';
import Notifications from 'vue-notification';
import CompaniesApi from "@/api/companies";
Vue.use(Notifications);

export default {
  components: {
    Empty,
    AboutServersList,
    ViewServer,
    Notifications
  },

  data: function () {
    return {
      companyFilters: {
        query: "",
        servers_ids: [],
        tags_ids: [],
        page: 1,
        per_page: 5000,
      },
      ServersWithLoadedCompanies: {},
      searchString: "",
      searchTimer: null,
      isSearchDone: false,
      searchShowArchive: false,
      searchByTags: [],
      searchTags: false,
      buttonShow: false,
      showModal: false,
      validatedResponse: null,
    };
  },

  computed: {
    ...mapState({
      servers: (state) => state.servers.available,
      tags: (state) => state.tags.available,
    }),
    ...mapGetters({
      getCompaniesInServer: "companies/getByServer",
      getAllCompanies: "companies/getAllCompanies",
    }),

    filteredServers() {
      if (this.searchString.length === 0 && this.searchByTags.length === 0) {
        return this.servers;
      } else if (this.searchString.length && !this.isSearchDone) {
        return this.servers;
      } else {
        return this.servers.filter(
          (s) => this.filterCompanies(s.id).length > 0
        );
      }
    },

    isFilterEmpty() {
      return (
        !this.companyFilters.query.length &&
        !this.companyFilters.servers_ids.length &&
        !this.companyFilters.tags_ids.length
      );
    },
  },

  watch: {
    ServersWithLoadedCompanies: {
      deep: true,
      handler() {
        this.companyFilters.servers_ids = Object.keys(
          this.ServersWithLoadedCompanies
        );
      },
    },

    searchString(val) {
      clearTimeout(this.searchTimer);
      this.isSearchDone = false;
      this.searchTimer = setTimeout(() => {
        if (this.searchString.length || this.companyFilters.tags_ids.length) {
          this.doneTyping();
        } else {
          this.clearServersData();
        }
      }, 1000);
    },
  },

  created() {
    this.$store.dispatch("companies/clearCompanies");
    if (this.servers == null || this.servers.length === 0) {
      this.getServers();
    }
  },

  mounted() {
    this.$store.dispatch("companies/clearCompanies");

    this.checkScrollPosition();
    window.addEventListener("scroll", this.checkScrollPosition);
  },

  methods: {
    ...mapActions(["startLoading", "stopLoading", "loadAll"]),

    clearServersData() {
      this.ServersWithLoadedCompanies = {};
      this.companyFilters.servers_ids = [];
    },

    isCompaniesWasLoaded(serverId) {
      return Object.keys(this.ServersWithLoadedCompanies).find(
        (a) => parseInt(a) === serverId
      );
    },

    async toggleSpoiler(serverId) {
      // eslint-disable-next-line camelcase
      const { page, per_page } = this.companyFilters;
      if (this.isCompaniesWasLoaded(serverId)) {
        this.ServersWithLoadedCompanies[serverId] =
          !this.ServersWithLoadedCompanies[serverId];
      } else {
        this.startLoading();
        await this.$store.dispatch("companies/appendCompanies", {
          servers_ids: [serverId],
          page,
          per_page,
        });
        this.$store.dispatch("companies/loadVersions");
        this.$set(this.ServersWithLoadedCompanies, serverId, true);
        this.stopLoading();
      }
    },

    async doneTyping() {
      this.clearServersData();
      this.companyFilters.query = this.searchString;
      await this.loadCompaniesByFilters();
      this.openedServerSpoilerAfterSearch();
    },

    openedServerSpoilerAfterSearch() {
      this.getAllCompanies.forEach((a) => {
        if (!this.isCompaniesWasLoaded(a.server_id)) {
          this.$set(this.ServersWithLoadedCompanies, a.server_id, true);
        }
      });
      this.isSearchDone = true;
    },

    checkScrollPosition() {
      this.buttonShow = window.pageYOffset > 500;
    },

    top: function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    getServers() {
      this.loadAll()
        .then((res) => {
          this.stopLoading();
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },

    async loadCompaniesByFilters() {
      if (this.isFilterEmpty) {
        this.clearServersData();
        return;
      }

      this.startLoading();
      await this.$store
        .dispatch("companies/getCompaniesByFilters", this.companyFilters)
        .then((res) => {
          this.stopLoading();
          toastHandler.apply(this, ["Список компаний обновлен!", "is-primary"]);
          this.openedServerSpoilerAfterSearch();
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },

    refreshCompanies() {
      this.$store.dispatch('startLoading');
      CompaniesApi.refresh()
        .then((response) => {
          if (response.request.status == 200) {
            this.validatedResponse = JSON.stringify(response.data.info, null, 2);
            this.showModal = true;
            this.$store.dispatch('stopLoading');
          } else {
            var error = 'Something gone wrong';
            this.$handleError(error);
            this.$store.dispatch('stopLoading');
          }

        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch('stopLoading');
        });
    },


    filterCompanies(serverId) {
      if (!this.searchShowArchive) {
        return this.getCompaniesInServer(serverId).filter(
          (a) => !a.is_archived
        );
      }
      return this.getCompaniesInServer(serverId);
    },

    isFilteredByTag(tag) {
      return this.searchByTags.includes(tag);
    },

    async addTagToFilter(tag) {
      this.searchByTags.push(tag);
      this.companyFilters.tags_ids.push(tag.id);
      this.clearServersData();
      await this.loadCompaniesByFilters();
    },

    async removeTagFromFilter(tag) {
      this.searchByTags = this.searchByTags.filter((t) => t.id !== tag.id);
      this.companyFilters.tags_ids = this.companyFilters.tags_ids.filter(
        (a) => a !== tag.id
      );
      this.clearServersData();
      await this.loadCompaniesByFilters();
    },

    exportCsv() {
      this.startLoading();
      this.$store
        .dispatch("companies/export")
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", "companies-and-versions.csv");

          document.body.appendChild(link);
          link.click();

          this.stopLoading();
        })
        .catch((error) => {
          this.$handleError(error);

          this.stopLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.block p {
  text-align: justify;
}

.block button {
  width: 100%;
}

.button-router {
  width: 100%;
}

.button-scroll {
  position: fixed;
  color: #fff; /* цвет текста */
  background-color: #286090; /* цвет заднего фона */
  right: 50px; /* расстояние от правого края */
  bottom: 20px; /* расстояние от нижнего края */
  border: rgb(255, 255, 255) solid;
  width: 70px;
  height: 70px;
  border-top-left-radius: 45px; /* скругление верхнего левого угла */
  border-top-right-radius: 45px; /* скругление верхнего правого угла */
  border-bottom-left-radius: 45px; /* скругление верхнего правого угла */
  border-bottom-right-radius: 45px; /* скругление верхнего правого угла */
  cursor: pointer; /* форма курсора */
  text-align: center; /*выравнивание содержимого элемента по центру */
}

.button-scroll:hover {
  background-color: #808080; /* цвет заднего фона при наведении */
}
</style>

<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column is-one-fifth">
          <b-menu>
            <b-menu-list label="Разделы">
              <b-menu-item
                icon="firebase"
                label="Firebase"
                tag="router-link"
                :to="{'name': 'admin-home'}"
                :active="$route.name == 'admin-home'"
              />
              <b-menu-item
                icon="shopping"
                label="Google Play"
                tag="router-link"
                :to="{'name': 'admin-google-play-accounts'}"
                :active="$route.name == 'admin-google-play-accounts'"
              />
              <b-menu-item
                icon="humble-bundle"
                label="AgConnect"
                tag="router-link"
                :to="{'name': 'admin-hms-config'}"
                :active="$route.name == 'admin-hms-config'"
              />
              <b-menu-item
                icon="apple"
                label="App Store"
                tag="router-link"
                :to="{'name': 'admin-app-store-accounts'}"
                :active="$route.name == 'admin-app-store-accounts'"
              />
              <b-menu-item
                icon="account"
                label="Пользователи"
                tag="router-link"
                :to="{'name': 'admin-users'}"
                :active="$route.name == 'admin-users'"
              />
              <b-menu-item
                icon="tag"
                label="Теги"
                tag="router-link"
                :to="{'name': 'admin-tags'}"
                :active="$route.name == 'admin-tags'"
              />
            </b-menu-list>
          </b-menu>
        </div>

        <div class="column">
          <router-view />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  created: function () {
  },
  data: function () {
    return {

    }
  },
  methods: {

  },
  computed: {
    ...mapState({
      servers: state => state.servers.available
    }),
    ...mapGetters({
      getCompaniesInServer: 'companies/getByServer'
    })
  },
  components: {
  }
}
</script>

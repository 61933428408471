<template>
  <section>
    <div class="container">
      <template v-if="releases != null && releases.length > 0">
        <b-table
          :data="releases"
          @click="onReleaseClick"
          hoverable
          paginated
          backend-pagination
          :total="total"
          :per-page="10"
          :current-page="page"
          @page-change="onPageChange"
        >
            <b-table-column label="Версия" v-slot="props">
              {{ props.row.release.version.name }}
              <b-tag v-if="props.row.status < 4" type="is-light">
                В работе
              </b-tag>
              <b-tag v-if="props.row.status == 4" type="is-success">
                Завершено
              </b-tag>
              <b-tag v-if="props.row.status == 5" type="is-danger">
                Ошибка
              </b-tag>
              <b-tag v-if="props.row.status == 6" type="is-warning">
                Остановлен
              </b-tag>
            </b-table-column>

            <b-table-column label="Дата" v-slot="props">
              {{ props.row.release.created_at | as_date }}
            </b-table-column>

            <b-table-column label="Тип" v-slot="props">
              <span v-if="props.row.release.mode == 1">Релиз</span>
              <span v-else>Сборка</span>
            </b-table-column>

            <b-table-column label="Платформа" centered v-slot="props">
              <span>
                <b-icon
                  icon="apple"
                  v-if="props.row.platform.includes('ios')"
                ></b-icon>
                <b-icon
                  icon="android"
                  type="is-success"
                  v-if="props.row.platform.includes('android')"
                ></b-icon>
              </span>
            </b-table-column>

            <b-table-column label="Ссылки" v-slot="props">
              <template v-if="props.row.platform === 'android'">
                <p v-if="props.row.build_artifact_url">
                  <a :href="props.row.build_artifact_url" target="_blank">
                    Ссылка на собранное приложение (для внутреннего
                    использования)
                  </a>
                </p>

                <p v-if="props.row.result_url">
                  <a :href="props.row.result_url" target="_blank">
                    Публичная ссылка
                  </a>
                </p>
              </template>

              <template v-if="props.row.platform === 'ios'">
                <p v-if="props.row.build_artifact_url">
                  <a :href="props.row.build_artifact_url" target="_blank">
                    Ссылка на собранное приложение (для внутреннего
                    использования)
                  </a>
                </p>

                <p v-if="props.row.result_url">
                  <a :href="props.row.result_url" target="_blank">
                    Публичная ссылка
                  </a>
                </p>
              </template>
            </b-table-column>
        </b-table>
      </template>

      <empty v-else>
        <b-button
          tag="router-link"
          :to="{ name: 'new-release' }"
          icon-left="rocket"
          type="is-primary"
        >
          Запустить релиз
        </b-button>
      </empty>
    </div>
  </section>
</template>

<style lang="scss" scoped>
tbody tr {
  cursor: pointer !important;
}
</style>

<script>
import { mapState } from 'vuex'
import Empty from '@/components/Empty.vue'

var moment = require('moment')

export default {
  components: {
    Empty
  },

  filters: {
    as_date (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm')
    }
  },

  data: function () {
    var queryPage = 1
    if (this.$route.query.page) {
      queryPage = Number.parseInt(this.$route.query.page)
    }
    return {
      companyId: parseInt(this.$route.params.id),
      timerId: -1,
      total: 0,
      page: queryPage
    }
  },

  computed: {
    ...mapState({
      releases: state => state.companies.currentCompanyReleases
    })
  },

  created: async function () {
    await this.getReleases(true)

    console.log('this.releases', this.releases)
    this.timerId = setInterval(() => this.getReleases(false), 1000 /** ms */ * 20)
  },

  beforeRouteUpdate (to, from, next) {
    if (to.name === 'company-releases') {
      var queryPage = 1
      if (to.query.page) {
        queryPage = Number.parseInt(to.query.page)
      }
      this.page = queryPage

      this.getReleases()
    }
    next()
  },

  beforeDestroy: function () {
    clearInterval(this.timerId)
  },

  methods: {
    getReleases: function (showLoading) {
      if (showLoading) {
        this.$store.dispatch('startLoading')
      }

      this.$store.dispatch('companies/getCompanyReleases', { id: this.companyId, page: this.page })
        .then(res => {
          if (showLoading) {
            this.$store.dispatch('stopLoading')
          }

          this.total = res.meta.total
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    onReleaseClick: function (companyRelease) {
      this.$router.push({ name: 'release-details', params: { id: companyRelease.release.id } })
    },
    onPageChange (page) {
      this.$router.push({ name: 'company-releases', query: { id: this.companyId, page: page } })
    }
  }
}
</script>

import TagsApi from '@/api/tags'

const state = {
  available: []
}

const getters = {
}

const mutations = {
  storeTags (state, tags) {
    state.available = tags
  },
  addTag (state, tag) {
    state.available.push(tag)
  },
  editTag (state, tag) {
    state.available = state.available.map(t => {
      if (t.id === tag.id) {
        return tag
      }
      return t
    })
  }
}

const actions = {
  getAll ({ commit }) {
    return TagsApi.getAll().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeTags', res.data.tags)
      return Promise.resolve()
    })
  },
  addTag ({ commit }, { title }) {
    return TagsApi.add(title).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('addTag', res.data.tag)
      return Promise.resolve()
    })
  },
  deleteTag ({ dispatch }, { id }) {
    return TagsApi.delete(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      return dispatch('getAll')
    })
  },
  mark ({ state, dispatch }, { tagId, companyId }) {
    return TagsApi.mark(companyId, tagId).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      var tag = state.available.find(t => t.id === tagId)
      return dispatch('companies/markCompanyWithTag', { companyId, tag }, { root: true })
    })
  },
  unmark ({ dispatch }, { tagId, companyId }) {
    return TagsApi.unmark(companyId, tagId).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      return dispatch('companies/unmarkCompanyWithTag', { companyId, tagId }, { root: true })
    })
  },
  subscribe ({ commit }, { id }) {
    return TagsApi.subscribe(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      var tag = state.available.find(t => t.id === id)
      tag.is_subscribed = true
      return commit('editTag', { tag })
    })
  },
  unsubscribe ({ commit }, { id }) {
    return TagsApi.unsubscribe(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      var tag = state.available.find(t => t.id === id)
      tag.is_subscribed = false
      return commit('editTag', { tag })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
  <section>
    <div>
      <b-field type="is-info">
        <b-input v-model="name" expanded placeholder="com.equeo.test"></b-input>
        <template slot="label">
          Название сервера
          <b-tooltip
            label="Это название будет отображаться на странице списка серверов"
            multilined
            position="is-bottom"
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </template>
      </b-field>

      <b-field type="is-primary">
        <b-input
          v-model="urlWeb"
          expanded
          placeholder="https://test-eq.ru/"
        ></b-input>
        <template slot="label">
          URL Web версии
          <b-tooltip
            label="Это поле будет использоваться в приложении для работы deeplink. Пример: https://test-eq.ru/"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </template>
      </b-field>

      <b-field type="is-primary">
        <b-input
          v-model="urlApi"
          expanded
          placeholder="https://api.test-eq.ru"
        ></b-input>
        <template slot="label">
          URL мобильной API
          <b-tooltip
            label="Это поле будет использоваться в приложении для доступа к серверу. Пример: https://api.test-eq.ru"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </template>
      </b-field>

      <b-field>
        <b-checkbox v-model="behindFirewall">
          Закрытый контур
          <b-tooltip
            label="Если компания находится в закрытом контуре - мы выключаем все интеграции с админ-панелью. Компании нужно создавать руками, собранные приложения нужно будет залить в админпанель"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </b-checkbox>
      </b-field>

      <template v-if="!behindFirewall">
        <b-field type="is-primary">
          <b-input
            v-model="urlAdmin"
            expanded
            placeholder="https://mapi.test-eq.ru/v1/"
          ></b-input>
          <template slot="label">
            URL API для административной панели
            <b-tooltip
              label="Это поле будет использоваться для заливки сборок в админ-панель. Пример: https://mapi.test-eq.ru/v1/"
              multilined
            >
              <b-icon icon="help-circle-outline" size="is-small"></b-icon>
            </b-tooltip>
          </template>
        </b-field>

        <b-field type="is-primary">
          <b-input v-model="adminLogin" expanded></b-input>
          <template slot="label">
            Логин администратора
            <b-tooltip
              label="Это поле будет использоваться для заливки сборок в админ-панель. Внимание: учетная запись должна иметь права суперадминистратора, т.к. только с такими правами можно загружать сборки"
              multilined
            >
              <b-icon icon="help-circle-outline" size="is-small"></b-icon>
            </b-tooltip>
          </template>
        </b-field>

        <b-field type="is-primary">
          <b-input
            v-model="adminPassword"
            type="password"
            password-reveal
            expanded
          ></b-input>
          <template slot="label">
            Пароль администратора
            <b-tooltip
              label="Это поле будет использоваться для заливки сборок в админ-панель. Внимание: учетная запись должна иметь права суперадминистратора, т.к. только с такими правами можно загружать сборки"
              multilined
            >
              <b-icon icon="help-circle-outline" size="is-small"></b-icon>
            </b-tooltip>
          </template>
        </b-field>
      </template>

      <b-field type="is-primary">
        <b-input
          v-model="s3ProxyToken"
          type="password"
          password-reveal
          expanded
        ></b-input>
        <template slot="label">
          Токен для s3 proxy
          <b-tooltip
            label="С этим токеном заливаются assetlinks.json"
            multilined
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </template>
      </b-field>

      <b-field class="server-footer-block">
        <b-button type="is-primary" @click="editServer()">Сохранить</b-button>
        <b-button type="is-danger" icon-right="delete" @click="deleteServer()">
          Удалить
        </b-button>
      </b-field>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.server-footer-block button {
  margin-right: 10px;
}
</style>

<script>
import { toastHandler } from "@/plugins/toastHandler";

export default {
  props: {
    value: Object,
  },
  model: {
    prop: "value",
    event: "input",
  },
  data: function () {
    return {
      name: this.value.name,
      urlWeb: this.value.url_web,
      urlApi: this.value.url_api,
      behindFirewall: this.value.behind_firewall,
      urlAdmin: this.value.url_admin,
      adminLogin: this.value.admin_login,
      adminPassword: this.value.admin_password,
      s3ProxyToken: this.value.s3_proxy_token || "",
    };
  },
  mounted: function () {},
  methods: {
    editServer: function () {
      this.$store
        .dispatch("servers/edit", {
          id: this.value.id,
          name: this.name,
          server: {
            name: this.name,
            url_web: this.urlWeb,
            url_api: this.urlApi,
            behind_firewall: this.behindFirewall,
            url_admin: this.urlAdmin,
            admin_login: this.adminLogin,
            admin_password: this.adminPassword,
            s3_proxy_token: this.s3ProxyToken,
          },
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, [
            `Сервер "${this.name}" изменен!`,
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    deleteServer: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("servers/deleteServer", { id: this.$route.params.id })
        .then((_) => {
          this.$router.push("/");
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, ["Сервер удален!", "is-danger"]);
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
  },
};
</script>

<template>
  <section>
    <div class="container">
      <p class="is-size-3">Как добавить сервер?</p>
      <div class="server-block">
        <p>
          В разделе “Компании”, нажмите кнопку “Добавить сервер”.
        </p>
        <hr />
        <p>
          <span>
            Для добавления укажите необходимые параметры:
          </span>
        </p>
        <p>
          - Название - любое удобное и понятное название.
        </p>
        <p>
          - URL Web Версии, URL мобильной API, URL API для административной панели - укажите схемы, http или https.
        </p>
        <p>
          - Чекбокс “Закрытый контур” отключает интеграцию с админ-панелью Эквио: компании не будут синхронизироваться и их нужно будет создавать руками, сборки в админ-панель нужно будет заливать администраторам. Если чекбокс “Закрытый контур” снят, то сразу после создания сервера, список компаний будет автоматически синхронизирован и все компании с добавленного сервера будут отображены в интерфейсе релиз-сервиса.
        </p>
        <p>
          - Логин и пароль администратора - этот администратор будет использоваться для внесения изменений в админ. панель, касающихся релизов, и будет отражен в логах.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.server-block p {
  text-align: justify;
}
.server-block span{
  font-weight: bold;
}
.server-block hr {
  box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}
</style>

<script>
export default {

}
</script>

<template>
  <section>
    <div class="container header" v-if="release">
      <release-details-header :release="release" />
    </div>

    <div class="container" v-if="release">
      <p class="is-size-4">Список компаний:</p>
      <div class="columns">
        <div class="column is-4">
          <div
            v-for="release_company in release.companies"
            :key="release_company.id"
            class="release-company"
            :class="{ 'is-selected': selectedCompany.id == release_company.id }"
            @click="selectCompany(release_company.id)"
          >
            <p>
              {{ release_company.company.company_id }}.
              {{ release_company.company.name }}
              <span>
                <b-icon
                  icon="apple"
                  v-if="release_company.platform.includes('ios')"
                ></b-icon>
                <b-icon
                  icon="android"
                  type="is-success"
                  v-if="release_company.platform.includes('android')"
                ></b-icon>
              </span>
              <router-link
                class="app-name"
                :to="{
                  name: 'edit-company',
                  params: { id: release_company.company.id },
                }"
              >
                <b-icon icon="pencil"></b-icon>
              </router-link>
            </p>

            <b-taglist>
              <b-tag v-if="release_company.status == 0" type="is-light">
                Не начато
              </b-tag>
              <b-tag
                v-else-if="
                  release_company.status == 1 &&
                  release.report.is_stopped == false
                "
                type="is-light"
              >
                Подготавливаем данные
              </b-tag>
              <b-tag
                v-else-if="
                  release_company.status == 2 &&
                  release.report.is_stopped == false
                "
                type="is-light"
              >
                Собираем приложение
              </b-tag>
              <b-tag
                v-else-if="
                  release_company.status == 3 &&
                  release.report.is_stopped == false
                "
                type="is-light"
              >
                Проводим релиз
              </b-tag>
              <b-tag v-else-if="release_company.status == 4" type="is-success">
                Завершено
              </b-tag>
              <b-tag v-else-if="release_company.status == 5" type="is-danger">
                Ошибка
              </b-tag>
              <b-tag v-else-if="release_company.status == 6" type="is-warning">
                Релиз остановлен
              </b-tag>
              <b-tag
                v-else-if="
                  release.report.completed == false &&
                  release.report.is_stopped == true
                "
                type="is-warning"
              >
                Остановка релиза
              </b-tag>
              <b-tag
                v-if="release_company.skipped_upload_to_admin_panel"
                type="is-warning"
              >
                Эта сборка не залита в админ-панель
              </b-tag>
            </b-taglist>
          </div>
        </div>

        <div class="column is-8">
          <div class="company-summary box">
            <h1 class="title">
              Компания:
              <i>{{ selectedCompany.company.company_id }}.</i>
              {{ selectedCompany.company.name }}
              <b-taglist class="is-pulled-right">
                <b-tag v-if="selectedCompany.status == 0" type="is-light">
                  Не начато
                </b-tag>
                <b-tag
                  v-else-if="
                    selectedCompany.status == 1 &&
                    release.report.is_stopped == false
                  "
                  type="is-light"
                >
                  Подготавливаем данные
                </b-tag>
                <b-tag
                  v-else-if="
                    selectedCompany.status == 2 &&
                    release.report.is_stopped == false
                  "
                  type="is-light"
                >
                  Собираем приложение
                </b-tag>
                <b-tag
                  v-else-if="
                    selectedCompany.status == 3 &&
                    release.report.is_stopped == false
                  "
                  type="is-light"
                >
                  Проводим релиз
                </b-tag>
                <b-tag
                  v-else-if="selectedCompany.status == 4"
                  type="is-success"
                >
                  Завершено
                </b-tag>
                <b-tag v-else-if="selectedCompany.status == 5" type="is-danger">
                  Ошибка
                </b-tag>
                <b-tag
                  v-else-if="selectedCompany.status == 6"
                  type="is-warning"
                >
                  Релиз остановлен
                </b-tag>
                <b-tag
                  v-else-if="
                    release.report.completed == false &&
                    release.report.is_stopped == true
                  "
                  type="is-warning"
                >
                  Остановка релиза
                </b-tag>
                <b-tag
                  v-if="selectedCompany.skipped_upload_to_admin_panel"
                  type="is-warning"
                >
                  Эта сборка не залита в админ-панель
                </b-tag>
              </b-taglist>
            </h1>
            <p class="subtitle">
              {{ selectedCompany.company.app_name }}

              <span>
                <b-icon
                  icon="apple"
                  v-if="selectedCompany.platform.includes('ios')"
                ></b-icon>
                <b-icon
                  icon="android"
                  type="is-success"
                  v-if="selectedCompany.platform.includes('android')"
                ></b-icon>
              </span>
            </p>

            <div class="columns">
              <div class="column is-half">
                <p class="subtitle" v-if="selectedCompany.build_url">
                  <a :href="selectedCompany.build_url" target="_blank">
                    Ссылка на сборку
                  </a>
                </p>
                <p class="subtitle" v-if="selectedCompany.result_url">
                  <a :href="selectedCompany.result_url" target="_blank">
                    Ссылка на собранное приложение
                  </a>
                </p>
              </div>
              <div class="column is-half">
                Дополнительные артефакты:
                <table class="artifacts">
                  <tr
                    v-for="(value, key) in JSON.parse(
                      selectedCompany.build_artifacts
                    )"
                    :key="key"
                  >
                    <td>{{ key }}</td>
                    <td>
                      <template v-if="value && value.startsWith('http')">
                        <a :href="value" target="_blank">{{ value }}</a>
                      </template>
                      <template v-else>{{ value }}</template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="company-details content">
            <log-viewer :logs="selectedCompany.build_logs"></log-viewer>
            <!-- <p>{{ selectedCompany.build_artifacts }}</p>
            <p>{{ selectedCompany.build_context }}</p> -->
          </div>
        </div>
      </div>
      <!-- <b-tabs>
          <b-tab-item :key="0" v-if="androidCompanies.length > 0">
            <template slot="header">
              <span>Android</span>
            </template>

            <report-table
              :companies="androidCompanies"
              :openedRowsIds="openedRowsIds"
              :release="release"
            />
          </b-tab-item>

          <b-tab-item :key="1" v-if="iosCompanies.length > 0">
            <template slot="header">
              <span>iOS</span>
            </template>

            <report-table
              :companies="iosCompanies"
              :openedRowsIds="openedRowsIds"
              :release="release"
            />
          </b-tab-item>
        </b-tabs> -->
    </div>
  </section>
</template>


<script>
import { mapState } from "vuex";

import ReportTable from "@/components/release/ReportTable";
import ReleaseDetailsHeader from "@/components/release/ReleaseDetailsHeader";
import LogViewer from "@/components/release/LogViewer";

export default {
  created: function () {
    this.refreshReport(true);
    this.timerId = setInterval(
      () => this.refreshReport(false),
      1000 /** ms */ * 20
    );
  },
  beforeDestroy: function () {
    clearInterval(this.timerId);
  },
  // props: {
  //   release: Object
  // },
  data: function () {
    return {
      timerId: -1,
    };
  },
  methods: {
    refreshReport: function (showLoading) {
      if (showLoading) {
        this.$store.dispatch("startLoading");
      }

      this.$store
        .dispatch("releases/getReport", { id: this.$route.params.id })
        .then((_) => {
          if (showLoading) {
            this.$store.dispatch("stopLoading");

            // open all rows only on initial loading
            this.openedRowsIds = this.release.companies.map((c) => c.id);
          }
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },

    selectCompany: function (releaseCompanyId) {
      this.$router.replace({
        name: this.$route.name,
        hash: `#${releaseCompanyId}`,
      });
    },
  },
  computed: {
    ...mapState({
      release: (state) => state.releases.currentRelease,
    }),
    androidCompanies: function () {
      return this.release.companies.filter((c) => c.platform === "android");
    },
    iosCompanies: function () {
      return this.release.companies.filter((c) => c.platform === "ios");
    },

    selectedCompany: function () {
      if (this.$route.hash) {
        let id = this.$route.hash.substring(1, this.$route.hash.length);
        let comps = this.release.companies.filter(
          (c) => new String(c.id) == id
        );
        if (comps) {
          return comps[0];
        }
      }
      return this.release.companies[0];
    },
  },

  components: {
    ReportTable,
    ReleaseDetailsHeader,
    LogViewer,
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.header {
  margin-bottom: 64px;
}

.release-company {
  border-radius: 8px;
  padding: 8px;

  &:hover {
    background-color: $grey-lightest;
  }
}

.is-selected {
  border-width: 2px;
  border-color: $primary;
  border-style: solid;
}

.artifacts {
  border: 1px solid;
  font-size: small;
  td {
    border: 1px solid;
    padding: 4px;
  }
}
</style>

<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column">
          <form>
            <b-field type="is-info">
              <b-input v-model="name" expanded></b-input>
              <template slot="label">
                Название сервера
                <b-tooltip
                  label="Это название будет отображаться на странице списка серверов"
                  multilined
                  position="is-bottom"
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field type="is-primary">
              <b-input
                v-model="urlWeb"
                expanded
                placeholder="https://test-eq.ru/"
              ></b-input>
              <template slot="label">
                URL Web версии
                <b-tooltip
                  label="Это поле будет использоваться в приложении для работы deeplink. Пример: https://test-eq.ru/"
                  multilined
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field type="is-primary">
              <b-input
                v-model="urlApi"
                expanded
                placeholder="https://api.test-eq.ru"
              ></b-input>
              <template slot="label">
                URL мобильной API
                <b-tooltip
                  label="Это поле будет использоваться в приложении для доступа к серверу. Пример: https://api.test-eq.ru"
                  multilined
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field>
              <b-checkbox v-model="behindFirewall">
                Закрытый контур
                <b-tooltip
                  label="Если компания находится в закрытом контуре - мы выключаем все интеграции с админ-панелью. Компании нужно создавать руками, собранные приложения нужно будет залить в админпанель"
                  multilined
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </b-checkbox>
            </b-field>

            <template v-if="!behindFirewall">
              <b-field type="is-primary">
                <b-input
                  v-model="urlAdmin"
                  expanded
                  placeholder="https://mapi.test-eq.ru/v1/"
                ></b-input>
                <template slot="label">
                  URL API для административной панели
                  <b-tooltip
                    label="Это поле будет использоваться для заливки сборок в админ-панель. Пример: https://mapi.test-eq.ru/v1/"
                    multilined
                  >
                    <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                  </b-tooltip>
                </template>
              </b-field>

              <b-field type="is-primary">
                <b-input v-model="adminLogin" expanded></b-input>
                <template slot="label">
                  Логин администратора
                  <b-tooltip
                    label="Это поле будет использоваться для заливки сборок в админ-панель. Внимание: учетная запись должна иметь права суперадминистратора, т.к. только с такими правами можно загружать сборки"
                    multilined
                  >
                    <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                  </b-tooltip>
                </template>
              </b-field>

              <b-field type="is-primary">
                <b-input
                  v-model="adminPassword"
                  type="password"
                  password-reveal
                  expanded
                ></b-input>
                <template slot="label">
                  Пароль администратора
                  <b-tooltip
                    label="Это поле будет использоваться для заливки сборок в админ-панель. Внимание: учетная запись должна иметь права суперадминистратора, т.к. только с такими правами можно загружать сборки"
                    multilined
                  >
                    <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                  </b-tooltip>
                </template>
              </b-field>
            </template>

            <b-field type="is-primary">
              <b-input
                v-model="s3ProxyToken"
                type="password"
                password-reveal
                expanded
              ></b-input>
              <template slot="label">
                Токен для s3 proxy
                <b-tooltip
                  label="С этим токеном заливаются assetlinks.json"
                  multilined
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field>
              <b-button @click="addServer()" type="is-primary">
                Добавить
              </b-button>
            </b-field>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { toastHandler } from "@/plugins/toastHandler";

export default {
  created: function () {},
  data: function () {
    return {
      name: "",
      urlWeb: "",
      urlApi: "",
      urlAdmin: "",
      adminLogin: "",
      adminPassword: "",
      s3ProxyToken: "",
      behindFirewall: false,
    };
  },
  methods: {
    addServer: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("servers/add", {
          name: this.name,
          urlWeb: this.urlWeb,
          urlApi: this.urlApi,
          urlAdmin: this.urlAdmin,
          adminLogin: this.adminLogin,
          adminPassword: this.adminPassword,
          s3ProxyToken: this.s3ProxyToken,
          behindFirewall: this.behindFirewall,
        })
        .then((res) => {
          this.$router.push("/");
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, [
            `Сервер "${this.name}" добавлен!`,
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
  },
  computed: {},
  components: {},
};
</script>

<template>
  <div>
    TODO сделать новую ссылку, чтобы ее можно было скопировать и отпраавить
  </div>
</template>

<script>

export default {
}
</script>

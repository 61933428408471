<template>
  <div>
    <b-field>
      <b-button
        type="is-success is-light"
        size="is-small"
        icon-left="check"
        v-if="this.resetData == false && androidDataUrl"
        outlined
      >
        <a
          size="is-small"
          class="is-size-7 a"
          target="_blank"
          :href="androidDataUrl"
        >
          Данные для сборки (data.zip)
        </a>
      </b-button>

      <b-button
        type="is-primary"
        size="is-small"
        icon-left="eye"
        class="button-figma"
        @click="showFigmaHistoryModal()"
      >
        Посмотреть историю изменений Figma
      </b-button>

      <b-button
        type="is-primary"
        size="is-small"
        icon-left="delete"
        class="button-figma"
        outlined
        @click="resetFigmaCache()"
      >
        Сбросить кэш для Figma
      </b-button>
    </b-field>

    <b-field :addons="false" type="is-primary">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <b-input v-model="androidPackage" placeholder="com.equeo.test"></b-input>
        </div>
        <div class="control">
          <b-button @click="handlePackageClick" class="is-primary is-fullwidth">Подставить значения</b-button>
        </div>
      </div>
      <template slot="label">
        Package
        <b-tooltip label="Пакет приложения. Пример: com.equeo.test" multilined>
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>

      <firebase-help-message
        :androidPackage="androidPackage"
        :firebaseProjects="firebaseProjects"
        ref="firebaseMessage"
        @create="launchFirebaseModal()"
        v-if="firebaseProjects"
      />
    </b-field>



    <b-field type="is-primary">
      <b-input v-model="alias" placeholder="alias"></b-input>
      <template slot="label">
        Alias
        <b-tooltip label="Название для подписи андроид приложения" multilined>
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="aliasPassword"></b-input>
      <template slot="label">
        Пароль для alias
        <b-tooltip label="Пароль для подписи андроид приложения" multilined>
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <b-input v-model="figmaId"></b-input>
        </div>
        <div class="control">
          <b-button @click="parseFigmaUrl" class="is-primary is-fullwidth">Спарсить ID</b-button>
        </div>
      </div>
      <template slot="label">
        Figma ID
        <b-tooltip
          label="Вставьте URL, figma ID будет найден после нажатия кнопки"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>

        <span class="has-text-weight-light">
          Последнее обновление: {{ figmaLastUpdated | as_date }}
        </span>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="localiseId"></b-input>
      <template slot="label">
        Localise ID
        <b-tooltip
          label="ID проекта в Localise, для указания переопределенных текстовок"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field type="is-primary">
      <b-input v-model="shaform"></b-input>
      <template slot="label">
        SHA256
        <b-tooltip label="Отпечаток образа" multilined>
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>
    </b-field>

    <b-field>
      <b-checkbox v-model="needZoom">
        Добавить Zoom
        <b-tooltip
          label="Если эта галочка включена - в приложение будет добавлен Zoom"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>

    <b-field>
      <b-checkbox v-model="isMarket">
        Загружать в Google Play
        <b-tooltip
          label="Если эта галочка включена - приложение будет загружено в Google Play"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>

    <b-field v-if="isMarket">
      <b-checkbox v-model="needAppBundle">
        Собирать App Bundle
        <b-tooltip
          label="Необходимо выбрать, если приложение собирается с Zoom SDK или приложение весит более 100 Мб (рекомендуется ставить для всех маркетовских сборок)"
          multilined
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>

    <b-field
      label="Какую учетную запись Google Play использовать"
      v-if="isMarket"
      type="is-primary"
    >
      <b-select v-model="googlePlayAccount">
        <option v-for="a in googlePlayAccounts" :value="a.id" :key="a.id">
          {{ a.name }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Какой сертификат Huawei Mobile Services использовать"
      type="is-primary"
    >
      <b-select v-model="huaweiMobileService">
        <option
          v-for="a in huaweiMobileServiceAccounts"
          :value="a.id"
          :key="a.id"
        >
          {{ a.name }}
        </option>
      </b-select>
    </b-field>

    <!-- <a
      v-if="androidDataUrl"
      class="is-size-7"
      target="_blank"
      :href="androidDataUrl"
    >
      Данные для сборки (data.zip)
    </a> -->

    <b-modal
      :active.sync="isFirebaseModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <firebase-create-app-modal
        :androidPackage="androidPackage"
        :firebaseProjects="firebaseProjects"
        @create="createAndroidAppInFirebase"
      />
    </b-modal>

    <b-modal
      :active.sync="isFigmaHistoryModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <figma-history-modal :history="figmaHistory" :company="company" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import FirebaseHelpMessage from "@/components/FirebaseHelpMessage.vue";
import FirebaseCreateAppModal from "@/components/FirebaseCreateAppModal.vue";
import FigmaHistoryModal from "@/components/FigmaHistoryModal.vue";
import { toastHandler } from "@/plugins/toastHandler";

var moment = require("moment");

export default {
  components: {
    FirebaseHelpMessage,
    FirebaseCreateAppModal,
    FigmaHistoryModal,
  },
  filters: {
    as_date(company) {
      if (!company) {
        return "";
      }
      return moment(company).format("YYYY-MM-DD HH:mm");
    },
  },
  props: {
    company: Object,
  },
  model: {
    prop: "company",
    event: "input",
  },
  data: function () {
    let marketAccount =
      this.company.android.android_market_account == null
        ? 0
        : this.company.android.android_market_account;
    if (this.company.android.android_dont_upload_to_market) {
      marketAccount = -1;
    }
    return {
      androidPackage: this.company.android.android_package,
      alias: this.company.android.android_alias,
      aliasPassword: this.company.android.android_alias_password,
      figmaId: this.company.android.android_figma_id,
      figmaLastUpdated: this.company.android.android_figma_updated_at,
      localiseId: this.company.android.android_localise_project_id,
      shaform: this.company.android.sha256,
      isMarket: this.company.android.android_is_market,
      googlePlayAccount: marketAccount,

      androidDataUrl: this.company.android.android_data_url,

      needZoom: this.company.android.android_need_zoom,
      approveDesign: this.company.android.android_approved_design,
      isFirebaseModalActive: false,
      isFigmaHistoryModalActive: false,
      figmaHistory: null,
      needAppBundle: this.company.android.android_need_app_bundle,

      huaweiMobileService:
        this.company.android.huawei_market_account == null
          ? 0
          : this.company.android.huawei_market_account,

      resetData: false,
    };
  },
  computed: {
    ...mapState({
      firebaseProjects: (state) => state.firebase.projects,
      addedGooglePlayAccounts: (state) => state.marketAccounts.android,
      addedhuaweiMobileServiceAccounts: (state) => state.marketAccounts.huawei,
    }),
    googlePlayAccounts: function () {
      return [
        ...this.addedGooglePlayAccounts,
        { id: -1, name: "Другая учетка Google Play, заливается Артемом" },
      ];
    },
    huaweiMobileServiceAccounts: function () {
      return [...this.addedhuaweiMobileServiceAccounts];
    },
  },
  watch: {
    androidPackage: function () {
      this.updateValue();
    },
    alias: function () {
      this.updateValue();
    },
    aliasPassword: function () {
      this.updateValue();
    },
    figmaId: function () {
      this.updateValue();
    },
    localiseId: function () {
      this.updateValue();
    },
    shaform: function () {
      this.updateValue();
    },
    isMarket: function () {
      this.needAppBundle = this.isMarket;

      this.updateValue();
    },
    googlePlayAccount: function () {
      this.updateValue();
    },
    needZoom: function () {
      this.updateValue();
    },
    needAppBundle: function () {
      this.updateValue();
    },
    huaweiMobileService: function () {
      this.updateValue();
    },
    approveDesign: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("companies/approveDesign", {
          id: this.id,
          platform: "android",
          approve: this.approveDesign,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
  },
  methods: {
    updateValue: function () {
      var obj = {
        ...this.company,
        android: {
          android_package: this.androidPackage,
          android_alias: this.alias,
          android_alias_password: this.aliasPassword,
          android_figma_id: this.figmaId,
          android_localise_project_id: this.localiseId,
          android_is_market: this.isMarket,
          sha256: this.shaform,
          android_market_account: this.googlePlayAccount,
          android_need_app_bundle: this.needAppBundle,
          android_need_zoom: this.needZoom,
          huawei_market_account: this.huaweiMobileService,
        },
      };

      this.$emit("input", obj);
    },
    handlePackageClick() {
      const packageParts = this.androidPackage.split(".");
      if (packageParts.length >= 1) {
        const lastWord = packageParts[packageParts.length - 1];
        this.alias = lastWord;
        const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let password = '';
        for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          password += characters[randomIndex];
        }

        this.aliasPassword = password;
      }
    },
    parseFigmaUrl() {
      const url = this.figmaId
      const pattern = /https:\/\/www\.figma\.com\/(file|proto)\/([a-zA-Z0-9]+)\/.*$/;

      // Extract the file ID using the regular expression
      const match = url.match(pattern);

      if (match && match.length >= 3) {
        // Return the file ID (group 2 in the regular expression match)
        this.figmaId = match[2];
      } else {
        // Invalid Figma URL, return null or handle the error accordingly
        this.figmaId = 'invalid url';
      }
    },
    resetDataZip: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("companies/resetDataZip", {
          id: this.company.id,
        })
        .then((res) => {
          this.resetData = true;
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, [
            "Данные для сборки сброшены!",
            "is-primary",
          ]);
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
    resetFigmaCache: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("companies/resetFigmaCache", {
          id: this.company.id,
          platform: "android",
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
    launchFirebaseModal: function () {
      this.isFirebaseModalActive = true;
    },
    createAndroidAppInFirebase: function (projectId) {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("firebase/addAndroidApp", {
          projectId: projectId,
          androidPackage: this.androidPackage,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
          this.isFirebaseModalActive = false;
          this.$refs.firebaseMessage.refreshMessage();
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
    showFigmaHistoryModal: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("companies/getFigmaHistory", {
          id: this.company.id,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");

          this.isFigmaHistoryModalActive = true;
          this.figmaHistory = res;
        })
        .catch((error) => {
          this.$handleError(error);
          this.$store.dispatch("stopLoading");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-figma {
  margin-left: 10px;
}
a {
  color: #006813;
  text-decoration: none;
}
a:visited {
  color: #880808;
}
a:hover {
  color: #fff;
  text-decoration: underline;
}
</style>

<template>
  <div class="modal-card huawei-account-block">
    <header class="modal-card-head">
      <p class="modal-card-title">Добавить учетную запись Huawei Mobile Services</p>
    </header>
    <section class="modal-card-body">
      <b-field type="is-info">
        <b-input v-model="accountName" placeholder="Название аккаунта"></b-input>
      </b-field>

      <b-field class="file">
        <b-upload v-model="credsFile" @input="onCredsUpdated" accept="application/json">
          <a class="button is-info">
            <b-icon icon="upload"></b-icon>
            <span>Добавить config для Huawei Mobile Services</span>
          </a>
        </b-upload>

        <span class="file-name" v-if="credsFile">{{ credsFile.name }}</span>
      </b-field>

    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Отменить</button>
      <button class="button is-primary" @click="create()">Добавить</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.huawei-account-block {
  width: auto;
}
</style>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data: function () {
    return {
      accountName: '',
      credsFile: null
    }
  },
  methods: {
    onCredsUpdated: function (creds) {
      this.credsFile = creds
    },
    create: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/addHuaweiAccount',
        {
          name: this.accountName,
          creds: this.credsFile
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          this.$parent.close()
          this.$emit('create', this.selectedProject)
          toastHandler.apply(this, ['Учетнтая запись Huawei Mobile Services добавлена!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

<template>
  <section>
    <div class="container">
      <div class="firebase-block">
        <p class="is-size-3">Как настроить Firebase?</p>
        <p>На текущий момент, проект Firebase выбирается автоматически, в зависимости от того, есть ли необходимый package (Android) или bundle id (iOS) в каком-либо из проектов в Firebase.</p>
        <br />

        <p>
          При создании нового идентификатора в админ-панели Firebase, необходимо обновить список доступных идентификаторов на релиз сервисе. Сделать это можно на странице
          <code class="is-family-monospace has-background-dark has-text-white">“Администрирование” -> Firebase -> Обновить конфигурацию Firebase.</code>
        </p>
        <hr />
        <p>
          <span>
            Чтобы добавить новый проект Firebase:
          </span>
        </p>
        <p>
          Загрузите файл
          <code class="is-family-monospace has-background-dark has-text-white">credentials.json</code>. Его можно получить в админ-панели Firebase, в настройках проекта:
          <code class="is-family-monospace has-background-dark has-text-white">Settings -> Service Accounts -> Firebase Admin SDK -> Generate new private key</code>
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.firebase-block p {
  text-align: justify;
}
.firebase-block span{
  font-weight: bold;
}
.firebase-block hr {
  box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}
</style>

<script>
export default {

}
</script>

<template>
  <section>
    <div class="container">
      <b-tabs class="release" type="is-boxed">
            <b-tab-item label="Версия" icon="arrange-send-backward">
              <p class="is-size-3">
                Как добавить версию приложения?
              </p>
              <p>
                Версии приложения добавляются автоматически при создании разработкой новых версий на GitLab. Обновление происходит раз в сутки. Принудительно обновить список версий можно вручную, нажав на кнопку “Обновить список версий”.
              </p>
            </b-tab-item>

            <b-tab-item label="Текст “Что нового?”" icon="newspaper-variant-multiple">
              <p class="is-size-3">
                Как добавить текст “Что нового?” для версии?
              </p>
              <p>
                В разделе “Версии” в списке найти нужную версию и нажать на иконку карандаша для редактирования. В открывшемся окне можно указать текст отдельно для Android и iOS. Для редактирования доступны 4 локали (аналогично языкам на платформе). Рекомендуется заполнять, как минимум, русский и английский вариант.
              </p>
              <p>
                Отсутствующие языки будут подставляться автоматически из английской локали.
              </p>
              <p>
                Для сохранения, после заполнения текстов, нажмите на кнопку “Обновить текст?”.
              </p>
            </b-tab-item>

            <b-tab-item label="Предварительная подготовка" icon="hammer-wrench">
              <p class="is-size-3">
                Предварительная подготовка
              </p>
              <p>
                Убедитесь, что все настройки компании верные и заполнен текст "Что нового?" для нужной версии.
              </p>
            </b-tab-item>

            <b-tab-item label="Релиз" icon="rocket-launch">
              <p class="is-size-3">
                Создание релиза
              </p>
              <p>
                Нажимаем на кнопку “Сделать релиз” в верхнем баре.
              </p>
              <hr />

              <div class="release-block">
                <b-message type="is-success">
                  <p>
                    <span>
                      Выбор компаний для релиза.
                    </span>
                      Доступен выбор каждой компании вручную или автоматический выбор по тегам. Для выбора компании - поставьте чекбокс в первом столбце. После выбора нужных компаний, нажмите кнопку “вперед”, расположенную под таблицей.
                  </p>
                </b-message>
                <figure class="image">
                  <img src="@/assets/docs/release-companies.png" />
                </figure>
                <hr />

                <b-message type="is-success">
                  <p>
                    <span>
                      Выбор версии.
                    </span>
                      Начните набирать номер версии и выберите нужную в выпадающем списке. Иконки платформ обозначают наличие версии для соответствующей платформы.
                  </p>
                </b-message>
                <p>
                  После выбора версии есть возможность поменять тексты “Что нового” для текущего релиза (при следующем релизе он останется тем, который был заполнен в разделе “Версии”).
                </p>
                <p>
                  После выбора версии нажмите “Вперед”
                  </p>
                <figure class="image">
                    <img src="@/assets/docs/release-versions.png" />
                </figure>
                <hr />

                <b-message type="is-success">
                  <p>
                    <span>
                      Выбор платформы.
                    </span>
                      Для релиза доступны две платформы: Android и iOS. На текущий момент рекомендуется делать релизы на одну платформу за раз. Для выбора нажмите на кнопку с названием платформы.
                  </p>
                </b-message>
                <hr />

                <b-message type="is-success">
                  <p>
                    <span>
                      Выберите тип релиза:
                    </span>
                  </p>
                  <p>
                    - Только собрать приложения - приложения будут собраны, можно будет скачать apk/ipa файлы, но релиза для пользователей не произойдет. Полезно, когда нужно проверить параметризацию, конфигурацию и прочее.
                  </p>
                  <p>
                    - Собрать и выпустить - приложения будут собраны и отправлены в полноценный релиз для пользователей (и в маркеты и по ссылке), в админке также будут сделаны нужны для релиза изменения.
                  </p>
                </b-message>

                <b-message type="is-danger">
                  <p>
                    На данный момент не поддерживается андроид релиз в маркеты для компаний, которые имеют собственные учетки магазинов. Их необходимо заливать вручную, скачав сборки через “только собрать приложения” и сделав нужные настройки в админке после релиза.
                  </p>
                </b-message>

                <p>
                  Описание - этот текст появится в разделе отчеты и нужен, чтобы было проще ориентироваться в произведенных релизах. Можно вписать любой текст или оставить поле пустым.
                </p>
                <figure class="image">
                  <img src="@/assets/docs/release-platforms.png" />
                </figure>
                <hr />

                <b-message type="is-success">
                  <p>
                    <span>
                      Подтверждение релиза.
                    </span>
                      Проверьте все настройки релиза и, если все ок, запускайте релиз.
                  </p>
                </b-message>
              </div>
            </b-tab-item>

            <b-tab-item label="Сборка/Статус" icon="robot-industrial">
              <div class="build-block">
                <p class="is-size-3">
                  Как скачать сборку/проверить статус релиза?
                </p>
                <p>
                  Перейдите в раздел “Отчеты” в верхнем баре и выберите релиз из списка.
                </p>
                <p>
                  Ссылку для установки релизной версии и передачи клиенту можно получить скопировав ссылку кнопки “Публичная ссылка” (только Android).
                </p>

                <b-message type="is-success">
                  <p>
                    Получить сборку можно нажав на “Ссылка на собранное приложение” (только для Android).
                  </p>
                </b-message>
                <figure class="image">
                  <img src="@/assets/docs/release-report-link.png" />
                </figure>

                <b-message type="is-success">
                  <p>
                    Также получить последнюю сборку можно на списке компаний (только для андроид)
                  </p>
                </b-message>
                <figure class="image">
                  <img src="@/assets/docs/company-last-version.png" />
                </figure>
              </div>
            </b-tab-item>

            <b-tab-item label="Отчет" icon="chat-processing">
              <p class="is-size-3">Информация о произведенных релизах</p>

              <b-message type="is-success">
                <p>
                  В разделе “Отчеты” можно посмотреть все проведенные релизы и детали релизов.
                </p>
              </b-message>

              <p>
                На главной странице указано: дата релиза, версия, краткое описание (если доступно), кто запустил релиз и прогресс.
              </p>

              <div class="report-block">
                <hr />
                <p>
                  <span>
                    По нажатию на релиз, откроются детали по каждой сборке:
                  </span>
                </p>
                <p>
                  - Ссылка на сборку Gitlab - может быть полезно для разработчиков, если возникнут проблемы со сборкой.
                </p>
                <p>
                  - Ссылка на собранное приложение - ссылка на приложение, которое может использоваться для тестирования. Не рекомендуется отправлять эту ссылку клиентам.
                </p>
                <p>
                  - Публичная ссылка - ссылка в Google Play или на s3. Эту ссылку можно отправлять клиентам. Эта же ссылка будет выведена на списке компаний.
                </p>
                <b-message type="is-danger">
                  <p>
                    <span>
                      (Только Андроид)
                    </span>
                      Ссылка на архив data.zip - параметризация, конфигурация сборки. Полезно для разработчиков.
                  </p>
                  <p>
                    <span>
                      (Только iOS)
                    </span>
                      Cсылка на ipa - ссылка на ipa-арзив для передачи клиентам, которые выкладывают приложение самостоятельно.
                </p>
                </b-message>
              </div>
            </b-tab-item>
        </b-tabs>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.release-block span {
  font-weight: bold;
}
.release hr {
  box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}
.release-block p {
  text-align: justify;
}
.release-block figure {
  margin-top: 20px;
  margin-bottom: 20px;
}
.build-block figure {
  margin-top: 20px;
  margin-bottom: 20px;
}
.report-block p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.report-block span{
  font-weight: bold;
}
</style>

<script>
export default {

}
</script>

import VersionsApi from "@/api/versions";
import semver from "semver";

const state = {
  available: [],
};

const getters = {};

const mutations = {
  storeVersions(state, versions) {
    state.available = versions;
    state.available.sort((a, b) => semver.compare(b.name, a.name));
  },
};
const actions = {
  getAll({ commit }) {
    return VersionsApi.getAll().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeVersions", res.data.versions);
      return Promise.resolve();
    });
  },
  edit({ commit }, { id, localizations }) {
    return VersionsApi.edit(id, localizations).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  refresh({ commit, dispatch }) {
    return VersionsApi.refresh().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return dispatch("getAll");
    });
  },
  getLocalizationForRelease(_context, { release }) {
    return VersionsApi.getLocalizationForRelease(release).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }
      return res.data.localizations;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'
import ErrorHandlerPlugin from '@/plugins/ErrorHandler'

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(ErrorHandlerPlugin)

// this is hack
// we need to know, if user has token in storage or not
store.dispatch('auth/loadTokensFromStorage')

router.beforeEach(async (to, from, next) => {
  if (!store.getters['auth/isAuthorized']) {
    if (from.name === to.name) {
      return
    }

    if (!to.matched.some(record => record.meta.availableWithoutAuth)) {
      router.push({ name: 'login' })
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <section>
    <div class="container">
      <div class="apps-block">
        <p class="is-size-3">Как настроить приложение?</p>

        <b-message type="is-danger">
          <p>Для заполнения настроек рекомендуется обращаться к разработке</p>
        </b-message>

        <p>
          Выберите компанию в списке и нажмите на карандаш для редактирования. В настройках приложение есть две вкладки: Android и iOS.
        </p>
        <p>
          Помимо настроек здесь можно установить “тег” для компании, с помощью которого компании для релиза будет выбтирать быстрее (например “маркетовские компании” или “первый приоритет”).
        </p>
        <p>
          Настройки заполняются один раз при создании приложения.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.apps-block p {
  text-align: justify;
}
</style>

<script>
export default {

}
</script>

import ReleasesApi from "@/api/releases";

const state = {
  available: null,
  currentRelease: null,
  filter: null,
  fullStatus: null,
  fullDescription: null,
};

const getters = {};

const mutations = {
  storeReleases(state, releases) {
    state.available = releases;
  },
  storeRelease(state, release) {
    state.currentRelease = release;
  },
  storeStatus(state, status) {
    state.fullStatus = status;
  },
  storeDescription(state, description) {
    state.fullDescription = description;
  },
};
const actions = {
  getAll({ commit }, { page, sort, filters }) {
    return ReleasesApi.getAll(page, sort, filters).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeReleases", res.data.releases);
      return Promise.resolve(res.data);
    });
  },
  new(
    _,
    {
      companies,
      version,
      platforms,
      mode,
      message,
      isTestRelease,
      localizations,
    }
  ) {
    let companiesIds = companies.map((c) => c.id);
    return ReleasesApi.new(
      companiesIds,
      version.id,
      platforms,
      mode,
      message,
      isTestRelease,
      localizations
    ).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },
  stopRelease(_, { id, stop }) {
    return ReleasesApi.stopRelease(id, stop).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },
  stopCompanyRelease(_, { release, company }) {
    return ReleasesApi.stopCompanyRelease(release, company).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },
  status({ commit }, { status }) {
    commit("storeStatus", status);
    localStorage.setItem("isFullStatus", status);
  },
  description({ commit }, { description }) {
    commit("storeDescription", description);
    localStorage.setItem("isFullDescription", description);
  },
  loadStatusFromStorage({ commit }) {
    const stored = localStorage.getItem("isFullStatus");
    if (stored === null) {
      commit("storeStatus", stored);
      return false;
    } else {
      return stored === "true";
    }
  },
  loadDescriptionFromStorage({ commit }) {
    const stored = localStorage.getItem("isFullDescription");
    if (stored === null) {
      commit("storeDescription", stored);
      return false;
    } else {
      return stored === "true";
    }
  },
  confirmation(
    _,
    { companies, version, platforms, message, isTestRelease, localizations }
  ) {
    var companiesIds = companies.map((c) => c.id);
    return ReleasesApi.confirmation(
      companiesIds,
      version.id,
      platforms,
      message,
      isTestRelease,
      localizations
    ).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },
  getReport({ commit }, { id }) {
    return ReleasesApi.getReport(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeRelease", res.data.release);
      return Promise.resolve();
    });
  },
  uploadToAdminPanel(_context, { id }) {
    return ReleasesApi.uploadToAdminPanel(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  prepareRetryData(context, { id, onlyFailed }) {
    return ReleasesApi.prepareRetryData(id, onlyFailed).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      const release = res.data.release;

      context.commit(
        "companies/storeCompaniesAppend",
        release.companies.map((rc) => rc.company),
        { root: true }
      );

      context.dispatch("versions/getAll", null, {
        root: true,
      });

      return Promise.resolve(release);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

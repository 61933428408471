<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column">
          <form>
            <b-field>
              <b-input v-model="companyId" expanded type="number"></b-input>
              <template slot="label">
                ID компании
                <b-tooltip label="ID компании в админ-панели e.Queo" multilined>
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field>
              <b-input v-model="name" expanded></b-input>
              <template slot="label">
                Название компании
                <b-tooltip
                  label="Это название будет отображаться на странице списка серверов"
                  multilined
                  position="is-bottom"
                >
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </template>
            </b-field>

            <b-field>
              <b-button @click="addCompany()">Добавить</b-button>
            </b-field>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created: function () {

  },
  data: function () {
    return {
      name: '',
      companyId: ''
    }
  },
  methods: {
    addCompany: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/add',
        {
          name: this.name,
          companyId: parseInt(this.companyId),
          serverId: this.$route.params.id
        })
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
  },
  components: {
  }
}
</script>

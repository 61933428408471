<template>
  <b-table
    :data="companies"
    @click="$emit('click', $event)"
    hoverable
    detailed
    detail-key="company.id"
    :opened-detailed="openedRowsIds"
    v-if="companies.length > 0"
  >
      <b-table-column label="ID" v-slot="props">
        {{ props.row.company.company_id }}
      </b-table-column>

      <b-table-column label="Название компании" v-slot="props">
        {{ props.row.company.name }}
      </b-table-column>

      <b-table-column label="Название приложения" v-slot="props">
        <router-link
          class="app-name"
          :to="{ name: 'edit-company', params: { id: props.row.company.id } }"
        >
          <!-- {{ getVisibleName(props.row.company) }} -->
          {{ (props.row.company.app_name).length > 30 ? props.row.company.app_name.slice(0,30) + '...' : props.row.company.app_name }}
        </router-link>
        <!-- <p>{{ props.row.company.app_name_android }}</p>
        <p>{{ props.row.company.app_name_ios }}</p>-->
      </b-table-column>

      <b-table-column label="Статус" v-slot="props">
        <b-taglist>
          <b-tag v-if="props.row.status == 0" type="is-light">
            Не начато
          </b-tag>
          <b-tag v-else-if="props.row.status == 1 && release.report.is_stopped == false" type="is-light">
            Подготавливаем данные
          </b-tag>
          <b-tag v-else-if="props.row.status == 2 && release.report.is_stopped == false" type="is-light">
            Собираем приложение
          </b-tag>
          <b-tag v-else-if="props.row.status == 3 && release.report.is_stopped == false" type="is-light">
            Проводим релиз
          </b-tag>
          <b-tag v-else-if="props.row.status == 4" type="is-success">
            Завершено
          </b-tag>
          <b-tag v-else-if="props.row.status == 5" type="is-danger">
            Ошибка
          </b-tag>
          <b-tag v-else-if="props.row.status == 6" type="is-warning">
            Релиз остановлен
          </b-tag>
          <b-tag v-else-if="release.report.completed == false && release.report.is_stopped == true" type="is-warning">
            Остановка релиза
          </b-tag>
          <b-tag v-if="props.row.skipped_upload_to_admin_panel" type="is-warning">
            Эта сборка не залита в админ-панель
          </b-tag>
        </b-taglist>
      </b-table-column>
    </template>

    <template slot="detail" slot-scope="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <b-message type="is-success">
              <template v-if="props.row.platform === 'android'" class="links">
                <p v-if="props.row.company.android_data_url">
                  <a
                    :href="props.row.company.android_data_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на архив (data.zip)
                  </a>
                </p>

                <p v-if="props.row.build_url">
                  <a
                    :href="props.row.build_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на сборку (Gitlab)
                  </a>
                </p>

                <!-- && release.step_release == 0 -->
                <p v-if="props.row.build_artifact_url">
                  <a
                    :href="props.row.build_artifact_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на собранное приложение (для внутреннего использования)
                  </a>
                </p>

                <p v-if="props.row.result_url">
                  <a :href="props.row.result_url" target="_blank">
                    Публичная ссылка
                  </a>
                </p>
              </template>

              <template v-if="props.row.platform === 'ios'" class="links">
                <p v-if="props.row.build_url">
                  <a
                    :href="props.row.build_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на сборку (Gitlab)
                  </a>
                </p>

                <p v-if="props.row.release_url">
                  <a
                    :href="props.row.release_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на задачу релиза (Gitlab)
                  </a>
                </p>

                <!-- && release.step_release == 0 -->
                <p v-if="props.row.build_artifact_url">
                  <a
                    :href="props.row.build_artifact_url"
                    target="_blank"
                    class="is-size-7"
                  >
                    Ссылка на собранное приложение (для внутреннего использования)
                  </a>
                </p>

                <p v-if="props.row.result_url">
                  <a :href="props.row.result_url" target="_blank">
                    Публичная ссылка
                  </a>
                </p>
              </template>
            </b-message>

          <div class="message-block">
            <b-message v-if="props.row.status == 6" type="is-warning">
              <strong>Релиз принудительно остановлен</strong>
            </b-message>

            <b-message v-if="props.row.error_code" type="is-danger">
             <strong> {{ getErrorMessageFromCode(props.row.error_code) }}</strong>
            </b-message>

            <b-message v-if="props.row.readable_error_message" type="is-danger">
             <strong> {{ props.row.readable_error_message }}</strong>
            </b-message>

            <b-message v-if="props.row.company.ios_market_url == null && props.row.platform == 'ios'" type="is-danger">
              <strong>AppStore URL не заполнен</strong>
            </b-message>

            <b-message type="is-danger" v-if="isOpenError==true">
             <div class="notification">
                <div class="content">
                  <p v-html="errorStackWithBrTags(props.row.error_message)"></p>
                </div>
              </div>
            </b-message>
          </div>

            <div class="buttons-block">
              <button v-if="props.row.error_code"
               class="button is-danger"
               @click="isOpenError = !isOpenError">
                Сообщение об ошибке
              </button>

              <b-button
                v-if="props.row.error_code == 4 || props.row.company.ios_market_url == null"
                tag="router-link"
                :to="{
                  name: 'edit-company',
                  params: { id: props.row.company.id },
                }"
                icon-left="pencil"
                type="is-primary"
                class="edit-btn"
                target="_blank"
              >
                Перейти к настройкам компании
              </b-button>

              <b-button
                v-if="release.report.is_stopped == false && release.report.completed == false || props.row.status != 6 && props.row.status != 5 && props.row.status != 4"
                icon-left="shield-alert"
                type="is-primary"
                class="edit-btn"
                target="_blank"
                @click="stopCompanyRelease(props.row.company.company_id)"
              >
                Остановить релиз
              </b-button>
            </div>
          </div>
        </div>
      </article>
  </b-table>
</template>

<style lang="scss" scoped>
.links p {
  margin-bottom: 0;
}
.message-block {
  margin-top: 20px;
  margin-bottom: 20px;
}
.buttons-block .button {
  margin-right: 15px;
}
.app-name {
  color: #20ba53;
}
</style>

<script>
import { toastHandler } from "@/plugins/toastHandler";

export default {
  props: {
    companies: Array,
    openedRowsIds: Array,
    release: Object,
  },
  data: function () {
    return {
      isOpenError: false,
    };
  },
  mounted: function () {},
  methods: {
    stopCompanyRelease: function (company) {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("releases/stopCompanyRelease", {
          release: Number(this.$route.params.id),
          company: company,
        })
        .then((_) => {
          this.$store.dispatch("stopLoading");
          toastHandler.apply(this, ["Релиз остановлен!", "is-primary"]);
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    errorStackWithBrTags: function (errorMessage) {
      return errorMessage
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/(\\r)*\n/g, "<br>");
    },
    getErrorMessageFromCode: function (errorCode) {
      switch (errorCode) {
        case 0:
          return "Нет ошибок";
        case 1:
          return "Ошибка на этапе подготовки данных для сборки";
        case 2:
          return "Ошибка на этапе сборки приложения";
        case 3:
          return "Ошибка на этапе релиза приложения";
        case 4:
          return "Дизайн не подтвержден";
      }
    },
    getVisibleName: function (company) {
      return company.appName === "" ? company.companyName : company.appName;
    },
  },
};
</script>

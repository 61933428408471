import HmsApi from '@/api/hms'

const state = {
  available: []
}

const getters = {
}

const mutations = {
  uploadedAgConnect (state, update) {
    state.available = update
  }
}

const actions = {
  uploadAgConnect ({ commit }, { file }) {
    return HmsApi.uploadAgConnect(file).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('uploadedAgConnect', res.data.updated)
      return Promise.resolve(res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

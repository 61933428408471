<template>
  <base-about aboutType="serversList">
    <span>
      <p>
        На этой странице представлены все доступные компании и их приложения. Вы можете скачать последную сборку нужного приложения, нажав на
        <b>иконки</b>
        <b-icon icon="android" type="is-success"></b-icon>и
        <b-icon icon="apple" type="is-dark"></b-icon>, если он доступны.
      </p>
      <p>
        После нажатия на иконку
        <b>редактирования</b>
        <b-icon icon="pencil" type="is-dark"></b-icon>, откроется страница, на которой можно отредактировать параметры приложения (изменить нназвание, добавить параметризацию и т.д.).
      </p>
      <p>
        Компанию можно
        <b>архивировать</b>
        <b-icon icon="archive" type="is-dark"></b-icon>. В таком случае, она не будет отображраться на списке компании и ее нельзя будет выбрать для релиза.
      </p>
      <p>
        Для
        <b>нового релиза</b> используйте кнопку
        <b-button
          tag="router-link"
          :to="{name: 'new-release'}"
          icon-left="rocket"
          type="is-dark"
          outlined
          size="is-small"
        >Сделать релиз</b-button>
      </p>
    </span>
  </base-about>
</template>

<script>
import BaseAbout from './BaseAbout.vue'

export default {
  components: {
    BaseAbout
  }
}
</script>

<template>
  <section>
    <about-releases-list />
    <div class="container">
      <div class="columns">
        <div class="column is-four-fifths">
          <template
            v-if="
              (releases != null && releases.length > 0) ||
              sort != null ||
              filters != null
            "
          >
            <b-table
              :data="releases"
              @click="onReleaseClick"
              hoverable
              paginated
              backend-pagination
              backend-filtering
              backend-sorting
              :total="total"
              :per-page="10"
              :current-page="page"
              @page-change="onPageChange"
              @filters-change="onFilter"
              @sort="onSort"
              type="is-danger"
            >
              <b-table-column
                label="Версия"
                searchable
                sortable
                field="version.name"
                v-slot="props"
              >
                {{ props.row.version.name }}
                <span v-if="isFullStatus == false">
                  <b-tag
                    v-if="
                      props.row.report.completed == false &&
                      props.row.report.is_stopped != true
                    "
                    type="is-light"
                    title="В работе"
                    class="small-tag"
                  >
                    <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                  </b-tag>

                  <b-tag
                    v-if="
                      props.row.report.completed && props.row.report.errors == 0
                    "
                    type="is-success"
                    title="Завершено"
                    class="small-tag"
                  >
                    <b-icon icon="check-bold" size="is-small"></b-icon>
                  </b-tag>

                  <b-tag
                    v-if="
                      props.row.report.completed && props.row.report.errors > 0
                    "
                    type="is-danger"
                    title="Завершено с ошибками"
                    class="small-tag"
                  >
                    <b-icon icon="exclamation-thick" size="is-small"></b-icon>
                  </b-tag>

                  <b-tag
                    v-if="props.row.report.is_stopped == true"
                    type="is-warning"
                    title="Релиз принудительно остановлен"
                    class="small-tag"
                  >
                    <b-icon icon="stop" size="is-small"></b-icon>
                  </b-tag>

                  <b-tag
                    v-if="props.row.is_not_uploaded_to_admin_builds"
                    type="is-warning"
                    title="Есть приложения, которые необходимо залить в админ-панель"
                    class="small-tag"
                  >
                    <b-icon icon="exclamation-thick" size="is-small"></b-icon>
                  </b-tag>

                  <b-tag
                    v-if="props.row.is_test_release"
                    type="is-info"
                    title="Тестовый"
                    class="small-tag"
                  >
                    <b-icon icon="check-all" size="is-small"></b-icon>
                  </b-tag>
                </span>

                <span v-if="isFullStatus == true">
                  <b-tag
                    v-if="
                      props.row.report.completed == false &&
                      props.row.report.is_stopped != true
                    "
                    type="is-light"
                    class="full-tag"
                  >
                    В работе
                  </b-tag>

                  <b-tag
                    v-if="
                      props.row.report.completed && props.row.report.errors == 0
                    "
                    type="is-success"
                    class="full-tag"
                  >
                    Завершено
                  </b-tag>

                  <b-tag
                    v-if="
                      props.row.report.completed && props.row.report.errors > 0
                    "
                    type="is-danger"
                    class="full-tag"
                  >
                    Завершено с ошибками
                  </b-tag>

                  <b-tag
                    v-if="props.row.report.is_stopped == true"
                    type="is-warning"
                    class="full-tag"
                  >
                    Релиз остановлен
                  </b-tag>

                  <b-tag
                    v-if="props.row.is_not_uploaded_to_admin_builds"
                    type="is-warning"
                    class="full-tag"
                  >
                    Есть приложения, которые необходимо залить в админ-панель
                  </b-tag>

                  <b-tag
                    v-if="props.row.is_test_release"
                    type="is-info"
                    class="full-tag"
                  >
                    Тестовый
                  </b-tag>
                </span>
              </b-table-column>

              <b-table-column
                label="Дата релиза"
                centered
                searchable
                sortable
                field="created_at"
                v-slot="props"
              >
                {{ props.row.created_at | as_date }}
              </b-table-column>

              <b-table-column
                label="Описание"
                searchable
                field="message"
                v-slot="props"
              >
                <div
                  v-if="isFullDescription == false && props.row.message != null"
                >
                  {{
                    props.row.message.length > 20
                      ? props.row.message.slice(0, 20) + "..."
                      : props.row.message
                  }}
                </div>
                <div v-if="isFullDescription == true">
                  {{ props.row.message }}
                </div>
              </b-table-column>

              <b-table-column
                label="Запустил"
                searchable
                sortable
                field="creator.login"
                v-slot="props"
              >
                <div v-if="props.row.creator">
                  {{
                    props.row.creator.login.length > 30
                      ? props.row.creator.login.slice(0, 30) + "..."
                      : props.row.creator.login
                  }}
                </div>
              </b-table-column>

              <b-table-column
                label="Платформы"
                searchable
                centered
                sortable
                field="platforms"
                width="4rem"
                v-slot="props"
              >
                <span>
                  <b-icon
                    icon="apple"
                    v-if="props.row.platforms.includes('ios')"
                  ></b-icon>

                  <b-icon
                    icon="android"
                    type="is-success"
                    v-if="props.row.platforms.includes('android')"
                  ></b-icon>
                </span>
              </b-table-column>

              <b-table-column label="Прогресс" v-slot="props">
                <b-progress
                  v-if="
                    props.row.report.completed &&
                    props.row.report.errors == 0 &&
                    props.row.report.is_stopped == false
                  "
                  :value="props.row.report.progress"
                  :max="props.row.report.total"
                  type="is-success"
                  show-value
                >
                  {{ props.row.report.progress }} /
                  {{ props.row.report.total }}
                </b-progress>

                <b-progress
                  v-else-if="
                    props.row.report.completed && props.row.report.errors > 0
                  "
                  :value="props.row.report.progress"
                  :max="props.row.report.total"
                  type="is-danger"
                  show-value
                >
                  {{ props.row.report.progress }} /
                  {{ props.row.report.total }} /
                  <b-icon icon="alert-circle" size="is-small"></b-icon>
                  {{ props.row.report.errors }}
                </b-progress>

                <b-progress
                  v-else-if="props.row.report.is_stopped == true"
                  :value="props.row.report.progress"
                  :max="props.row.report.total"
                  type="is-warning"
                  show-value
                >
                  {{ props.row.report.progress }} /
                  {{ props.row.report.total }}
                </b-progress>
              </b-table-column>
            </b-table>
          </template>

          <empty v-else>
            <b-button
              tag="router-link"
              :to="{ name: 'new-release' }"
              icon-left="rocket"
              type="is-primary"
            >
              Запустить релиз
            </b-button>
          </empty>
        </div>

        <div class="column">
          <div class="block">
            <b-button
              type="is-warning"
              icon-left="delete"
              v-if="this.filters != null && this.sort == null"
              @click="reload"
              class="button-block"
            >
              Сбросить все фильтры
            </b-button>

            <b-button
              type="is-warning"
              icon-left="delete"
              v-if="this.sort != null && this.filters == null"
              @click="reload"
              class="button-block"
            >
              Сбросить сортировку
            </b-button>

            <b-button
              type="is-warning"
              icon-left="delete"
              v-if="this.sort != null && this.filters != null"
              @click="reload"
              class="button-block"
            >
              Сбросить все назначения
            </b-button>
          </div>

          <div class="block">
            <b-button
              tag="router-link"
              :to="{ name: 'new-release' }"
              icon-left="rocket"
              type="is-primary"
              class="button-block"
            >
              Запустить релиз
            </b-button>

            <div>
              <p class="text-right-block">
                <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                Вы можете посмотреть список компаний, на которые был произведен
                релиз, выбрав нужную позицию в списке.
              </p>
              <p class="text-right-block">
                Этот список обновляется автоматически.
              </p>
              <hr />
            </div>
          </div>

          <div class="block">
            <div v-if="isOpenTags == false">
              <b-field>
                <b-switch type="checkbox" v-model="isFullStatus">
                  Полный статус
                </b-switch>
              </b-field>

              <b-field>
                <b-switch type="checkbox" v-model="isFullDescription">
                  Полное описание
                </b-switch>
              </b-field>
              <hr />
            </div>

            <b-button
              icon-left="help"
              type="is-primary"
              @click="isOpenTags = !isOpenTags"
              class="button-block"
            >
              Статусы
            </b-button>
          </div>

          <b-message v-if="isOpenTags == true" type="is-success">
            <p>
              <b-tag type="is-light">
                <b-icon icon="dots-horizontal" size="is-small"></b-icon>
              </b-tag>
              В работе
            </p>
            <br />

            <p>
              <b-tag type="is-success">
                <b-icon icon="check-bold" size="is-small"></b-icon>
              </b-tag>
              Завершено
            </p>
            <br />

            <p>
              <b-tag type="is-danger">
                <b-icon icon="exclamation-thick" size="is-small"></b-icon>
              </b-tag>
              Завершено с ошибками
            </p>
            <br />

            <p>
              <b-tag type="is-warning">
                <b-icon icon="stop" size="is-small"></b-icon>
              </b-tag>
              Релиз принудительно остановлен
            </p>
            <br />

            <p>
              <b-tag type="is-warning">
                <b-icon icon="exclamation-thick" size="is-small"></b-icon>
              </b-tag>
              Есть приложения, которые необходимо залить в админ-панель
            </p>
            <br />

            <p>
              <b-tag type="is-info">
                <b-icon icon="help" size="is-small"></b-icon>
              </b-tag>
              Тестовый
            </p>
          </b-message>

          <p v-else class="text-right-block">
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
            Вы можете посмотреть подробную расшифровку статуса релиза наведя на
            соответствующую иконку или нажав кнопку "Статус".
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Empty from "@/components/Empty.vue";
import AboutReleasesList from "@/components/help/about/ReleasesList.vue";
import { toastHandler } from "@/plugins/toastHandler";

var moment = require("moment");

export default {
  components: {
    Empty,
    AboutReleasesList,
  },

  filters: {
    as_date(value) {
      if (!value) {
        return "";
      }
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },

  data: function () {
    const queryPage = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : 1;

    return {
      timerId: -1,
      total: 0,
      page: queryPage,
      filters: null,
      sort: null,
      isOpenTags: false,
      isFullStatus: true,
      isFullDescription: true,
      searchTimer: null,
    };
  },

  computed: {
    ...mapState({
      releases: (state) => state.releases.available,
    }),
  },

  watch: {
    isFullStatus(newStatus, oldStatus) {
      this.setStatus({ status: newStatus });
    },

    isFullDescription(newDescription, oldDescription) {
      this.setDescription({ description: newDescription });
    },
  },

  created() {
    this.getReleases(true);
    this.timerId = setInterval(() => this.getReleases(false), 1000 * 300);
    this.loadStatusFromStorage().then((res) => {
      this.isFullStatus = res;
    });
    this.loadDescriptionFromStorage().then((res) => {
      this.isFullDescription = res;
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === "releases") {
      if (to.query.page) {
        var queryPage = 1;
        queryPage = Number.parseInt(to.query.page);
      }
      this.page = queryPage;

      this.getReleases();
    }
    next();
  },

  beforeDestroy: function () {
    clearInterval(this.timerId);
  },

  methods: {
    ...mapActions(["startLoading", "stopLoading", "loadAll"]),
    ...mapActions("releases", {
      getAllReleases: "getAll",
      setStatus: "status",
      setDescription: "description",
      loadStatusFromStorage: "loadStatusFromStorage",
      loadDescriptionFromStorage: "loadDescriptionFromStorage",
    }),

    getReleases(showLoading) {
      if (showLoading) {
        this.startLoading();
      }

      this.getAllReleases({
        page: this.page,
        sort: this.sort,
        filters: this.filters,
      })
        .then((res) => {
          if (showLoading) {
            this.stopLoading();
            this.total = res.meta.total;
          }
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },

    reload() {
      this.startLoading();
      window.location.reload();
      this.stopLoading();
      toastHandler.apply(this, ["Все фильтры сброшены!", "is-primary"]);
    },

    setSort(sort) {
      if (this.sort?.key !== sort) {
        this.sort = { key: sort, value: -1 };
      } else if (this.sort.key === sort) {
        this.sort.value = -this.sort.value;
      }
    },

    onSort(sort) {
      this.setSort(sort);
      this.getAllReleases({ page: this.page, sort, filters: this.filters })
        .then((res) => {
          this.total = res.meta.total;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    doneTyping(filters) {
      this.filters = { ...filters };
      if (this.page === 1) {
        this.getAllReleases({
          page: this.page,
          sort: this.sort,
          filters: this.filters,
        })
          .then((res) => {
            this.total = res.meta.total;
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        this.onPageChange(1);
      }
    },

    onFilter(filters) {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.doneTyping(filters);
      }, 1000);
    },

    onReleaseClick(release) {
      this.$router.push({
        name: "release-details",
        params: { id: release.id },
      });
    },

    onPageChange(page) {
      this.$router.push({ name: "releases", query: { page: page } });
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  cursor: pointer !important;
}

.small-tag {
  margin-right: 5px;
}

.full-tag {
  margin-top: 5px;
}

.button-block {
  width: 100%;
}

.text-right-block {
  text-align: justify;
  width: 100%;
  margin-top: 10px;
}
</style>

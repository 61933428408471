import api from '@/api/api'

class LocalesApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('locales/')
  }
}

export default new LocalesApi()

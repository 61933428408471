<template>
  <div></div>
</template>

<script>
export default {
  created: function () {
    this.$store.dispatch('auth/logout')
    this.$router.push({ name: 'login' })
  }
}
</script>

<template>
  <div>
    <b-field>
      <b-checkbox-button
        v-model="selectedPlatforms"
        native-value="android"
        type="is-success"
      >
        <b-icon icon="android"></b-icon>
        Android
      </b-checkbox-button>

      <b-checkbox-button v-model="selectedPlatforms" native-value="ios">
        <b-icon icon="apple"></b-icon>
        iOS
      </b-checkbox-button>
    </b-field>

    <p>Я хочу:</p>

    <b-field>
      <b-radio-button v-model="buildReleaseVariant" native-value="build">
        <b-icon icon="cog"></b-icon>
        <span>Только собрать приложения</span>
      </b-radio-button>

      <b-radio-button v-model="buildReleaseVariant" native-value="release">
        <b-icon icon="rocket"></b-icon>
        <span>Собрать и выпустить</span>
      </b-radio-button>
    </b-field>

    <!-- <b-field>
      <b-checkbox-button v-model="selectedSteps" native-value="bundle">Bundle</b-checkbox-button>
      <b-checkbox-button v-model="selectedSteps" native-value="build">Build</b-checkbox-button>
      <b-checkbox-button v-model="selectedSteps" native-value="release">Release</b-checkbox-button>
    </b-field>

    <b-field>
      <b-checkbox @input="isTestReleaseChanged" :value="isTestRelease">
        Это тестовый релиз
        <b-tooltip
          label="При тестовом релизе не будете обновлены приложения в админке и маркете. Обновление можно будет скачать по ссылке"
          multilined
          position="is-bottom"
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </b-checkbox>
    </b-field>
    -->

    <b-field type="is-info">
      <template slot="label">
        Описание
        <b-tooltip
          label="Это опциональное описание будет отображено на списке последних релизов. Может использоваться, чтобы пометить этот релиз каким-либо образом"
          multilined
          position="is-bottom"
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>

      <b-input v-model="releaseMessage"></b-input>
    </b-field>
  </div>
</template>

<script>

export default {
  props: {
    platforms: Array,
    isTestRelease: Boolean,
    steps: Array,
    releaseVariant: String,
    message: String
  },
  data: function () {
    return {
      selectedPlatforms: this.platforms,
      selectedSteps: this.steps,
      releaseMessage: this.message,
      buildReleaseVariant: this.releaseVariant
    }
  },
  methods: {
    isTestReleaseChanged: function (checked) {
      this.$emit('update:isTestRelease', checked)
    }
  },
  watch: {
    selectedPlatforms: function () {
      this.$emit('update:platforms', this.selectedPlatforms)
    },
    selectedSteps: function () {
      this.$emit('update:steps', this.selectedSteps)
    },
    buildReleaseVariant: function () {
      this.$emit('update:releaseVariant', this.buildReleaseVariant)
    },
    releaseMessage: function () {
      this.$emit('update:message', this.releaseMessage)
    }
  }
}
</script>

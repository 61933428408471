<template>
  <base-about aboutType="versionsList">
    <span>
      <p>На этой странице перечислены доступные для релиза версии. Дата у версии указывает на дату, когда эта версия была создана разработчиками в репозитории.</p>
      <p>
        После нажатия на иконку
        <b>редактирования</b>
        <b-icon icon="pencil" type="is-dark"></b-icon>, откроется страница, на которой можно указать текст
        <b>"Что нового?"</b>.
      </p>
      <p>Список доступных версий обновляется автоматически каждые 24 часа. Чтобы обновить список принудтельно, используйте кнопку "Обновить версии"</p>
    </span>
  </base-about>
</template>

<script>
import BaseAbout from './BaseAbout.vue'

export default {
  components: {
    BaseAbout
  }
}
</script>

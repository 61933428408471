import api from '@/api/api'

class TagsApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('tags/')
  }

  add (title) {
    return this.api.post('tags/', { title })
  }

  delete (id) {
    return this.api.delete(`tags/${id}/`)
  }

  mark (companyId, tagId) {
    return this.api.post('tags/mark/', { company_id: companyId, tag_id: tagId })
  }

  unmark (companyId, tagId) {
    return this.api.post('tags/unmark/', { company_id: companyId, tag_id: tagId })
  }

  subscribe (id) {
    return this.api.post(`tags/${id}/subscribe/`)
  }

  unsubscribe (id) {
    return this.api.delete(`tags/${id}/subscribe/`)
  }
}

export default new TagsApi()

import api from '@/api/api'

class UsersApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('users/')
  }

  delete (id) {
    return this.api.delete(`users/deleteUser/${id}/`, {login: id})
  }
  changerole (id) {
    return this.api.post(`users/makeRole/${id}/`, {login: id})
  }


  register (login, password) {
    return this.api.post('users/registration/', { login: login, password: password })
  }
  changepassword (new_pas, confirm){
    return this.api.post('users/changepassword/', { new_pas: new_pas, confirm: confirm })
  }

  getSelf () {
    return this.api.get('users/self/')
  }

  saveSettings (notifyRelease, notifySuccessBuilds, notifyFailedBuilds) {
    return this.api.post('users/self/settings/', {
      notification_notify_release: notifyRelease,
      notification_notify_success_builds: notifySuccessBuilds,
      notification_notify_failed_builds: notifyFailedBuilds
    })
  }
}

export default new UsersApi()

<template>
  <section>
    <b-field>
      <b-upload v-model="dropFiles" multiple drag-drop>
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags">
      <span
        v-for="(file, index) in dropFiles"
        :key="index"
        class="tag is-primary"
      >
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index)"
        ></button>
      </span>
    </div>
    <b-field>
      <b-button
        type="is-primary"
        @click="getFiles()"
        :disabled="dropFiles.length <= 0"
      >
        Сохранить
      </b-button>
    </b-field>

    <b-table
      :data="value.certificates"
      hoverable
      v-if="value.certificates.length > 0"
    >
      <b-table-column label="Название компании" v-slot="props">
        <div>{{ props.row.name }}</div>
      </b-table-column>
      <b-table-column label=" " width="25px" v-slot="props">
        <a @click="selectCertificate(props.row)">
          <b-icon type="is-dark" icon="pencil"></b-icon>
        </a>
      </b-table-column>

      <b-table-column label="" width="25px" v-slot="props">
        <a @click="deleteFile(props.row.id)">
          <b-icon type="is-dark" icon="delete-forever"></b-icon>
        </a>
      </b-table-column>
    </b-table>

    <b-modal
      :active.sync="isEditCertificateModal"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <edit-certificate-modal
        :certificate="selectedCertificate"
        @changeName="editCertificate"
      />
    </b-modal>
  </section>
</template>

<script>
import EditCertificateModal from "@/components/servers/EditCertificateModal.vue";

export default {
  props: {
    value: Object,
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      dropFiles: [],
      server_id: this.value.id,
      isEditCertificateModal: false,
      selectedCertificate: null,
    };
  },
  methods: {
    deleteDropFile: function (index) {
      this.dropFiles.splice(index, 1);
    },
    getFiles: function () {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("servers/addCertificates", {
          files: this.dropFiles,
          serverId: this.server_id,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
          this.$emit("update-servers");
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });

      this.dropFiles = [];
    },
    deleteFile: function (id) {
      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("servers/deleteCertificate", {
          serverId: this.server_id,
          certificateId: id,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
          this.$emit("update-servers");
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
    selectCertificate: function (certificate) {
      this.selectedCertificate = certificate;
      this.isEditCertificateModal = true;
    },
    editCertificate: function (certificate) {
      this.isEditCertificateModal = false;
      this.selectedCertificate = null;

      this.$store.dispatch("startLoading");

      this.$store
        .dispatch("servers/editCertificate", {
          serverId: this.server_id,
          certificateId: certificate.id,
          name: certificate.name,
        })
        .then((res) => {
          this.$store.dispatch("stopLoading");
          this.$emit("update-servers");
        })
        .catch((error) => {
          this.$handleError(error);

          this.$store.dispatch("stopLoading");
        });
    },
  },
  watch: {
    dropFiles: function () {},
  },
  computed: {
    isDisabled: function () {
      return this.dropFiles.length === 0;
    },
  },
  components: {
    EditCertificateModal,
  },
};
</script>

<template>
  <section>
    <div class="container">
      <b-steps
        v-model="activeStep"
        :animated="true"
        size="is-medium"
        @input="updateStep"
        type="is-success"
      >
        <b-step-item label="Приложения" icon="cellphone">
          <new-release-select-companies
            :companies.sync="companies"
            :totalCompanies="totalCompanies"
            :currentPage.sync="companyFilters.page"
            @onPageChange="onPageChange"
            @onFilter="onFilter"
          />
        </b-step-item>

        <b-step-item
          label="Версия"
          :clickable="isVersionsStepEnabled"
          icon="arrange-send-backward"
        >
          <new-release-select-version
            :version.sync="version"
            :localizations="initialVersionLocalization"
          />
        </b-step-item>

        <b-step-item
          label="Платформы"
          :clickable="isPlatformsStepEnabled"
          icon="atom-variant"
        >
          <new-release-select-platforms
            :platforms.sync="platforms"
            :steps.sync="buildSteps"
            :message.sync="message"
            :releaseVariant.sync="releaseVariant"
            :isTestRelease.sync="isTestRelease"
          />
        </b-step-item>

        <b-step-item
          label="Подтверждение"
          icon="check-bold"
          :clickable="isConfirmationStepEnabled"
        >
          <new-release-confirmation
            :companies="companies"
            :version="version"
            :platforms="platforms"
            :steps="buildSteps"
            :releaseVariant="releaseVariant"
            :message="message"
            :isTestRelease="isTestRelease"
          />
        </b-step-item>

        <b-step-item label="Отчет" icon="chat-processing" disabled>
          <new-release-report />
        </b-step-item>

        <template slot="navigation" slot-scope="{ previous, next }">
          <div class="release-nav">
            <div class="container">
              <b-button
                icon-left="arrow-left"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
                type="is-danger"
                class="button-footer"
              >
                Назад
              </b-button>

              <b-button
                icon-right="arrow-right"
                :disabled="isNextStepDisabled"
                @click.prevent="next.action"
                v-if="activeStep < 3"
                type="is-primary"
              >
                Вперед
              </b-button>

              <b-button
                icon-right="rocket"
                :disabled="next.disabled"
                @click.prevent="release()"
                v-else-if="activeStep == 3"
                type="is-primary"
              >
                Запустить релиз
              </b-button>

              <div
                class="check"
                v-if="activeStep == 3 && releaseVariant === 'release'"
              >
                <b-icon
                  icon="check"
                  type="is-success"
                  v-if="confirmation && confirmation.can_release"
                ></b-icon>
                <b-icon icon="close" type="is-danger" v-else></b-icon>
                <b-button
                  icon-right="progress-check"
                  @click.prevent="getConfirmation()"
                  class="check"
                >
                  Проверить настройки релиза
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </b-steps>

      <b-modal
        :active.sync="isConfirmationWarningActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <new-release-confirmation-warning
          :confirmation="confirmation"
          :version="version"
          @refresh="getConfirmation"
          @edit-version="setStep(1)"
          @close="isConfirmationWarningActive = false"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import NewReleaseSelectCompanies from "@/components/release/NewReleaseSelectCompanies.vue";
import NewReleaseSelectVersion from "@/components/release/NewReleaseSelectVersion.vue";
import NewReleaseSelectPlatforms from "@/components/release/NewReleaseSelectPlatforms.vue";
import NewReleaseConfirmation from "@/components/release/NewReleaseConfirmation.vue";
import NewReleaseConfirmationWarning from "@/components/release/NewReleaseConfirmationWarning.vue";
import NewReleaseReport from "@/components/release/NewReleaseReport.vue";

export default {
  components: {
    NewReleaseSelectCompanies,
    NewReleaseSelectVersion,
    NewReleaseSelectPlatforms,
    NewReleaseConfirmation,
    NewReleaseConfirmationWarning,
    NewReleaseReport,
  },

  props: {
    initialCompanies: {
      type: Array,
      default: function () {
        return [];
      },
    },
    initialVersion: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initialVersionLocalization: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initialPlatforms: {
      type: Array,
      default: function () {
        return [];
      },
    },
    initialReleaseVariant: {
      type: String,
      default: "",
    },
    initialStep: {
      type: Number,
      default: 0,
    },
    initialMessage: {
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      companies: this.initialCompanies,
      version: this.initialVersion,
      platforms: this.initialPlatforms,
      buildSteps: ["bundle", "build", "release"],
      releaseVariant: this.initialReleaseVariant,
      activeStep: this.initialStep,
      message: this.initialMessage,
      isTestRelease: false,
      confirmation: null,
      isConfirmationWarningActive: false,
      companyFilters: {
        query: "",
        servers_ids: [],
        tags_ids: [],
        page: 1,
        per_page: 50,
        filters: {},
      },
      totalCompanies: 0,
    };
  },

  computed: {
    ...mapState({
      allCompanies: (state) => state.companies.available,
      allVersions: (state) => state.versions.available,
    }),
    isVersionsStepEnabled: function () {
      return this.companies.length > 0;
    },
    isPlatformsStepEnabled: function () {
      return !!this.version.name;
    },
    isConfirmationStepEnabled: function () {
      return this.platforms.length > 0 && !!this.releaseVariant;
    },
    isNextStepDisabled: function () {
      if (this.activeStep === 0) {
        return !this.isVersionsStepEnabled;
      }
      if (this.activeStep === 1) {
        return !this.isPlatformsStepEnabled;
      }
      if (this.activeStep === 2) {
        return !this.isConfirmationStepEnabled;
      }
      return true;
    },
  },

  async created() {
    await this.findAllData();
    await this.getCompanies();
  },

  methods: {
    ...mapActions(["startLoading", "stopLoading", "loadAllReleaseData"]),
    ...mapActions("companies", ["getCompaniesByFilters"]),

    async getCompanies() {
      this.startLoading();

      await this.getCompaniesByFilters(this.companyFilters)
        .then((res) => {
          this.totalCompanies = res.meta.total;
        })
        .catch((error) => {
          this.$handleError(error);
        });

      this.stopLoading();
    },

    onFilter(filters) {
      Object.assign(this.companyFilters.filters, filters);
      this.companyFilters.page = 1;
      this.getCompanies();
    },

    findAllData() {
      this.loadAllReleaseData(this.companyFilters).catch((error) => {
        this.$handleError(error);
        this.stopLoading();
      });
    },

    async onPageChange() {
      this.startLoading();

      await this.getCompaniesByFilters(this.companyFilters)
        .then((res) => {
          this.totalCompanies = res.meta.total;
        })
        .catch((error) => {
          this.$handleError(error);
        });

      this.stopLoading();
    },

    getConfirmation: function () {
      this.startLoading();

      this.$store
        .dispatch("releases/confirmation", {
          companies: this.companies,
          version: this.version,
          platforms: this.platforms,
          releaseMode: this.releaseVariant,
          message: this.message,
          isTestRelease: this.isTestRelease,
          localizations: this.version.localizations,
        })
        .then((res) => {
          this.stopLoading();

          this.confirmation = res;
          this.isConfirmationWarningActive = !this.confirmation.can_release;
        })
        .catch((error) => {
          this.$handleError(error);

          this.stopLoading();
        });
    },

    release: function () {
      let mode = "build";
      if (this.releaseVariant === "release") {
        mode = "release";
      }

      this.startLoading();

      this.$store
        .dispatch("releases/new", {
          companies: this.companies,
          version: this.version,
          platforms: this.platforms,
          mode: mode,
          message: this.message,
          isTestRelease: this.isTestRelease,
          localizations: this.version.localizations,
        })
        .then((res) => {
          this.stopLoading();
          this.$router.push({
            name: "release-details",
            params: { id: res.release.id },
          });
        })
        .catch((error) => {
          this.$handleError(error);

          this.stopLoading();
        });
    },

    updateStep: function () {
      if (this.activeStep === 3 && this.releaseVariant === "release") {
        // we are on confirmation step
        this.getConfirmation();
      }
    },
    setStep: function (newStep) {
      this.activeStep = newStep;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.release-nav {
  height: 40px; /* Adjust the height as per your preference */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white; /* Set the background color as needed */
  z-index: 9999; /* Adjust the z-index if necessary */

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .check {
    float: right;

    .icon {
      margin-top: 8px;
    }
  }
}

.step-content {
  margin-bottom: 64px;
}
.button-footer {
  margin-right: 10px;
}
</style>

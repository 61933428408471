<template>
  <div>
    <b-field type="is-info">
      <template slot="label">
        Версия
        <b-tooltip
          label="Начните вводить название версии, потом выберите нужную версию из списка."
          multilined
          position="is-bottom"
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>

      <b-autocomplete
        :data="suggestedVersions"
        placeholder="7.0.0"
        field="name"
        @typing="refreshVersionsOnType"
        @select="onSuggestedVersionSelect"
        ref="autocomplete"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              {{ props.option.name }}
              <span>
                <b-icon
                  icon="apple"
                  v-if="props.option.is_ios_available"
                ></b-icon>

                <b-icon
                  icon="android"
                  type="is-success"
                  v-if="props.option.is_android_available"
                ></b-icon>
              </span>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>

    <version-editor
      v-if="selectedVersion && selectedVersion.localizations"
      :localizations="selectedVersion.localizations"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VersionEditor from '@/components/VersionEditor'

export default {
  props: {
    version: Object,
    localizations: Object
  },
  data: function () {
    return {
      suggestedVersions: [],
      selectedVersion: {}
    }
  },
  mounted: function () {
    this.selectedVersion = this.allVersions.find(v => v.id === this.version.id)
    if (this.selectedVersion) {
      this.$refs.autocomplete.setSelected(this.selectedVersion)
      if (this.localizations) {
        this.selectedVersion.localizations = this.localizations
      }
    }
  },
  methods: {
    refreshVersionsOnType: function (query) {
      if (!query.length) {
        this.suggestedVersions = []
        return
      }

      this.suggestedVersions = this.allVersions.filter(
        v => {
          var regex = new RegExp(query, 'i')
          var foundByName = v.name.search(regex) >= 0
          return foundByName
        }
      )
    },

    onSuggestedVersionSelect: function (version) {
      this.selectedVersion = version

      this.$emit('update:version', this.selectedVersion)
    }
  },
  computed: {
    ...mapState({
      allVersions: state => state.versions.available
    })
  },
  components: {
    VersionEditor
  }
}
</script>

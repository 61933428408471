import axios from "axios";
import config from "../../config";

class Api {
  instance = null;

  defaultConfig = {
    baseURL: `${config.rootUrl}/`,
  };

  constructor() {
    this.instance = axios.create(this.defaultConfig);
  }

  setDefaultInstanceParams(token) {
    if (token) {
      this.instance.defaults.headers.common["Authorization"] = `${token}`;
    } else {
      delete this.instance.defaults.headers.common["Authorization"];
    }
  }

  get(url, requestParams) {
    return this.instance.get(url, {
      params: requestParams,
    });
  }

  download(url, requestParams) {
    return this.instance.get(url, {
      responseType: "blob",
      params: requestParams,
    });
  }

  post(url, data, requestParams) {
    return this.instance.post(url, data, { params: requestParams });
  }

  postFile(url, fieldname, file, fields, requestParams) {
    var data = new FormData();
    data.append(fieldname, file, file.name);
    fields.forEach((field) => {
      data.append(field.name, field.value);
    });

    return this.instance.request({
      method: "post",
      url: url,
      data: data,
      params: requestParams,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  postFiles(url, files, fields, requestParams) {
    var data = new FormData();

    files.forEach((file, i) => {
      data.append(`files[${i}]`, file);
    });

    fields.forEach((field) => {
      data.append(field.name, field.value);
    });

    return this.instance.request({
      method: "post",
      url: url,
      data: data,
      params: requestParams,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  put(url, data, requestParams) {
    return this.instance.put(url, data, { params: requestParams });
  }

  delete(url, data, requestParams) {
    return this.instance.delete(url, {
      data,
      params: requestParams,
    });
  }
}

export { Api };

export default new Api();

import FirebaseApi from '@/api/firebase'

const state = {
  projects: null
}

const getters = {
}

const mutations = {
  storeProjects (state, projects) {
    state.projects = projects
  },
  addProject (state, project) {
    state.projects.push(project)
  },
  addAndroidApp (state, { app, projectId }) {
    state.projects = state.projects.map(p => {
      if (p.id === projectId) {
        p.apps.android.push(app)
      }
      return p
    })
  },
  addIosApp (state, { app, projectId }) {
    state.projects = state.projects.map(p => {
      if (p.id === projectId) {
        p.apps.ios.push(app)
      }
      return p
    })
  }
}
const actions = {
  getProjects ({ commit }) {
    return FirebaseApi.getAll().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeProjects', res.data.projects)
      return Promise.resolve()
    })
  },
  addProject ({ commit }, { creds }) {
    return FirebaseApi.addProject(creds).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      commit('addProject', res.data.project)
      return Promise.resolve()
    })
  },
  addAndroidApp ({ commit }, { projectId, androidPackage }) {
    commit('addAndroidApp', { app: { id: -1, package: androidPackage }, projectId })

    // return FirebaseApi.addAndroidApp(projectId, androidPackage).then(res => {
    //   if ('error' in res.data) {
    //     return Promise.reject(res.data.error)
    //   }
    //   commit('addAndroidApp', { app: res.data.app, projectId })
    //   return Promise.resolve()
    // })
  },
  addIosApp ({ commit }, { projectId, bundleId }) {
    return FirebaseApi.addIosApp(projectId, bundleId).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      commit('addIosApp', { app: res.data.app, projectId })
      return Promise.resolve()
    })
  },
  refresh () {
    return FirebaseApi.refresh().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      return Promise.resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import api from '@/api/api'

class FirebaseApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('firebase/')
  }

  addProject (creds) {
    return this.api.postFile('firebase/', 'creds', creds, [])
  }

  addAndroidApp (projectId, androidPackage) {
    return this.api.post(`firebase/${projectId}/app/android/`, { android_package: androidPackage })
  }

  addIosApp (projectId, bundleId) {
    return this.api.post(`firebase/${projectId}/app/ios/`, { bundle_id: bundleId })
  }

  refresh () {
    return this.api.post('firebase/refresh/')
  }
}

export default new FirebaseApi()

// var localUrl = 'https://api.dev-rs.e-queo.xyz'
var localUrl = "http://127.0.0.1:5000";
var remoteUrl = "##URL_BACKEND##";
// 'http://212.109.219.84:5002'

var config = {
  rootUrl: localUrl,
};

if (process.env.NODE_ENV === "production") {
  config.rootUrl = remoteUrl;
}

export default config;

<template>
  <section>
    <about-versions-list />
    <div class="container">
      <div class="columns">
        <div class="column is-four-fifths">
          <template v-if="versions != null && versions.length > 0">
            <b-table :data="versions" hoverable>
                <b-table-column field="name" label="Версия" v-slot="props">
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column label="Доступность"  v-slot="props">
                  <span>
                    <p>
                      <b-icon
                        icon="apple"
                        v-if="props.row.is_ios_available"
                      ></b-icon>
                      {{ props.row.ios_created_at | as_date }}
                    </p>

                    <p>
                      <b-icon
                        icon="android"
                        type="is-success"
                        v-if="props.row.is_android_available"
                      ></b-icon>
                      {{ props.row.android_created_at | as_date }}
                    </p>
                  </span>
                </b-table-column>

                <b-table-column label="Что нового"  v-slot="props">
                  <span>
                    {{
                      props.row.localizations["android"]["market"]["ru"]
                        | truncate(80)
                    }}
                  </span>
                </b-table-column>

                <b-table-column v-slot="props">
                  <router-link
                    :to="{ name: 'edit-version', params: { id: props.row.id } }"
                  >
                    <b-button icon="pencil"><b-icon type="is-dark" icon="pencil"></b-icon></b-button>
                  </router-link>
                </b-table-column>
            </b-table>
          </template>

          <empty v-else>
            <b-button @click="refreshVersions()" icon-left="refresh" type="is-primary">
              Обновить список версий
            </b-button>
          </empty>
        </div>

        <div class="column">
          <div class="block">
            <b-button @click="refreshVersions()" icon-left="refresh" type="is-primary" style="text-align: justify;width: 100%">
              Обновить список версий
            </b-button>
          </div>
          <div class="block">
            <p class="what-is-new">
              <b-icon icon="help-circle-outline" size="is-small"></b-icon>
              Вы можете обновить сообщение "Что нового?", выбрав нужную компанию
              в списке.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.what-is-new {
  text-align: justify;
  width: 100%;
}
</style>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

import Empty from '@/components/Empty.vue'
import AboutVersionsList from '@/components/help/about/VersionsList.vue'

var moment = require('moment')

export default {
  created: function () {
    if (this.versions == null || this.versions.length === 0) {
      this.getVersions()
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    getVersions: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('versions/getAll')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    refreshVersions: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('versions/refresh')
        .then(_ => {
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Список версий обновлен!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    ...mapState({
      versions: state => state.versions.available
    })
  },
  filters: {
    truncate (value, length) {
      if (!value) {
        return ''
      }
      return value.length > length
        ? value.substr(0, length) + '...'
        : value
    },
    as_date (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    }
  },
  components: {
    Empty,
    AboutVersionsList
  }
}
</script>

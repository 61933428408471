<template>
  <section>
    <div class="container" v-if="company">
      <b-taglist>
        <b-button
          tag="router-link"
          :to="{
            name: 'company-releases',
            params: { id: company.id },
          }"
          icon-left="history"
          type="is-primary"
          size="is-small"
          class="button-taglist"
          >
            История релизов
        </b-button>

        <b-button
          @click="isTagAddPanelOpened = true"
          icon-left="tag"
          type="is-primary"
          size="is-small"
          class="button-taglist"
          outlined>
            Добавить тег
        </b-button>

        <template v-for="tag in company.tags">
          <b-tag
            v-if="!tag.is_system_tag"
            closable
            :key="tag.id"
            @close="unmark(tag)"
            :type="'is-' + tag.color"
          >
            {{ tag.title }}
          </b-tag>
          <b-tag v-else :key="tag.id" :type="'is-' + tag.color">
            {{ tag.title }}
          </b-tag>
        </template>
      </b-taglist>

      <p class="is-size-3">
        {{ company.name }}
      </p>

      <label class="label" slot="label">
        Название приложения
        <b-tooltip
          label="Это название будет отображаться на устройстве под иконкой приложения"
          multilined
          position="is-bottom"
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </label>

      <b-field grouped type="is-info">
        <b-input v-model="appName" expanded></b-input>
        <b-checkbox v-model="isEnAppNameDifferent">
          Другое имя для английской локали
        </b-checkbox>
      </b-field>

      <b-field v-if="isEnAppNameDifferent" type="is-info">
        <b-input v-model="appNameEn" expanded></b-input>
        <template slot="label">
          Название приложения, английская локаль
          <b-tooltip
            label="Это название будет отображаться на устройстве под иконкой приложения на английской локали"
            multilined
            position="is-bottom"
          >
            <b-icon icon="help-circle-outline" size="is-small"></b-icon>
          </b-tooltip>
        </template>
      </b-field>

      <!-- <b-field :addons="false">
      <template slot="label">
        Иконка приложения
        <b-tooltip
          label="Эта иконка приложения будет отображаться на устройстве пользователя"
          multilined
          position="is-bottom"
        >
          <b-icon icon="help-circle-outline" size="is-small"></b-icon>
        </b-tooltip>
      </template>

      <b-field grouped>
        <b-upload v-model="image" :native="true" accept="image/*" @input="onImageUpdated">
          <a class="button is-primary">
            <b-icon icon="upload"></b-icon>
            <span>Иконка приложения</span>
          </a>
        </b-upload>

        <figure class="image is-48x48">
          <img :src="image_url" v-if="image_url" />
        </figure>
      </b-field>
      </b-field>-->

      <b-tabs v-if="Object.keys(mobileParams).length !== 0" type="is-boxed">
        <b-tab-item :key="0"
          label="Android"
          icon="android"
          >
            <edit-android v-model="mobileParams" />
        </b-tab-item>

        <b-tab-item :key="1"
          label="iOS"
          icon="apple"
          >
            <edit-ios v-model="mobileParams" />
        </b-tab-item>
      </b-tabs>

      <b-field grouped>
        <b-field>
          <b-tooltip
            label="При нажатии на эту кнопку, все материалы (иконка приложения, параметризация, конфигурация Firebase и конфигурация сборки) будут собраны в один архив и доступны на S3."
            multilined
          >
            <b-button type="is-primary" @click="editCompany()">
              Сохранить
            </b-button>
          </b-tooltip>
        </b-field>

        <b-field>
          <b-button @click="unarchive()" v-if="isArchived" type="is-danger">
            Вернуть из архива
          </b-button>
          <b-button @click="archive()" v-else type="is-danger">Архивировать</b-button>
        </b-field>
      </b-field>

      <b-modal
        :active.sync="isTagAddPanelOpened"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        ref="tagsModal"
      >
        <div class="modal-card modal-add-tag">
          <header class="modal-card-head">
            <p class="modal-card-title">Добавить теги</p>
          </header>
          <section class="modal-card-body">
            <div
              v-for="tag in notAddedTags"
              :key="tag.id"
            >
              <b-button
                @click="mark(tag)"
                icon-left="plus"
                size="is-small"
              ></b-button>
              <b-tag :type="'is-' + tag.color">{{ tag.title }}</b-tag>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button"
              type="button"
              @click="$refs.tagsModal.close()"
            >
              Закрыть
            </button>
          </footer>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.button-taglist {
  vertical-align: text-bottom;
  margin-right: 10px;
}
.modal-add-tag {
  width: auto;
}
.modal-add-tag section {
  width: 640px;
}
.modal-add-tag section div {
  padding-bottom: 6px;
}
.modal-add-tag section button {
  margin-right: 6px;
}
</style>

<script>
import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'
import EditAndroid from '@/components/companies/EditAndroid.vue'
import EditIos from '@/components/companies/EditIos.vue'

export default {
  created: function () {
    this.refreshCompany()
  },
  data: function () {
    return {
      mobileParams: {},
      appName: '',
      appNameEn: '',
      isArchived: false,
      isEnAppNameDifferent: false,
      isTagAddPanelOpened: false
    }
  },
  methods: {
    refreshCompany: function () {
      this.id = this.$route.params.id
      var loadSomething = [
        this.$store.dispatch('companies/getCompany', {
          id: this.id
        }),
        this.$store.dispatch('firebase/getProjects'),
        this.$store.dispatch('marketAccounts/getAccounts'),
        this.$store.dispatch('tags/getAll')
      ]

      Promise.all(loadSomething)
        .then(res => {
          if (!this.company) {
            this.$router.push('/404')
            return
          }
          this.refreshModel()
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    refreshModel: function () {
      this.id = this.$route.params.id
      this.appName = this.company.app_name
      this.appNameEn = this.company.app_name_en
      this.isEnAppNameDifferent = !(this.company.app_name_en == null || this.company.app_name_en === this.company.app_name)
      this.isArchived = this.company.is_archived
      this.mobileParams = this.company
    },
    editCompany: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/edit',
        {
          id: this.company.id,
          appName: this.appName,
          appNameEn: this.isEnAppNameDifferent ? this.appNameEn : null,
          company: this.mobileParams
        })
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, [`Компания "${this.company.name}" сохранена!`, 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    onImageUpdated: function (image) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/editImage',
        {
          id: this.id,
          image: image
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    archive: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/archive', { id: this.id })
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, [`Компания "${this.company.name}" заархивирована!`, 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    unarchive: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/unarchive', { id: this.id })
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Компания разархивирована!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    mark: function (tag) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('tags/mark', { tagId: tag.id, companyId: this.company.id })
        .then(res => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    unmark: function (tag) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('tags/unmark', { tagId: tag.id, companyId: this.company.id })
        .then(res => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    ...mapState({
      company: state => state.companies.company,
      tags: state => state.tags.available
    }),
    notAddedTags: function () {
      return this.tags.filter(t => !this.company.tags.some(ct => ct.id === t.id) && !t.is_system_tag)
    }
  },
  components: {
    EditAndroid,
    EditIos
  }
}
</script>

import UsersApi from '@/api/users'

const state = {
  users: null
}

const getters = {
}

const mutations = {
  storeUsers (state, users) {
    state.users = users
  }
}

const actions = {
  getUsers ({ commit }) {
    return UsersApi.getAll().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('storeUsers', res.data.users)
      return Promise.resolve()
    })
  },
  deleteUser ({ dispatch }, { id }) {
    return UsersApi.delete(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      dispatch('getUsers')
      return res
    })
  },
  makeRole ({ dispatch }, { id }) {
    return UsersApi.changerole(id).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      dispatch('getUsers')
      return res
    })
  },
  register ({ dispatch }, { login, password }) {
    return UsersApi.register(login, password).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      dispatch('getUsers')

      return res
    })
  },
  changepassword ({ dispatch }, {  new_pas, confirm }) {
    return UsersApi.changepassword( new_pas, confirm).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }
      dispatch('getUsers')
      return res
    })
  },
  getSelf ({ commit }) {
    return UsersApi.getSelf().then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('saveUser', res.data.user, { root: true })

      return Promise.resolve()
    })
  },
  saveSettings ({ commit }, { notifyRelease, notifySuccessBuilds, notifyFailedBuilds }) {
    return UsersApi.saveSettings(notifyRelease, notifySuccessBuilds, notifyFailedBuilds).then(res => {
      if ('error' in res.data) {
        return Promise.reject(res.data.error)
      }

      commit('saveUser', res.data.user, { root: true })

      return Promise.resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

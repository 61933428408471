<template>
  <div class="modal-card apple-account-block">
    <header class="modal-card-head">
      <p class="modal-card-title">Добавить учетную запись Apple App store</p>
    </header>
    <section class="modal-card-body">
      <b-field type="is-info">
        <b-input v-model="accountName" placeholder="Название аккаунта"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamId" placeholder="Sign team ID"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamName" placeholder="Sign team name"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="signTeamUsername" placeholder="Sign team username"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneTeamId" placeholder="Fastlane team ID"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneTeamName" placeholder="Fastlane team name"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="fastlaneResignSigningIdentity" placeholder="Fastlane Signing Identity"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="provisionProfile" placeholder="Provision Profile"></b-input>
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Отменить</button>
      <button class="button is-primary" @click="create()">Добавить</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.apple-account-block {
  width: auto;
}
</style>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
  },
  data: function () {
    return {
      accountName: '',
      signTeamId: '',
      signTeamName: '',
      signTeamUsername: '',
      fastlaneTeamId: '',
      fastlaneTeamName: '',
      fastlaneResignSigningIdentity: '',
      provisionProfile: ''
    }
  },
  methods: {
    create: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/addIosAccount',
        {
          name: this.accountName,
          signTeamID: this.signTeamId,
          signTeamName: this.signTeamName,
          signTeamUsername: this.signTeamUsername,
          flTeamID: this.fastlaneTeamId,
          flTeamName: this.fastlaneTeamName,
          flIdentity: this.fastlaneResignSigningIdentity,
          provision: this.provisionProfile
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          this.$parent.close()
          this.$emit('create', this.selectedProject)
          toastHandler.apply(this, ['Учетная запись Apple App Store создана!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

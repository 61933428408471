<template>
  <base-about aboutType="releasesList">
    <span>
      <p>На этой странице перечислены недавние релизы. У каждого релиза есть описание и статус.</p>
      <p>Нажав на элемент списка, можно посмотреть детализированный отчет о прошедшем релизе.</p>
      <p>Список и детали списка обновляются автоматически каждую минуту.</p>
    </span>
  </base-about>
</template>

<script>
import BaseAbout from './BaseAbout.vue'

export default {
  name: 'ReleasesList',
  components: {
    BaseAbout
  }
}
</script>

const state = {
  isDisabled: false,
  about: [
    {
      type: 'serversList',
      enabled: true
    },
    {
      type: 'versionsList',
      enabled: true
    },
    {
      type: 'releasesList',
      enabled: true
    }
  ]
}

const getters = {
  isAboutEnabled: (state) => (type) => {
    if (state.isDisabled) return false
    var item = state.about.find(i => i.type === type)
    return item.enabled
  }
}

const mutations = {
  loadState (state, newState) {
    state.isDisabled = newState.isDisabled
    state.about = state.about.map(i => {
      var newItem = newState.about.find(ni => ni.type === i.type)
      if (newItem == null) {
        return i
      }
      return newItem
    })
  },
  disableAll (state) {
    state.isDisabled = true
  },
  disableAbout (state, type) {
    state.about = state.about.map(i => {
      if (i.type === type) {
        i.enabled = false
        return {
          type: type,
          enabled: false
        }
      }
      return i
    })
  }
}
const actions = {
  disableAll ({ commit, state }) {
    commit('disableAll')

    localStorage.setItem('onboarding', JSON.stringify(state))
  },
  disableAbout ({ commit, state }, { type }) {
    commit('disableAbout', type)

    localStorage.setItem('onboarding', JSON.stringify(state))
  },
  loadFromStorage ({ commit }) {
    var t = localStorage.getItem('onboarding')
    if (t !== null && t !== undefined && t !== 'null') {
      commit('loadState', JSON.parse(t))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

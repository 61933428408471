<template>
  <div class="modal-card google-account-block">
    <header class="modal-card-head">
      <p class="modal-card-title">Добавить учетную запись Google Play</p>
    </header>
    <section class="modal-card-body">
      <b-field type="is-info">
        <b-input v-model="accountName" placeholder="Название аккаунта"></b-input>
      </b-field>

      <b-field type="is-primary">
        <b-input v-model="testPackage" placeholder="Тестовое приложение"></b-input>
      </b-field>

      <b-field class="file">
        <b-upload v-model="credsFile" @input="onCredsUpdated" accept="application/json">
          <a class="button is-info">
            <b-icon icon="upload"></b-icon>
            <span>Добавить учетную запись разработчика Google Play</span>
          </a>
        </b-upload>

        <span class="file-name" v-if="credsFile">{{ credsFile.name }}</span>
      </b-field>

      <p>
        Загрузите файл credentials.json. Его можно получить в админ-панели Google Play, в секции Developer Account -> Api Access -> Service Accounts -> Create service account.
        <br />При переходе в Google Developer Console необходимо выбрать роль для сервисного аккаунта
        <b>"Editor"</b>. Там же нужно создать json ключ для доступа.
        <br />После этого в консоли Google Play необходимо предоставить разрешения для доступа к приложениям.
      </p>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Отменить</button>
      <button class="button is-primary" @click="create()">Добавить</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.google-account-block {
  width: auto;
}
</style>

<script>
import { toastHandler } from '@/plugins/toastHandler'

export default {
  props: {
  },
  data: function () {
    return {
      accountName: '',
      credsFile: null,
      testPackage: ''
    }
  },
  methods: {
    onCredsUpdated: function (creds) {
      this.credsFile = creds
    },
    create: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('marketAccounts/addAndroidAccount',
        {
          name: this.accountName,
          creds: this.credsFile,
          testPackage: this.testPackage
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          this.$parent.close()
          this.$emit('create', this.selectedProject)
          toastHandler.apply(this, ['Учетная запись Google Play добавлена!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

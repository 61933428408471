
export function toastHandler(message, type) {
  // this.$buefy.toast.open({
  this.$buefy.toast.open({
    duration: type === 'is-danger' ? 5000 : 3500,
    position: 'is-bottom',
    message: message,
    type: type
  })
}

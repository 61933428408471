<template>
  <section>
    <b-button @click="showAddNewUserModal()" icon-left="plus" type="is-primary">Добавить пользователя</b-button>
    <template v-if="users != null && users.length > 0">
    <b-table :data="users">
        <b-table-column label="Имя" v-slot="props">
          {{ props.row.login }}
            <b-tag v-if="props.row.role == 'admin'" type="is-danger">Администратор</b-tag>
            <b-tag v-if="props.row.role == 'release-manager'" type="is-primary">Релиз-менеджер</b-tag>
            <b-tag v-if="props.row.role == 'viewer'" type="is-light">Только просмотр</b-tag>
              <div class="buttons-block">
                <b-button  @click="deleteUser(props.row)">
                  <b-icon icon="delete"></b-icon>
                </b-button>
                <b-button  @click="makeRole(props.row)">
                  <b-icon icon="crown"></b-icon>
                </b-button>
              </div>
          </b-table-column>
    </b-table>
    </template>
    <p v-else class="is-size-3 has-text-centered user-no-list">Пользователи не добавлены</p>
    <b-modal
      :active.sync="isAddNewUserModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <add-new-user-modal @create="getUsers" />
    </b-modal>
  </section>
</template>

<style lang="scss" scoped>
.user-no-list {
  margin-top: 20px;
}
.buttons-block {
  position: relative;
  right: -80%;
}
.buttons-block button {
  margin-right: 20px;
}
</style>

<script>
import { mapState } from 'vuex'
import AddNewUserModal from '@/views/admin/AddNewUserModal.vue'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  created: function () {
    this.getUsers()
  },
  data: function () {
    return {
      isAddNewUserModalActive: false,
      isSelectRoleactive: false,
    }
  },
  methods: {
    getUsers: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('users/getUsers')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    showAddNewUserModal () {
      this.isAddNewUserModalActive = true
    },
    makeRole: function (user) {
      if (confirm('Назначить пользователю права администратора?')) {
        this.$store.dispatch('startLoading')

        this.$store.dispatch('users/makeRole', { id: user.login })
          .then(_ => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, [`Роль изменена для пользователя ${user.login}`, 'is-success'])
          })
          .catch(error => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Не хватает прав, обратитесь к администратору', 'is-danger'])

          })
       }
    },
    deleteUser: function (user) {
      if (confirm('Вы уверены что хотите удалить пользователя ?')) {
        this.$store.dispatch('startLoading')

        this.$store.dispatch('users/deleteUser', { id: user.login })
          .then(_ => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Пользователь удален', 'is-success'])
          })
          .catch(error => {
            this.$store.dispatch('stopLoading')
            toastHandler.apply(this, ['Не хватает прав, обратитесь к администратору', 'is-danger'])

          })
       }
    },
    getError: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('companies/error')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    }
    // onReleaseClick: function (release) {
    //   this.$router.push({ name: 'release-details', params: { id: release.id } })
    // }
  },
  computed: {
    ...mapState({
      users: state => state.users.users
    })
  },
  components: {
    AddNewUserModal
  }
}
</script>

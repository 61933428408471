import api from '@/api/api'

class VersionsApi {
  constructor() {
    this.api = api
  }

  getAll () {
    return this.api.get('versions/')
  }

  edit (id, localizations) {
    return this.api.post(`versions/${id}/`, {
      localizations
    })
  }

  refresh () {
    return this.api.post('versions/refresh/')
  }

  getLocalizationForRelease (id) {
    return this.api.get(`versions/release-localization/${id}/`)
  }
}

export default new VersionsApi()

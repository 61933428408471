<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">В этом релизе обнаружены следующие ошибки</p>
    </header>
    <section class="modal-card-body">
      <div v-if="companiesWithErrors">
        <div
          class="company"
          v-for="company in companiesWithErrors"
          :key="company.id"
        >
          <b-button
            tag="router-link"
            :to="{ name: 'edit-company', params: { id: company.id } }"
            icon-left="pencil"
            type="is-small"
            class="edit-btn"
            target="_blank"
          >
            Перейти к настройкам компании
          </b-button>

          <p>{{ company.name }}</p>

          <b-taglist>
            <b-tag
              v-for="error in company.android"
              :key="error.id"
              :type="'is-danger'"
            >
              Android: {{ error.text }}
            </b-tag>
            <b-tag
              v-for="error in company.ios"
              :key="error.id"
              :type="'is-danger'"
            >
              iOS: {{ error.text }}
            </b-tag>
          </b-taglist>
        </div>
      </div>

      <div v-if="hasLocalizationErrors" class="localizations">
        <p>
          <b>Незаполнены сообщения "Что нового?":</b>
        </p>

        <div
          class="platforms"
          v-for="(scopes, platform) in localizationErrors"
          :key="platform"
        >
          <p>
            Для платформы
            <b>{{ platform }}</b>
            :
          </p>

          <p v-for="(loc, scope) in scopes" :key="scope">
            <span v-if="scope == 'admin'">Админ-панель:</span>
            <span v-if="scope == 'market'">Маркет:</span>
            <i class="locale" v-for="l in loc" :key="l">{{ l }}</i>
          </p>
        </div>

        <b-button
          tag="router-link"
          :to="{ name: 'edit-version', params: { id: version.id } }"
          icon-left="pencil"
          type="is-small"
          target="_blank"
        >
          Перейти к настройкам версии
        </b-button>

        <b-button icon-left="pencil" type="is-small" @click="editVersionLoc">
          Поменять текст "Что нового" только для этого релиза
        </b-button>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Закрыть
      </button>
      <button class="button" type="button" @click="$emit('refresh')">
        Обновить
      </button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.company {
  padding-bottom: 12px;
  border-bottom: 1px lightgray solid;

  .edit-btn {
    float: right;
  }
}

.localizations {
  .platforms {
    margin-top: 6px;

    .locale {
      margin-left: 6px;
    }
  }
}
</style>

<script>

export default {
  props: {
    confirmation: Object,
    version: Object
  },
  methods: {
    getErrorsForCompany: function (id) {
      var errors = this.confirmation.status.companies.find(x => x.id === id)
      if (errors) {
        var text = ''
        errors.android.forEach(error => {
          text += JSON.stringify(error)
        })

        return text
      }
      return ''
    },
    editVersionLoc: function () {
      this.$parent.close()

      this.$emit('edit-version')
    }
  },
  computed: {
    companiesWithErrors: function () {
      return this.confirmation.status.companies.filter(c => c.android || c.ios)
    },
    localizationErrors: function () {
      return this.confirmation.status.localizations
    },
    hasLocalizationErrors: function () {
      if (this.confirmation.status.localizations.android) {
        return this.confirmation.status.localizations.android.market ||
          this.confirmation.status.localizations.android.admin
      }
      if (this.confirmation.status.localizations.ios) {
        return this.confirmation.status.localizations.ios.market ||
          this.confirmation.status.localizations.ios.admin
      }
      return false
    }
  },
  components: {
  }
}
</script>

<template>
  <p>
    {{message}}
    <b-button
      v-if="showCreateButton"
      size="is-small"
      type="is-danger"
      @click="create()"
      outlined
      >
        Создать
    </b-button>
  </p>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  props: {
    bundleId: String,
    firebaseProjects: Array
  },
  data: function () {
    return {
      message: '',
      showCreateButton: false
    }
  },
  mounted: function () {
    this.refreshMessage()
  },
  methods: {
    create: function () {
      this.$emit('create')
    },
    refreshMessage: function () {
      this.showCreateButton = false

      if (isEmpty(this.bundleId)) {
        this.message = 'Необходимо заполнить это поле'
        return
      }

      var firebaseProject = this.firebaseProjects.find(p => p.apps.ios.find(a => a.bundle === this.bundleId))
      if (firebaseProject != null) {
        this.message = `Будет использован проект Firebase "${firebaseProject.name}"`
        return
      }

      this.showCreateButton = true
      this.message = 'Проект в Firebase не найден. '
    }
  },
  watch: {
    bundleId: function (val) {
      this.refreshMessage()
    }
  }
}
</script>

<template>
  <section>
    <b-tooltip
      label="Загрузите файл credentials.json. Его можно получить в админ-панели Firebase, в настройках проекта. Settings -> Service Accounts -> Firebase Admin SDK -> Generate new private key"
      multilined
      position="is-bottom"
    >
      <b-field class="file" >
        <b-upload v-model="credsFile" @input="onCredsUpdated" accept="application/json">
          <a class="button is-info">
            <b-icon icon="upload"></b-icon>
            <span>Добавить проект</span>
          </a>
        </b-upload>
      </b-field>
    </b-tooltip>

    <b-button @click="refreshProjects()" icon-left="refresh" type="is-primary" class="button-refresh">Обновить конфигурацию Firebase</b-button>

    <template v-if="projects != null && projects.length > 0">
      <b-table :data="projects">
          <b-table-column label="Название" v-slot="props">
            {{ props.row.name }}
            <b-tag
              v-if="!props.row.can_create_android_apps"
              type="is-danger"
            >Нельзя создавать Android приложения</b-tag>
            <b-tag
              v-if="!props.row.can_create_ios_apps"
              type="is-danger"
            >Нельзя создавать iOS приложения</b-tag>
          </b-table-column>
      </b-table>
    </template>

    <p v-else class="is-size-3 has-text-centered firebase-no-list">Проекты Firebase не добавлены</p>
  </section>
</template>

<style lang="scss" scoped>
.button-refresh {
  margin-left: 10px;
}
.firebase-no-list {
  margin-top: 20px;
}
</style>

<script>

import { mapState } from 'vuex'
import { toastHandler } from '@/plugins/toastHandler'

export default {
  created: function () {
    this.getProjects()
  },
  data: function () {
    return {
      credsFile: null
    }
  },
  methods: {
    getProjects: function () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('firebase/getProjects')
        .then(_ => {
          this.$store.dispatch('stopLoading')
        })
        .catch(error => {
          this.$handleError(error)

          this.$store.dispatch('stopLoading')
        })
    },
    // onReleaseClick: function (release) {
    //   this.$router.push({ name: 'release-details', params: { id: release.id } })
    // },
    onCredsUpdated: function (creds) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('firebase/addProject',
        {
          creds: creds
        })
        .then(res => {
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Проект добавлен!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    },
    refreshProjects: function (creds) {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('firebase/refresh')
        .then(res => {
          this.$store.dispatch('stopLoading')
          toastHandler.apply(this, ['Конфигурация Firebase обнавлена!', 'is-primary'])
        })
        .catch(error => {
          this.$handleError(error)
          this.$store.dispatch('stopLoading')
        })
    }
  },
  computed: {
    ...mapState({
      projects: state => state.firebase.projects
    })
  },
  components: {
  }
}
</script>
